import { FC, ReactNode } from 'react';

import { Container } from './styled';

type Props = {
  children: ReactNode;
  columns?: number;
};

const ProductCardList: FC<Props> = ({ children, columns = 3 }) => {
  return <Container $columns={columns}>{children}</Container>;
};

export default ProductCardList;
