import { FC, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppSelector } from 'hooks/redux';
import { useTranslations } from 'hooks/useTranslations';
import { Pagination } from 'models/common';
import { customerSelector } from 'store/customerState';
import { localizationSelector } from 'store/localizationState';
import { useGetRegisteredProductsQuery } from 'store/productService';
import * as routes from 'router/routes';

import SeoHelmet from 'components/fragments/SeoHelmet';
import Button from 'components/UI/Buttons/Button';
import FloatingActionButton from 'components/UI/Buttons/FloatingActionButton';
import Copyright from 'components/UI/Copyright';
import Heading from 'components/UI/Heading';
import { IconType } from 'components/UI/Icon';
import Loader from 'components/UI/Loader';
import PageState from 'components/UI/Page/PageState';
import PageWrapper from 'components/UI/Page/PageWrapper';
import ProductCard from 'components/UI/Product/ProductCard';
import ProductCardList from 'components/UI/Product/ProductCardList';

import {
  HeadingContainer,
  ActionButtonContainer,
  Description,
  LoadedText,
  LoadMoreContainer
} from './styled';

const ProductsPage: FC = () => {
  const t = useTranslations();
  const navigate = useNavigate();
  const { customer } = useAppSelector(customerSelector);
  const { languageCode } = useAppSelector(localizationSelector);

  // Hooks
  const products = useGetRegisteredProductsQuery({
    customerId: customer?.id,
    langCode: languageCode
  });

  // State
  const [pagination, setPagination] = useState<Pagination>({
    limit: 9,
    offset: 0
  });

  // Callbacks
  const onRegisterProductClick = useCallback(
    () => navigate(routes.PRODUCT_REG),
    [navigate]
  );
  const onProductClick = useCallback(
    (iprId: string) => () => navigate(`${routes.PRODUCTS}/${iprId}`),
    [navigate]
  );

  const onClickLoadMore = useCallback(() => {
    const { offset, limit } = pagination;
    const updated = { limit, offset: limit + offset };
    setPagination(updated);
  }, [pagination]);

  // Products
  const productCards = useMemo(() => {
    const { data, isLoading } = products;

    // Loading
    if (isLoading) {
      return <Loader padding center size="large" />;
    }

    // Empty state
    if (!data?.products.length) {
      return (
        <PageState
          iconType={IconType.Products}
          title={t('myproducts.no_products_title')}
          description={t('myproducts.no_products_description')}
        >
          <Button size="large" onClick={onRegisterProductClick}>
            {t('myproducts.btn_add_product')}
          </Button>
        </PageState>
      );
    }

    // Mock pagination logic until it is implemented in Customer API
    const productResults = data.products.slice(
      0,
      pagination.limit + pagination.offset
    );

    // Data
    return (
      <ProductCardList>
        {productResults.map((product) => {
          return (
            <ProductCard
              key={product.iprId}
              product={product}
              onClick={onProductClick(product.iprId)}
              hasOffers={data.hasOffers.includes(product.iprId)}
            />
          );
        })}
      </ProductCardList>
    );
  }, [products, pagination, t, onProductClick, onRegisterProductClick]);

  // Product count
  const productCount = useMemo(() => {
    return products.data?.products.length;
  }, [products]);

  // Loaded text
  const loadedText = useMemo(() => {
    const { limit, offset } = pagination;

    if (!productCount) {
      return null;
    }

    const loaded =
      productCount < limit + offset ? productCount : limit + offset;

    return (
      <LoadedText>
        {t('myproducts.loaded', { loaded, total: productCount })}
      </LoadedText>
    );
  }, [pagination, productCount, t]);

  // Load more
  const loadMore = useMemo(() => {
    const { limit, offset } = pagination;

    if (!productCount || productCount < limit + offset) {
      return null;
    }
    return (
      <LoadMoreContainer>
        <Button size="large" onClick={onClickLoadMore}>
          {t('myproducts.btn_load_more')}
        </Button>
      </LoadMoreContainer>
    );
  }, [onClickLoadMore, pagination, productCount, t]);

  return (
    <PageWrapper>
      <Copyright>
        <SeoHelmet title="myproducts.meta_title" />
        <HeadingContainer>
          <Heading>{t('myproducts.title')}</Heading>
          {!!productCount && <h6>{`(${productCount})`}</h6>}
        </HeadingContainer>
        <Description>{t('myproducts.description')}</Description>
        {productCards}
        {loadedText}
        {loadMore}
      </Copyright>
      {!!productCount && (
        <ActionButtonContainer>
          <FloatingActionButton
            text={t('myproducts.btn_add_product')}
            onClick={onRegisterProductClick}
          />
        </ActionButtonContainer>
      )}
    </PageWrapper>
  );
};

export default ProductsPage;
