import {
  FC,
  useState,
  ChangeEvent,
  ReactNode,
  useCallback,
  useMemo
} from 'react';
import { UseFormRegisterReturn, UseFormResetField } from 'react-hook-form';

import { useTranslations } from 'hooks/useTranslations';

import errorIcon from 'assets/vectors/error-icon.svg';
import showPasswordIcon from 'assets/vectors/show-password.svg';
import hidePasswordIcon from 'assets/vectors/hide-password.svg';
import calendarIcon from 'assets/vectors/calendar.svg';

import DatePicker from '../DatePicker';

import {
  LabelFloat,
  LabelFloatGroup,
  LabelFloatInput,
  LabelFloatLabel,
  LabelFloatButton,
  LabelFloatMessage
} from './styled';

type CustomIcon = {
  src: string;
  alt: string;
  onClick: () => void;
};

type Props = {
  description: string;
  placeholder?: string;
  name: string;
  type?: string;
  register?: UseFormRegisterReturn;
  defaultValue?: string;
  disabled?: boolean;
  error?: any;
  icon?: CustomIcon;
  reset?: UseFormResetField<any>;
  outlined?: boolean;
  helperMessage?: string;
  helperAction?: ReactNode;
  maxDate?: Date;
  defaultDate?: Date;
  setDate?: (date: Date) => void;
  uppercase?: boolean;
};

const InputField: FC<Props> = ({
  description,
  placeholder = ' ',
  register,
  name,
  type,
  defaultValue,
  disabled = false,
  error,
  icon,
  reset,
  outlined = false,
  helperMessage = '',
  helperAction = null,
  maxDate,
  defaultDate,
  setDate,
  uppercase
}) => {
  const t = useTranslations();

  // State
  const [focus, setFocus] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showCalendar, setShowCalendar] = useState<boolean>(false);

  // Render icon button
  const renderIconButton = useCallback(
    (onClick: () => void, src: string, alt: string) => (
      <LabelFloatButton type="button" onClick={onClick}>
        <img src={src} alt={alt} />
      </LabelFloatButton>
    ),
    []
  );

  // Render input icon
  const inputIcon = useMemo(() => {
    if (error) {
      return renderIconButton(() => reset?.(name), errorIcon, 'error');
    }
    if (icon) {
      return renderIconButton(() => icon.onClick(), icon.src, icon.alt);
    }
    switch (type) {
      case 'password':
        return renderIconButton(
          () => setShowPassword(!showPassword),
          !showPassword ? showPasswordIcon : hidePasswordIcon,
          'show password'
        );
      case 'date':
        return renderIconButton(
          () => setShowCalendar(!showCalendar),
          calendarIcon,
          'calendar'
        );
      default:
        return null;
    }
  }, [
    error,
    icon,
    type,
    name,
    showPassword,
    showCalendar,
    reset,
    renderIconButton
  ]);

  // Message
  const renderMessage = () => {
    if (error) {
      return <p>{t(error.message)}</p>;
    }
    if (helperMessage) {
      return <p>{t(helperMessage)}</p>;
    }
    return null;
  };

  // Uppercase input
  const transformInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (uppercase) {
        event.target.value = event.target.value.toLocaleUpperCase();
      }
    },
    [uppercase]
  );

  // On focus
  const onFocus = useCallback(() => setFocus(true), []);

  // On blur
  const onBlur = useCallback(() => setFocus(false), []);

  // Close calendar
  const closeCalendar = useCallback(() => setShowCalendar(false), []);

  return (
    <LabelFloat>
      <LabelFloatGroup
        $focus={focus}
        $error={error}
        disabled={disabled}
        $outlined={outlined}
        data-testid="label-float-group"
      >
        <LabelFloatInput
          {...register}
          defaultValue={defaultValue}
          placeholder={placeholder}
          onInput={transformInput}
          type={!showPassword ? type : 'text'}
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={disabled}
          $error={error}
        />
        <LabelFloatLabel $error={error} $focus={focus}>
          {t(description)}
        </LabelFloatLabel>
        {inputIcon}
        {showCalendar && type === 'date' && setDate && (
          <DatePicker
            maxDate={maxDate}
            defaultDate={defaultDate}
            onChange={setDate}
            close={closeCalendar}
            data-testid="date-picker"
          />
        )}
      </LabelFloatGroup>
      <LabelFloatMessage $error={error}>
        {renderMessage()}
        {helperAction}
      </LabelFloatMessage>
    </LabelFloat>
  );
};

export default InputField;
