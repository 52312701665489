import styled from 'styled-components';
import { breakpoints } from 'styles';

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 24px;
`;

type HeaderColumnLeftProps = {
  $titleMargin: boolean;
};
export const HeaderColumnLeft = styled.div<HeaderColumnLeftProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;

  > p {
    margin: 0;
  }

  > h3 {
    ${({ $titleMargin }) => ($titleMargin ? '' : 'margin: 0;')}
  }
`;

type HeaderColumnRightProps = {
  $responsiveImage: boolean;
};
export const HeaderColumnRight = styled.div<HeaderColumnRightProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.3rem;
  > img {
    max-width: 100%;
  }

  @media (min-width: ${breakpoints.m}px) {
    > img {
      ${({ $responsiveImage }) => ($responsiveImage ? 'display: none;' : '')}
    }
  }
`;
