import styled from 'styled-components';

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;

  > button {
    min-width: auto;
  }
`;
