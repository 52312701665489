/* istanbul ignore file */

// Declaration
declare global {
  interface Window {
    __env__: {
      ENV: string;
      MAVENOID_ORG_NAME: string;
      MAVENOID_CLIENT_ID: string;
      KEYLIGHT_URL: string;
      KEYLIGHT_ORDER_KEY: string;
      SENTRY_DSN: string;
      BACKEND_API: string;
      AUTH_URL: string;
      AUTH_CLIENT_ID: string;
    };
    __REDUX_DEVTOOLS_EXTENSION__: () => any;
    mavenoid?: any;
  }
}

// Variables
export const environment = window.__env__.ENV;
export const keylightUrl = window.__env__.KEYLIGHT_URL;
export const keylightOrderKey = window.__env__.KEYLIGHT_ORDER_KEY;
export const sentryDsn = window.__env__.SENTRY_DSN;
export const mavenoidClientId = window.__env__.MAVENOID_CLIENT_ID;
export const mavenoidOrgName = window.__env__.MAVENOID_ORG_NAME;
export const backendApi = window.__env__.BACKEND_API;
export const authUrl = window.__env__.AUTH_URL;
export const authClientId = window.__env__.AUTH_CLIENT_ID;
export const isTest = environment === 'test';
export const isDev = environment === 'dev';
export const isLive = environment === 'live';

// Handle variables not set
if (!backendApi) {
  throw new Error('No backend endpoint set! Application will not work!');
}
