// Unprotected routes
export const ROOT = '/';
export const LOGIN = '/login';
export const RESET_PASSWORD = '/reset-password';
export const SIGNUP = '/signup';
export const VERIFY_EMAIL = '/verify-email';
export const ACTIVATE_ACCOUNT = '/activate-account';
export const EMAIL_VERIFICATION = '/email-verification';
export const PRODUCT_INFO = '/product-information';
export const PRODUCT_REG = '/register-product';
export const INVITE_PRODUCTS_REG = '/register-invite-products';
export const PRODUCT_REG_PROFESSIONAL = '/professional/product-registration';
export const CONFIRM_CHANGE_EMAIL = '/validate-change-email';
export const CONFIRM_DELETE_ACCOUNT = '/confirm-account-deletion';

// Protected routes
export const PRODUCTS = '/my-products';
export const PROFILE = '/my-profile';
export const PROFILE_EDIT = '/my-profile/edit';
