/**
 * Try to get markdown content from a provided uri.
 */
export async function fetchMarkdown(url: string): Promise<string> {
  const response = await fetch(url);
  const text = await response.text();

  if (!response.ok) {
    throw new Error(`Bad Response: ${response.status}`);
  }

  return text;
}
