import styled from 'styled-components';

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;

type StyleType = {
  $isActive: boolean;
};

export const StyledGroup = styled.g<StyleType>`
  fill: ${({ theme, $isActive }) => {
    return $isActive ? theme.colors.primary : '#201E1E';
  }};
`;

export const StyledPath = styled.path<StyleType>`
  fill: ${({ theme, $isActive }) => {
    return $isActive ? theme.colors.primary : '#201E1E';
  }};
`;
