import { FC, ReactNode } from 'react';
import { StyledBadge } from './styled';

type Props = {
  isPrimary?: boolean;
  children: ReactNode;
};

const Badge: FC<Props> = ({ children, isPrimary }) => {
  return <StyledBadge $isPrimary={isPrimary}>{children}</StyledBadge>;
};

export default Badge;
