import {
  ProductDetails,
  ProductList,
  Product,
  ContractStatus,
  ServiceContract
} from 'models/product';
import { ThemeColor } from 'styles';

export function findProductListItemsByIprId(list: any[], iprId: string) {
  return list.find((item) => item.iprId === iprId);
}

export function findProductByInviteRequest(product: Product) {
  if (product.iprId) {
    return {
      iprId: product.iprId
    };
  }
  if (product.serialNumber && product.pnc) {
    return {
      serialNumber: product.serialNumber,
      productno: product.pnc
    };
  }
  return product;
}

export function filterCancelledServiceContracts(
  serviceContracts: ServiceContract[]
) {
  return serviceContracts?.filter(
    (serviceContract) => serviceContract.status !== 'CANCELLED'
  );
}

export function transformProductRegistrationResponse(response: any) {
  const { attributes } = response.data;
  const productList: ProductList = {
    products: attributes.products,
    hasOffers: []
  };

  // Return registrations if nothing is included
  if (!attributes.serviceContractOfferings) {
    return productList;
  }

  attributes.serviceContractOfferings.forEach(({ iprId }: any) => {
    if (!productList.hasOffers.includes(iprId)) {
      productList.hasOffers.push(iprId);
    }
  });

  return productList;
}

export function transformProductDetailsResponse(response: any) {
  const { attributes } = response.data;

  const productDetails: ProductDetails = {
    product: attributes?.product,
    dealer: attributes?.dealer,
    serviceContracts:
      filterCancelledServiceContracts(attributes?.serviceContracts) ?? [],
    serviceRecords: attributes?.serviceRecords ?? [],
    documents: attributes?.documents ?? [],
    warranty: attributes?.warranty,
    hasOffers: !!attributes?.serviceContractOffering
  };

  return productDetails;
}

export function getContractStatusColor(status: ContractStatus): ThemeColor {
  switch (status) {
    case ContractStatus.Active:
      return 'success';
    case ContractStatus.Pending:
      return 'warning';
    case ContractStatus.NeverActivated:
      return 'info';
    default:
      return 'error';
  }
}

export function extractPublicationTitle(
  publicationTitle: Record<string, string>,
  languages: string[],
  locale: string
) {
  if (languages.includes('ANY')) {
    return publicationTitle.en ?? 'Terms & Conditions';
  }

  const availableTranslatedTitles = languages.filter((language) =>
    Object.prototype.hasOwnProperty.call(
      publicationTitle,
      language.toLowerCase()
    )
  );

  // If there is only one available translated language
  // Then select that one
  const selectedLanguage =
    availableTranslatedTitles.length === 1
      ? availableTranslatedTitles[0]
      : locale;

  // If there is only one available translated title
  // then select that one
  // If there are more than two available translated titles
  // Then select the translated title that matches browser locale
  // Otherwise use fallback
  return (
    publicationTitle[selectedLanguage?.toLowerCase()] ??
    publicationTitle.en ??
    'Terms & Conditions'
  );
}
