import { FC, useCallback } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';

import { DIAMGroup } from 'models/customer';
import { useTranslations } from 'hooks/useTranslations';
import InputField from 'components/UI/InputField';
import Button from 'components/UI/Buttons/Button';
import Loader from 'components/UI/Loader';
import Modal from 'components/UI/Modal';
import { ModalForm, ModalTitle } from 'styles';
import { ButtonGroup, Text, Services, Group, Info } from './styled';

type ValidateChangeEmail = {
  code: string;
  password: string;
};

type Props = {
  email: string | null;
  isOpen: boolean;
  defaultCode?: string | null;
  isLoading: boolean;
  groups: DIAMGroup[];
  submit: (values: ValidateChangeEmail) => void;
  cancel?: () => void;
};

const ConfirmChangeEmailModal: FC<Props> = ({
  email,
  isOpen,
  defaultCode,
  isLoading,
  groups,
  submit,
  cancel
}) => {
  const t = useTranslations();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors
  } = useForm();

  // Submit
  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    ({ password }) => {
      if (defaultCode) {
        submit({ code: defaultCode, password });
      }
    },
    [submit, defaultCode]
  );

  // Render services
  const renderServices = () => {
    if (isLoading) {
      return <Loader center />;
    }
    return (
      <ul>
        {groups.map((item) => (
          <Group key={item.groupId}>{item.name}</Group>
        ))}
      </ul>
    );
  };

  // Render info box
  const renderInfoBox = () => {
    if (!email) {
      return null;
    }
    return (
      <Info>
        <Text>{t('change_email.confirm.instructions', { email })}</Text>
      </Info>
    );
  };

  // Reset inputfield
  const reset = useCallback(
    (name: string) => {
      setValue(name, '');
      clearErrors(name);
    },
    [setValue, clearErrors]
  );

  return (
    <Modal isOpen={isOpen}>
      <ModalTitle>{t('change_email.confirm.title')}</ModalTitle>
      <ModalForm onSubmit={handleSubmit(onSubmit)}>
        <Services>
          <Text>{t('change_email.confirm.description')}</Text>
          <Text>{t('change_email.confirm.text_services')}</Text>
          {renderServices()}
        </Services>
        {renderInfoBox()}
        <InputField
          name="password"
          description="input.password_label"
          type="password"
          error={errors.password}
          register={register('password', {
            required: {
              value: true,
              message: 'input.password_required'
            }
          })}
          reset={reset}
          outlined
        />
        <ButtonGroup>
          <Button color="transparent" textColor="primary" onClick={cancel}>
            {t('change_email.confirm.btn_cancel')}
          </Button>
          <Button submit>{t('change_email.confirm.btn_confirm')}</Button>
        </ButtonGroup>
      </ModalForm>
    </Modal>
  );
};

export default ConfirmChangeEmailModal;
