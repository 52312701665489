import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryAuth } from 'utils/fetchUtils/fetchUtils';
import { AppConfigAttributes, AppConfigResponse } from 'models/basicData';

export const configApi = createApi({
  reducerPath: 'configApi',
  baseQuery: baseQueryAuth,
  tagTypes: ['Config'],
  endpoints: (builder) => ({
    getConfig: builder.query<AppConfigAttributes, void>({
      query: () => '/config',
      transformResponse: (res: AppConfigResponse) => res.data.attributes
    })
  })
});

export const { useGetConfigQuery } = configApi;
