import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  p {
    font-size: 16px;
    margin: 0;
  }

  h3 {
    margin: 0;
    overflow-wrap: anywhere;
  }
`;
