import { FC } from 'react';

import Heading, { Type as HeadingType } from 'components/UI/Heading';
import arrowRightIcon from 'assets/vectors/arrow-right.svg';

import { ArrowIcon, Col, Container, Row } from './styled';

export type ClickableListItem = {
  id: number;
  title: string;
  description?: string;
  onClick?: () => void;
};

type Props = {
  data: ClickableListItem[];
};

const ClickableList: FC<Props> = ({ data }) => {
  return (
    <Container>
      {data.map((item, i) => (
        <Row
          key={item.id}
          $border={i + 1 !== data.length}
          onClick={item.onClick}
        >
          <Col>
            <Heading type={HeadingType.h3}>{item.title}</Heading>
            {item.description && <p>{item.description}</p>}
          </Col>
          <ArrowIcon src={arrowRightIcon} alt="clickable" />
        </Row>
      ))}
    </Container>
  );
};

export default ClickableList;
