import styled from 'styled-components';
import QrReader from 'react-qr-scanner';
import { motion } from 'framer-motion';

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.surface};
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 3;
  padding: 16px;
`;

export const Header = styled.div`
  margin-bottom: 24px;
`;

export const IconButton = styled.button`
  background: transparent;
  border: none;
  padding: 8px;

  img {
    width: 32px;
    height: 32px;
  }
`;

export const TextContainer = styled.div`
  text-align: center;
  margin-bottom: 48px;

  p {
    color: ${({ theme }) => theme.colors.subtle};
  }
`;

export const ScanContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    position: absolute;
  }
`;

export const ScanLoad = styled.div`
  position: absolute;
`;

export const Scanner = styled(QrReader)`
  video {
    object-fit: cover !important;
    height: 280px;
    width: 280px;
  }
`;

export const ScanLine = styled(motion.div)`
  background-color: ${({ theme }) => theme.colors.warning};
  box-shadow: 0 4px 4px 0 rgba(223, 64, 14, 0.46);
  position: absolute;
  width: 320px;
  height: 2px;
`;
