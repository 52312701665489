import {
  determineLanguage,
  determineLanguageRegion,
  isCountryCode
} from '../localizationUtils';

// Market
function determineMarket(params: URLSearchParams) {
  const market = params.get('market');
  const countrycode = market && isCountryCode(market) ? market : 'SE';
  return countrycode.toLowerCase();
}

export function getMarketSettings(location: Location = window.location) {
  const params = new URLSearchParams(location.search);

  const languageCode = determineLanguage();
  const languageRegionCode = determineLanguageRegion(languageCode);
  const market = determineMarket(params);

  return { languageCode, languageRegionCode, market };
}
