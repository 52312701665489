import { FC } from 'react';
import { Helmet } from 'react-helmet-async';

import { localizationSelector } from 'store/localizationState';

import { useTranslations } from 'hooks/useTranslations';
import { useAppSelector } from 'hooks/redux';

import favicon from 'assets/images/favicon_husqvarna.ico';

type Props = {
  title: string;
};

const SeoHelmet: FC<Props> = ({ title }) => {
  const t = useTranslations();
  const { languageCode } = useAppSelector(localizationSelector);

  return (
    <Helmet>
      <html lang={languageCode} />
      <title>{`${t('start.header')} - ${t(title)}`}</title>
      <link rel="icon" href={favicon} />
    </Helmet>
  );
};

export default SeoHelmet;
