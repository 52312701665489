import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { Invite } from 'models/invite';
import { ApiError } from 'models/errors/ApiError';
import * as localStorage from 'utils/localStorageUtils';
import type { RootState } from 'store';

export type InviteState = {
  invite: Invite | null;
  hasErrors: boolean;
  shouldComplete: boolean;
  isCompleted: boolean;
  soldDateRequired: boolean;
};

export const defaultInviteState: InviteState = {
  invite: null,
  hasErrors: false,
  shouldComplete: false,
  isCompleted: false,
  soldDateRequired: false
};

// Export slice
export const inviteSlice = createSlice({
  name: 'invite',
  initialState: defaultInviteState,
  reducers: {
    resetInviteState: () => defaultInviteState,
    setInvite: (state, action: PayloadAction<Invite>) => {
      state.invite = action.payload;
      state.isCompleted = false;
    },
    updateInviteEmail: (state, action: PayloadAction<string>) => {
      if (state.invite) {
        state.invite = {
          ...state.invite,
          customerEmail: action.payload
        };
      }
    },
    completeInviteStart: (state) => {
      state.shouldComplete = true;
      state.hasErrors = false;
      localStorage.setItem('invite', state.invite);
    },
    completeInviteSuccess: (state) => {
      state.isCompleted = true;
      state.shouldComplete = false;
      state.soldDateRequired = false;
      state.invite = null;

      localStorage.removeItem('invite');
    },
    completeInviteFailure: (state) => {
      state.hasErrors = true;
      state.isCompleted = false;

      localStorage.removeItem('invite');
    },
    completeInviteCancel: (state) => {
      state.hasErrors = false;
      state.shouldComplete = false;
      state.isCompleted = false;
      state.soldDateRequired = false;

      localStorage.removeItem('invite');
    },
    setSoldDateRequired: (state) => {
      state.soldDateRequired = true;
    }
  }
});

// Export actions
export const {
  resetInviteState,
  setInvite,
  updateInviteEmail,
  completeInviteStart,
  completeInviteSuccess,
  completeInviteFailure,
  completeInviteCancel,
  setSoldDateRequired
} = inviteSlice.actions;

// Export reducer
export const inviteReducer = inviteSlice.reducer;

// Export selector
export const inviteSelector = (state: RootState) => state.invite;

// Export thunks
export function checkInvite() {
  return async (dispatch: Dispatch) => {
    const invite = localStorage.getItem<Invite>('invite');
    if (invite) {
      dispatch(setInvite(invite));
      dispatch(completeInviteStart());
    }
  };
}

export function inviteRegistrationError(error: ApiError) {
  return async (dispatch: Dispatch) => {
    // Handle error
    switch (error.code) {
      case 'sold.date.required':
        dispatch(setSoldDateRequired());
        break;
      case 'relationship.my-products.exists':
      case 'relationship.sold-as-new-to':
        dispatch(completeInviteSuccess());
        break;
      default:
        dispatch(completeInviteFailure());
    }
  };
}
