import styled, { DefaultTheme } from 'styled-components';
import { boxShadows } from 'styles';

type SelectProps = {
  $error?: boolean;
  $focus?: boolean;
};

type SelectOptionsProps = {
  $isActive: boolean;
};

type OptionProps = {
  disabled?: boolean;
};

function handleBackgroundColor(theme: DefaultTheme, error?: boolean) {
  if (error) {
    return theme.colors.subtleError;
  }

  return theme.colors.surface;
}

const handleBorderColor = (
  theme: DefaultTheme,
  focus?: boolean,
  error?: boolean
) => {
  if (error) {
    return theme.colors.error;
  }

  if (focus) {
    return theme.colors.grey7;
  }

  return theme.colors.grey3;
};

export const SelectWrapper = styled.div`
  position: relative;
  user-select: none;
  width: 100%;
`;

export const Select = styled.div<SelectProps>`
  display: flex;
  background-color: ${({ theme, $error }) =>
    handleBackgroundColor(theme, $error)};
  border-radius: 8px;
  opacity: 1;
  background-clip: padding-box;
  border: 1px solid;
  border-color: ${({ theme, $focus, $error }) =>
    handleBorderColor(theme, $focus, $error)};
`;

export const SelectOptions = styled.div<SelectOptionsProps>`
  position: absolute;
  display: block;
  max-height: 300px;
  overflow-y: scroll;
  top: 64px;
  left: 0;
  right: 0;
  border-radius: 8px;
  transition: all 0.5s;
  background: ${({ theme }) => theme.colors.surface};
  opacity: ${({ $isActive }) => ($isActive ? '1' : '0')};
  visibility: ${({ $isActive }) => ($isActive ? 'visible' : 'hidden')};
  pointer-events: ${({ $isActive }) => ($isActive ? 'all' : 'none')};
  box-shadow: ${boxShadows.normal};
  z-index: 2;
`;

export const Option = styled.div<OptionProps>`
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
  font-size: 18px;
  line-height: 60px;
  cursor: ${({ disabled = false }) => (disabled ? 'inherit' : 'pointer')};
  transition: all 0.5s;
  text-align: start;

  &:hover {
    background-color: ${({ theme, disabled = false }) =>
      !disabled ? theme.colors.grey5 : 'transparent'};
  }

  p {
    margin: 0;
  }
`;

export const LabelFloatInput = styled.input<SelectProps>`
  width: 100%;
  height: 54px;
  outline: none;
  border: none;
  padding: 24px 14px 6px;
  min-width: 180px;
  font-size: 18px;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -webkit-appearance: none;
  background-color: ${({ theme, $error }) =>
    handleBackgroundColor(theme, $error)};
  border-radius: 8px;
  caret-color: ${({ theme }) => theme.colors.primary};

  &::placeholder {
    color: transparent;
  }

  &:focus + label,
  &:not(:placeholder-shown) + label,
  &:-webkit-autofill + label {
    font-size: 12px;
    margin-top: 8px;
  }
`;

export const LabelFloatLabel = styled.label<SelectProps>`
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 16px;
  margin-top: 16px;
  font-size: 18px;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  color: ${({ theme, $error, $focus }) => {
    if ($error) {
      return theme.colors.error;
    }

    if ($focus) {
      return theme.colors.primary;
    }

    return theme.colors.onSurface;
  }};
`;

export const LabelFloatButton = styled.button<SelectOptionsProps>`
  display: flex;
  align-items: center;
  border: none;
  padding: 0 16px;
  background: transparent;

  &:focus {
    outline: 0;
  }

  img {
    transform: rotate(${({ $isActive }) => ($isActive ? '-180deg' : '0')});
    transition: transform 0.2s;
    width: 24px;
    height: 24px;
  }
`;

export const LabelFloatMessage = styled.div<SelectProps>`
  min-height: 24px;
  padding: 4px 16px;
  margin-bottom: 8px;
  display: flex;

  p {
    margin: 0;
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme, $error }) => {
      if ($error) {
        return theme.colors.error;
      }

      return theme.colors.onSurface;
    }};
  }
`;

export const Mark = styled.mark`
  background-color: ${({ theme }) => theme.colors.primary10};
  color: ${({ theme }) => theme.colors.secondary};
  font-weight: 600;
`;
