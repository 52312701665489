import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useTranslations } from 'hooks/useTranslations';
import { authSelector } from 'store/authState';
import { customerSelector } from 'store/customerState';
import { useGetCustomerConsentsQuery } from 'store/customerService';
import { fetchBasicData, localizationSelector } from 'store/localizationState';
import {
  deleteAccountRequest,
  deleteAccountSelector
} from 'store/deleteAccountState';
import {
  changeEmailRequest,
  changeEmailSelector,
  resetChangeEmailState
} from 'store/changeEmailState';
import {
  buildAddress,
  findCustomerOption,
  getConsentTranslation,
  getCustomerPhoneNumber,
  isBusinessCustomer
} from 'utils/customerUtils/customerUtils';
import { showSuccessToast } from 'utils/toastUtils';
import { formatDate } from 'utils/dateUtils';
import { CustomerConsent } from 'models/customer';

import SeoHelmet from 'components/fragments/SeoHelmet';
import ChangeEmailModal, {
  ChangeEmailValues
} from 'components/modals/ChangeEmailModal';
import DeleteAccountModal from 'components/modals/DeleteAccountModal';
import UpdateConsentModal from 'components/modals/UpdateConsentModal';
import Heading, { Type } from 'components/UI/Heading';
import Copyright from 'components/UI/Copyright';
import DataCell from 'components/UI/DataCell';
import Loader from 'components/UI/Loader';
import { IconType } from 'components/UI/Icon';
import ClickableList from 'components/UI/ClickableList';
import CheckboxList from 'components/UI/CheckboxList';
import PageWrapper from 'components/UI/Page/PageWrapper';
import PageState from 'components/UI/Page/PageState';

import * as config from 'config';
import * as routes from 'router/routes';

import {
  Card,
  DataGrid,
  PageContent,
  BusinessAccountInfo,
  ConsentGrid,
  EmptyText
} from './styled';

const ProfilePage: FC = () => {
  const t = useTranslations();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Redux
  const { session } = useAppSelector(authSelector);
  const { customer, isLoading } = useAppSelector(customerSelector);
  const deleteAccount = useAppSelector(deleteAccountSelector);
  const changeEmail = useAppSelector(changeEmailSelector);
  const { languageOptions, countryOptions, languageCode } =
    useAppSelector(localizationSelector);

  const consents = useGetCustomerConsentsQuery({
    customerId: session.customerId
  });

  // State
  const [changeEmailOpen, setChangeEmailOpen] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [consentModal, setConsentModal] = useState<CustomerConsent | null>(
    null
  );

  // Check if business customer
  const isBusiness = useMemo(() => isBusinessCustomer(customer), [customer]);

  // Delete account request
  const requestDeleteAccount = useCallback(() => {
    const { accessToken, customerId } = session;
    if (accessToken && customerId) {
      dispatch(deleteAccountRequest(customerId, accessToken));
    }
  }, [dispatch, session]);

  // Change email request
  const requestChangeEmail = useCallback(
    (values: ChangeEmailValues) => {
      const { accessToken } = session;
      if (customer && accessToken) {
        dispatch(
          changeEmailRequest(
            customer.id,
            values.email,
            values.password,
            accessToken
          )
        );
      }
    },
    [dispatch, customer, session]
  );

  // Close change email modal
  const onChangeEmailClose = useCallback(() => setChangeEmailOpen(false), []);

  // Close delete account modal
  const onDeleteAccountClose = useCallback(() => setDeleteModalOpen(false), []);

  // Close consent modal
  const onConsentModalClose = useCallback(() => setConsentModal(null), []);

  // Handle consent click
  const onConsentClick = useCallback(
    (consentTypeId: string) => () => {
      const found =
        consents.data?.find((item) => item.id === consentTypeId) ?? null;
      setConsentModal(found);
    },
    [consents]
  );

  // Delete request success
  useEffect(() => {
    if (deleteAccount.request.isCompleted) {
      showSuccessToast(t('toast.account_delete_verify'));
    }
  }, [t, deleteAccount]);

  // Change email success
  useEffect(() => {
    if (changeEmail.request.isCompleted) {
      showSuccessToast(
        t('toast.change_email_verify', { email: changeEmail.request.newEmail })
      );
      dispatch(resetChangeEmailState());
    }
  }, [dispatch, t, changeEmail]);

  // Fetch country and language options
  useEffect(() => {
    if (!countryOptions.length || !languageOptions.length) {
      dispatch(fetchBasicData());
    }
  }, [dispatch, countryOptions, languageOptions]);

  // Consents
  const consentData = useMemo(() => {
    // Loading
    if (consents.isLoading) {
      return <Loader padding center size="large" />;
    }
    // No data
    if (!consents.data?.length) {
      return <EmptyText>{t('myprofile.consents_empty')}</EmptyText>;
    }
    return (
      <CheckboxList
        onClick={onConsentClick}
        data={consents.data.map(({ id, attributes }) => {
          const { texts, status, timestamp } = attributes;
          const { title, description } = getConsentTranslation(
            texts,
            languageCode
          );

          return {
            id,
            title,
            description,
            isChecked: status === 'ACCEPTED',
            additionalText: timestamp
              ? t('myprofile.consents_updated', {
                  date: formatDate(timestamp, 'yyyy-MM-dd')
                })
              : null
          };
        })}
      />
    );
  }, [onConsentClick, t, consents, languageCode]);

  // Customer data
  const customerData = useMemo(() => {
    const cellData = [
      {
        id: 1,
        name: 'myprofile.label_email',
        value: customer?.email
      },
      {
        id: 2,
        name: 'myprofile.label_location_address',
        value: buildAddress(customer?.locationAddress)
      },
      {
        id: 3,
        name: 'myprofile.label_billing_address',
        value: buildAddress(customer?.billingAddress)
      },
      {
        id: 4,
        name: 'myprofile.label_country',
        value: findCustomerOption(countryOptions, customer?.country)
      },
      {
        id: 5,
        name: 'myprofile.label_language',
        value: findCustomerOption(languageOptions, customer?.language)
      },
      {
        id: 6,
        name: 'myprofile.label_phone',
        value: getCustomerPhoneNumber(customer?.cellPhoneNumber)
      }
    ];

    // Add business data
    if (isBusiness) {
      cellData.unshift(
        ...[
          {
            id: 7,
            name: 'myprofile.label_company_name',
            value: customer?.name
          },
          {
            id: 8,
            name: 'myprofile.label_vat_number',
            value: customer?.vatNumber
          }
        ]
      );
    }

    return cellData.map((item) => (
      <DataCell key={item.id} name={t(item.name)}>
        {item.value}
      </DataCell>
    ));
  }, [customer, isBusiness, languageOptions, countryOptions, t]);

  // Manage data
  const manageData = useMemo(() => {
    const list = [
      {
        id: 2,
        title: t('myprofile.subscription_title'),
        description: t('myprofile.subscription_description'),
        onClick: () => window.open(config.keylightUrl)
      },
      {
        id: 3,
        title: t('myprofile.change_password_title'),
        description: t('myprofile.change_password_description'),
        onClick: () => navigate(routes.RESET_PASSWORD)
      }
    ];
    if (!isBusiness) {
      list.unshift({
        id: 1,
        title: t('myprofile.edit_profile_title'),
        description: t('myprofile.edit_profile_description'),
        onClick: () => navigate(routes.PROFILE_EDIT)
      });
      list.push(
        {
          id: 4,
          title: t('myprofile.change_email_title'),
          description: t('myprofile.change_email_description'),
          onClick: () => setChangeEmailOpen(true)
        },
        {
          id: 5,
          title: t('myprofile.delete_account_title'),
          description: t('myprofile.delete_account_description'),
          onClick: () => setDeleteModalOpen(true)
        }
      );
    }
    return list;
  }, [t, navigate, isBusiness]);

  // Loading and check for options
  if (isLoading || !languageOptions.length || !countryOptions.length) {
    return (
      <PageWrapper>
        <Loader center size="large" />
      </PageWrapper>
    );
  }

  // Empty state
  if (!customer) {
    return (
      <PageState iconType={IconType.Profile} title={t('myprofile.empty')} />
    );
  }

  return (
    <PageWrapper responsive={false}>
      <Copyright>
        <PageContent>
          <SeoHelmet title="myprofile.meta_title" />
          <Heading>
            {t('myprofile.title', {
              name: `${customer.firstName} ${customer.lastName}`
            })}
          </Heading>
          <Card>
            <DataGrid>{customerData}</DataGrid>
          </Card>
          <Heading type={Type.h2}>{t('myprofile.manage_title')}</Heading>
          <Card>
            <ClickableList data={manageData} />
          </Card>
          {isBusiness && (
            <BusinessAccountInfo>
              {t('myprofile.business_customer_account_info')}
            </BusinessAccountInfo>
          )}
          <Heading type={Type.h2}>{t('myprofile.consents_title')}</Heading>
          <Card>
            <ConsentGrid>{consentData}</ConsentGrid>
          </Card>
        </PageContent>
        <ChangeEmailModal
          isOpen={changeEmailOpen}
          isCompleted={changeEmail.request.isCompleted}
          isLoading={changeEmail.request.isLoading}
          currentEmail={customer.email}
          close={onChangeEmailClose}
          submit={requestChangeEmail}
        />
        <DeleteAccountModal
          isOpen={deleteModalOpen}
          isLoading={deleteAccount.request.isLoading}
          isCompleted={deleteAccount.request.isCompleted}
          submit={requestDeleteAccount}
          close={onDeleteAccountClose}
        />
        <UpdateConsentModal
          data={consentModal}
          languageCode={languageCode}
          isOpen={!!consentModal}
          close={onConsentModalClose}
        />
      </Copyright>
    </PageWrapper>
  );
};

export default ProfilePage;
