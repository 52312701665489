export type DealerInfo = {
  companyCode: string;
  customerNumber: string;
};

export type ProductQuery = {
  customerId?: string;
  langCode?: string;
  iprId?: string | null;
  husqvarnaId?: string | null;
  pnc?: string | null;
  serialNumber?: string | null;
};

export type InviteQuery = {
  email?: string;
  code?: string | null;
};

export type Product = {
  brand: string;
  description?: string;
  imageId: string | null;
  imageUrl: string | null;
  iprId: string;
  modelName: string;
  productno: string;
  serialNumber: string;
  pnc: string;
  productCategory: string;
  husqvarnaId: string;
};

export type DealerAddress = {
  coAddress: string;
  name: string;
  street: string;
  zipCity: string;
};

type DocumentPublishTitle = {
  en: string;
};
export type ProductDocumentData = {
  id: string;
  format: string;
  iprId: string;
  languages: string[];
  link: string;
  publicationTitle: DocumentPublishTitle;
  publicationType: string;
  sizeBytes: number;
};

export type DealerData = {
  address: DealerAddress;
  iprId: string;
  name: string;
};

export type TermsAndConditions = {
  id: string;
  type: string;
  attributes: {
    publicationType: string;
    publicationTitle: Record<string, string>;
    publicationId: string;
    created: number;
    updated: number;
    binaryLastModified: number;
    languages: string[];
    publishedBy: {
      status: string;
      startTime: number;
    };
    format: string;
    link: string;
    sizeBytes: number;
    documentArticleNumber: string;
    originalFilename: string;
    filename: string;
    intendedCountries: Record<string, string>;
    intendedCountriesType: string;
    checksum: string;
  };
};

export type ServiceContract = {
  id: string;
  type: string;
  iprId: string;
  customerId: string;
  startDate: number;
  endDate: number;
  status: ContractStatus;
  serviceContractProduct: string;
  marketName?: string;
  termsAndConditions: TermsAndConditions[];
};

export enum ContractStatus {
  Pending = 'PENDING',
  Active = 'ACTIVE',
  OnHold = 'ON_HOLD',
  NeverActivated = 'NEVER_ACTIVATED',
  Cancelled = 'CANCELLED',
  Expired = 'EXPIRED'
}

export type Warranty = {
  expireDate: string;
  extendedWarrantyPeriod: number;
  iprId: string;
  productNo: string;
  serialNo: string;
  soldDate: string;
  source: string;
  verified: boolean;
  verifiedBy: string;
  verifiedWhen: string;
  warrantyPeriod: number;
};

export type DoneBy = {
  userId: string;
  sourceSystem: string;
  time: number;
};

export type Workshop = {
  workshopName: string;
  idType: string;
};

export type ServicedPart = {
  genericName: string;
};

export type PerformedTask = {
  id: string;
  type: string;
  attributes: {
    description: string;
    type: string;
    servicedParts: ServicedPart[];
    createdBy: DoneBy;
    modifiedBy: DoneBy;
  };
};

export type ServiceRecord = {
  id: string;
  title: string;
  description: string;
  recordDate: number;
  performedByType: string;
  type: string;
  workshop: Workshop;
  createdBy: DoneBy;
  relationships: {
    performedTasks: {
      data: PerformedTask[];
    };
  };
};

export interface ProductDetails {
  product: Product;
  dealer: DealerData | null;
  serviceContracts: ServiceContract[];
  documents: ProductDocumentData[];
  warranty: Warranty | null;
  serviceRecords: ServiceRecord[];
  hasOffers: boolean;
}
export interface ProductList {
  products: Product[];
  hasOffers: string[];
}

export type ProductDetailsContext = {
  data: ProductDetails;
};
