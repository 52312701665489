import { FC, useEffect } from 'react';

import * as config from 'config';

type Props = {
  productId?: number;
  langCode?: string;
  openByDefault?: boolean;
};

const Chatbot: FC<Props> = ({ productId, langCode, openByDefault = false }) => {
  // Mount scripts
  useEffect(() => {
    const widgetScript = document.createElement('script');
    widgetScript.src = `https://${config.mavenoidOrgName}.mavenoid.com/embedded/embedded.js`;
    widgetScript.async = true;
    document.body.appendChild(widgetScript);

    window.mavenoid = window.mavenoid || [];

    window.mavenoid.config = {
      noIframe: false,
      clientId: config.mavenoidClientId
    };

    window.mavenoid.push({
      event: 'troubleshooter-open',
      orgName: config.mavenoidOrgName,
      openByDefault,
      productId,
      defaultLang: langCode
    });

    // Cleanup
    return () => {
      window.mavenoid.push({
        event: 'troubleshooter-unmount'
      });
      document.body.removeChild(widgetScript);
    };
  }, [productId, openByDefault, langCode]);

  return null;
};

export default Chatbot;
