import styled from 'styled-components';
import { breakpoints } from 'styles';

export const Form = styled.form`
  width: 100%;
  margin-top: 1.5rem;
  text-align: center;
`;

export const FormCol = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;

  @media (max-width: ${breakpoints.s}px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 0;
  }
`;

export const FormLabelContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
`;

export const FormLabel = styled.label`
  font-weight: bold;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.onBackground};
  text-align: start;
`;

export const AdressCheckboxContainer = styled.label`
  display: flex;
  gap: 8px;
  cursor: pointer;
  padding: 8px 0 16px;
`;
