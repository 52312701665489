import { motion } from 'framer-motion';
import styled from 'styled-components';
import { boxShadows } from 'styles';

export const Text = styled(motion.span)`
  color: ${({ theme }) => theme.colors.onPrimary};
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
  margin: 0 16px;
  white-space: nowrap;
`;

export const Button = styled(motion.button)`
  display: flex;
  align-items: center;
  height: 56px;
  min-width: 56px;
  border: none;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.primary};
  box-shadow: ${boxShadows.normal};
  padding: 16px;
  overflow: hidden;

  &:focus {
    outline: none;
  }

  svg {
    min-width: 24px;
  }
`;
