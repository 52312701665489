import styled from 'styled-components';

type Props = {
  $isPrimary?: boolean;
};

export const StyledBadge = styled.div<Props>`
  padding: 0.2rem 0.6rem;
  background-color: ${({ theme, $isPrimary }) => {
    return $isPrimary ? theme.colors.primary : theme.colors.grey2;
  }};
  color: ${({ theme, $isPrimary }) => {
    return $isPrimary ? theme.colors.onPrimary : 'black';
  }};
  font-weight: bold;
  border-radius: 0.3rem;
  font-size: 0.8rem;
`;
