import { FC, useCallback, useEffect, useState } from 'react';
import { FieldValues, SubmitHandler } from 'react-hook-form';
import { Navigate, useNavigate } from 'react-router-dom';

import { LOGIN } from 'router/routes';
import { showSuccessToast } from 'utils/toastUtils';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useQuery } from 'hooks/useQuery';

import {
  changeEmailSelector,
  changeEmailValidation,
  changeEmailConfirmation
} from 'store/changeEmailState';
import { authSelector } from 'store/authState';
import { fetchCustomer } from 'store/customerState';

import ConfirmChangeEmailModal from 'components/modals/ConfirmChangeEmailModal';
import PageImageWrapper from 'components/UI/Page/PageImageWrapper';
import { useTranslations } from 'hooks/useTranslations';
import Loader from 'components/UI/Loader';

const ConfirmChangeEmailPage: FC = () => {
  const t = useTranslations();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { session } = useAppSelector(authSelector);
  const { validation, confirmation } = useAppSelector(changeEmailSelector);
  const { newEmail } = validation;

  // State
  const [modalOpen, setModalOpen] = useState<boolean>(true);

  const [customerId, code] = useQuery('customerId', 'code');

  // Store query in state
  useEffect(() => {
    if (customerId && code) {
      dispatch(changeEmailValidation(customerId, code));
    }
  }, [dispatch, customerId, code]);

  // Show success toast
  useEffect(() => {
    if (confirmation.isCompleted && customerId) {
      if (session.accessToken) {
        dispatch(fetchCustomer(session.accessToken, customerId));
      }
      showSuccessToast(t('toast.change_email_success', { email: newEmail }));
    }
  }, [dispatch, t, newEmail, confirmation, customerId, session]);

  // Submit
  const submitModalForm: SubmitHandler<FieldValues> = useCallback(
    (values) => {
      if (newEmail && customerId) {
        dispatch(
          changeEmailConfirmation(
            customerId,
            newEmail,
            values.code,
            values.password
          )
        );
      }
    },
    [customerId, dispatch, newEmail]
  );

  // Cancel
  const cancelModal = useCallback(() => {
    setModalOpen(false);
    navigate(LOGIN);
  }, [navigate]);

  if (validation.isError) {
    return <Navigate to={LOGIN} />;
  }

  if (confirmation.isCompleted && newEmail) {
    return (
      <Navigate
        to={{
          pathname: LOGIN,
          search: `?email=${encodeURIComponent(newEmail)}`
        }}
      />
    );
  }

  if (validation.isLoading) {
    return <Loader padding center size="large" />;
  }

  return (
    <PageImageWrapper>
      <ConfirmChangeEmailModal
        isOpen={modalOpen}
        email={newEmail}
        defaultCode={code}
        isLoading={confirmation.isLoading}
        groups={validation.groups}
        submit={submitModalForm}
        cancel={cancelModal}
      />
    </PageImageWrapper>
  );
};

export default ConfirmChangeEmailPage;
