import { FC } from 'react';

import Heading, { Type as HeadingType } from 'components/UI/Heading';
import Checkbox from 'components/UI/Checkbox';

import { AdditionalText, Col, Container, Row } from './styled';

export type CheckboxListItem = {
  id: string;
  isChecked: boolean;
  title: string;
  description?: string;
  additionalText?: string | null;
};

type Props = {
  data: CheckboxListItem[];
  onClick: (id: string) => () => void;
};

const CheckboxList: FC<Props> = ({ data, onClick }) => {
  return (
    <Container>
      {data.map((item, i) => (
        <Row
          key={item.id}
          $border={i + 1 !== data.length}
          onClick={onClick(item.id)}
        >
          <Checkbox isChecked={item.isChecked} />
          <Col>
            <Heading type={HeadingType.h3}>{item.title}</Heading>
            {item.description && <p>{item.description}</p>}
          </Col>
          <AdditionalText>{item.additionalText}</AdditionalText>
        </Row>
      ))}
    </Container>
  );
};

export default CheckboxList;
