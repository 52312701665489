import styled from 'styled-components';

export const Text = styled.p`
  font-size: 16px;
`;

export const Services = styled.div`
  margin-bottom: 16px;
`;

export const Info = styled.div`
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.grey5};
  border-radius: 4px;
  margin-bottom: 16px;

  p:last-child {
    margin-bottom: 0px;
  }
`;

export const Group = styled.li`
  font-size: 16px;
  font-weight: bold;
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    min-width: auto;
  }
`;
