import { FC, useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Navigate } from 'react-router-dom';
import deepEquals from 'deep-equal';

import { useTranslations } from 'hooks/useTranslations';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { ICustomerUpdate } from 'models/customer';
import {
  createCustomerUpdateRequest,
  isBusinessCustomer
} from 'utils/customerUtils/customerUtils';

import { authSelector } from 'store/authState';
import {
  customerSelector,
  resetPatchProcess,
  updateCustomer
} from 'store/customerState';
import { fetchBasicData, localizationSelector } from 'store/localizationState';

import * as routes from 'router/routes';
import Heading, { Type as HeadingType } from 'components/UI/Heading';
import Loader from 'components/UI/Loader';
import Button from 'components/UI/Buttons/Button';
import InputField from 'components/UI/InputField';
import PageWrapper from 'components/UI/Page/PageWrapper';
import SelectField from 'components/UI/SelectField';
import Copyright from 'components/UI/Copyright';
import AnimatedFormGroup from 'components/UI/AnimatedFormGroup';
import BackButton from 'components/UI/Buttons/BackButton';
import PhoneNumberField from 'components/UI/PhoneNumberField';
import Checkbox from 'components/UI/Checkbox';

import {
  AdressCheckboxContainer,
  Form,
  FormCol,
  FormLabel,
  FormLabelContainer
} from './styled';

type AddressType = 'locationAddress' | 'billingAddress';
type AddressValue = 'street' | 'zipCode' | 'city';

const EditProfilePage: FC = () => {
  const t = useTranslations();
  const dispatch = useAppDispatch();
  const { session } = useAppSelector(authSelector);

  const { customer, isLoading, completedUpdate } =
    useAppSelector(customerSelector);
  const { languageOptions, countryOptions } =
    useAppSelector(localizationSelector);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    watch
  } = useForm({
    mode: 'onTouched',
    defaultValues: {
      firstName: customer?.firstName,
      lastName: customer?.lastName,
      email: customer?.email,
      language: customer?.language,
      country: customer?.country,
      phoneNumber: customer?.cellPhoneNumber?.number,
      dialCode: customer?.cellPhoneNumber?.dialCode,
      locationAddress: {
        street: customer?.locationAddress?.street,
        zipCode: customer?.locationAddress?.zipCode,
        city: customer?.locationAddress?.city
      },
      billingAddress: {
        street: customer?.billingAddress?.street,
        zipCode: customer?.billingAddress?.zipCode,
        city: customer?.billingAddress?.city
      },
      sameAsLocationAddress: null
    }
  });

  // Reset
  useEffect(() => {
    return () => {
      dispatch(resetPatchProcess());
    };
  }, [dispatch]);

  // Fetch country and language options
  useEffect(() => {
    if (!countryOptions.length || !languageOptions.length) {
      dispatch(fetchBasicData());
    }
  }, [dispatch, countryOptions, languageOptions]);

  // Submit
  const onSubmit: SubmitHandler<any> = useCallback(
    (values) => {
      const { accessToken } = session;
      if (customer && accessToken) {
        const customerUpdate: ICustomerUpdate =
          createCustomerUpdateRequest(values);
        dispatch(updateCustomer(accessToken, customer.id, customerUpdate));
      }
    },
    [dispatch, customer, session]
  );

  // On dial code change
  const onDialCodeChange = useCallback(
    (value: string) => setValue('dialCode', value),
    [setValue]
  );

  // Address error
  const addressError = useCallback(
    (type: AddressType, value: AddressValue) => errors[type]?.[value],
    [errors]
  );

  // Reset inputfield
  const reset = useCallback(
    (name: any) => {
      setValue(name, '');
      clearErrors(name);
    },
    [setValue, clearErrors]
  );

  // Render address section
  const renderAddressSection = useCallback(
    (type: AddressType, name: string) => {
      const isEqual = deepEquals(
        customer?.locationAddress,
        customer?.billingAddress
      );

      const isChecked = watch('sameAsLocationAddress');
      const watchAddress = watch(`${type}.street`);
      const isRequired = watchAddress !== '';

      const isDefaultOpen = type === 'locationAddress' || !isEqual;
      const isToggleOpen = type === 'locationAddress' || !isChecked;
      const isOpen = isChecked === null ? isDefaultOpen : isToggleOpen;

      return (
        <>
          <FormLabelContainer>
            <FormLabel>{t(name)}</FormLabel>
          </FormLabelContainer>
          {type === 'billingAddress' && (
            <AdressCheckboxContainer>
              <Checkbox
                id="sameAsLocationAddress"
                isChecked={!isOpen}
                register={register('sameAsLocationAddress')}
              />
              {t('editprofile.form.use_location_address')}
            </AdressCheckboxContainer>
          )}
          <AnimatedFormGroup defaultIsOpen={isDefaultOpen} isOpen={isOpen}>
            <InputField
              name={`${type}.street`}
              description="editprofile.form.label_address"
              error={addressError(type, 'street')}
              register={register(`${type}.street`)}
              reset={reset}
            />
            <InputField
              name={`${type}.zipCode`}
              description="editprofile.form.label_postal_code"
              error={addressError(type, 'zipCode')}
              register={register(`${type}.zipCode`, {
                required: {
                  value: isRequired,
                  message: 'input.required'
                }
              })}
              reset={reset}
            />
            <InputField
              name={`${type}.city`}
              description="editprofile.form.label_city"
              error={addressError(type, 'city')}
              register={register(`${type}.city`, {
                required: {
                  value: isRequired,
                  message: 'input.required'
                }
              })}
              reset={reset}
            />
          </AnimatedFormGroup>
        </>
      );
    },
    [addressError, register, reset, t, watch, customer]
  );

  // Edit profile is not available to business customers
  if (isBusinessCustomer(customer) || completedUpdate) {
    return <Navigate to={routes.PROFILE} />;
  }

  if (
    !customer ||
    isLoading ||
    !languageOptions.length ||
    !countryOptions.length
  ) {
    return (
      <PageWrapper>
        <Loader center size="large" />
      </PageWrapper>
    );
  }

  return (
    <PageWrapper responsive={false}>
      <Copyright>
        <BackButton backUrl={routes.PROFILE}>
          {t('productinfo.btn_back')}
        </BackButton>
        <Heading type={HeadingType.h1}>{t('editprofile.meta_title')}</Heading>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormCol>
            <InputField
              name="firstName"
              description="editprofile.form.label_firstname"
              error={errors.firstName}
              register={register('firstName', {
                required: {
                  value: true,
                  message: 'editprofile.form.firstname_required'
                }
              })}
              reset={reset}
            />
            <InputField
              name="lastName"
              description="editprofile.form.label_lastname"
              error={errors.lastName}
              register={register('lastName', {
                required: {
                  value: true,
                  message: 'editprofile.form.lastname_required'
                }
              })}
              reset={reset}
            />
          </FormCol>
          <InputField
            name="email"
            description="input.email_label"
            defaultValue={customer.email}
            disabled
          />
          <PhoneNumberField
            description="editprofile.form.label_phone_number"
            numberInputRef={register('phoneNumber')}
            dialCodeInputRef={register('dialCode')}
            onDialCodeChange={onDialCodeChange}
            defaultDialCode={customer.cellPhoneNumber?.dialCode}
            defaultNumber={customer.cellPhoneNumber?.number}
            error={errors.phoneNumber}
          />
          <SelectField
            name="language"
            register={register('language', {
              required: {
                value: true,
                message: 'input.required'
              }
            })}
            setValue={setValue}
            clearErrors={clearErrors}
            defaultValue={customer.language}
            placeholder={t('editprofile.form.label_language_prompt')}
            description="editprofile.form.label_language"
            options={languageOptions}
            error={errors.language}
          />
          <SelectField
            name="country"
            register={register('country', {
              required: {
                value: true,
                message: 'editprofile.form.country_required'
              }
            })}
            setValue={setValue}
            clearErrors={clearErrors}
            placeholder={t('editprofile.form.label_country_prompt')}
            description="editprofile.form.label_country"
            options={countryOptions}
            defaultValue={customer.country}
            error={errors.country}
          />
          {renderAddressSection(
            'locationAddress',
            'editprofile.form.label_location_address'
          )}
          {renderAddressSection(
            'billingAddress',
            'editprofile.form.label_billing_address'
          )}
          <Button submit fullWidth>
            {t('editprofile.form.btn_submit')}
          </Button>
        </Form>
      </Copyright>
    </PageWrapper>
  );
};

export default EditProfilePage;
