import styled from 'styled-components';
import { breakpoints } from 'styles';

type WrapperProps = {
  $empty: boolean;
  $responsive: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  position: relative;
  padding: ${({ $empty }) => ($empty ? '132px' : '32px')} 32px;
  margin: 0 auto;
  max-width: ${({ $responsive }) => ($responsive ? '1200' : '800')}px;
  min-height: 100%;
  height: 100%;
  width: 100%;
  z-index: 1;

  @media (max-width: ${breakpoints.m}px) {
    padding: ${({ $empty }) => ($empty ? '132px' : '24px')} 16px 16px;
    margin: 0 auto;
    max-width: 800px;
  }
`;
