import { FC, useCallback, useEffect, useMemo } from 'react';

import { CustomerConsent } from 'models/customer';
import { useTranslations } from 'hooks/useTranslations';
import { getConsentTranslation } from 'utils/customerUtils';
import { useUpdateCustomerConsentMutation } from 'store/customerService';
import Button from 'components/UI/Buttons/Button';
import Loader from 'components/UI/Loader';
import Modal from 'components/UI/Modal';

import { ModalDescription, ModalTitle } from 'styles';
import { ButtonGroup, LoaderWrapper } from './styled';

type Props = {
  data: CustomerConsent | null;
  languageCode?: string;
  isOpen: boolean;
  close: () => void;
};

const UpdateConsentModal: FC<Props> = ({
  data,
  languageCode,
  isOpen,
  close
}) => {
  const t = useTranslations();

  // Redux
  const [updateConsent, { isLoading, isSuccess }] =
    useUpdateCustomerConsentMutation();

  // Close if complete
  useEffect(() => {
    if (isSuccess) {
      close();
    }
  }, [close, isSuccess]);

  // Confirm consent
  const onConfirm = useCallback(() => {
    if (data) {
      const { status, customerId, consentTypeId } = data.attributes;
      updateConsent({
        customerId,
        consentTypeId,
        body: {
          status: status === 'ACCEPTED' ? 'DENIED' : 'ACCEPTED'
        }
      });
    }
  }, [updateConsent, data]);

  // Title & description
  const { title, description } = useMemo(
    () => getConsentTranslation(data?.attributes.texts, languageCode),
    [data, languageCode]
  );

  // Loader
  const loader = useMemo(() => {
    if (!isLoading && data) {
      return null;
    }
    return (
      <LoaderWrapper>
        <Loader center />
      </LoaderWrapper>
    );
  }, [isLoading, data]);

  // Actions
  const actions = useMemo(() => {
    if (!data) {
      return null;
    }
    const shouldDeny = data.attributes.status === 'ACCEPTED';
    return (
      <ButtonGroup>
        <Button
          onClick={close}
          color="transparent"
          textColor={shouldDeny ? 'onSurface' : 'primary'}
        >
          {t('update_consent.cancel')}
        </Button>
        <Button
          onClick={onConfirm}
          color={shouldDeny ? 'error' : 'primary'}
          disabled={isLoading}
        >
          {t(`update_consent.${shouldDeny ? 'deny' : 'accept'}`)}
        </Button>
      </ButtonGroup>
    );
  }, [onConfirm, close, t, isLoading, data]);

  return (
    <Modal isOpen={isOpen}>
      <ModalTitle>{title}</ModalTitle>
      <ModalDescription>{description}</ModalDescription>
      {loader}
      {actions}
    </Modal>
  );
};

export default UpdateConsentModal;
