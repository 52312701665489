export const enum MenuItemVisibility {
  Public,
  Private,
  Hybrid
}

export type MenuLinkItem = {
  name: string;
  link: string;
  visibility: MenuItemVisibility;
};
