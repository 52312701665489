import { DefaultTheme } from 'styled-components';

export const husqvarnaTheme: DefaultTheme = {
  fontFamily:
    "'Husqvarna Gothic', 'Helvetica Neue', Helvetica, Arial, sans-serif;",
  colors: {
    primary: '#273A60',
    primary10: 'rgba(243, 83, 33, 0.1)',
    onPrimary: '#FFFFFF',
    secondary: '#273A60',
    onSecondary: '#FFFFFF',
    background: '#F4F4F3',
    onBackground: '#4d4d4d',
    surface: '#FFFFFF',
    onSurface: '#3D3D3C',
    white: '#FFFFFF',
    grey1: '#F4F4F3',
    grey2: '#e2e3e6',
    grey3: '#D2D2D1',
    grey4: '#CCCCCC',
    grey5: '#EEEEED',
    grey6: '#3D3D3C',
    grey7: '#686867',
    black: '#000000',
    info: '#07afdc',
    danger: '#dc2832',
    success: '#247631',
    warning: '#E75C31',
    border: '#979797',
    error: '#DB242E',
    subtleError: '#FEF6F7',
    subtle: 'rgba(32, 30, 30, 0.5)'
  }
};
