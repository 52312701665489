import * as config from 'config';
import { BasicDataAttributes, BasicDataResponse } from 'models/basicData';
import {
  AppTranslation,
  Translation,
  defaultLanguageCode
} from 'utils/constants';
import { apiFetch, defaultHeaders } from 'utils/fetchUtils';

import availableLanguages from 'assets/languages/available.json';
import defaultTranslation from 'assets/languages/en.json';

function replaceKeyWithValue(str: string, values: object): string {
  let valueStr: string = str;
  Object.entries(values).forEach(([key, value]) => {
    const selector = `{${key}}`;

    if (valueStr.includes(selector)) {
      valueStr = valueStr.replace(selector, value);
    }
  });
  return valueStr;
}

function translateFromLanguage(
  key: string,
  language: AppTranslation,
  parameters: Record<string, any> = {}
) {
  const sections = key.split('.');
  let lookUpObject: any = language;
  let foundKey = null;

  sections.forEach((section) => {
    if (typeof lookUpObject[section] === 'object') {
      lookUpObject = lookUpObject[section];
    } else {
      foundKey = lookUpObject[section];
    }
  });

  if (foundKey) {
    return replaceKeyWithValue(foundKey, parameters);
  }
  return foundKey;
}

export function translate(
  key: string,
  language: AppTranslation,
  parameters?: Record<string, any>
): string {
  const translationKey = String(key).trim();
  if (!translationKey) {
    return '';
  }

  let translation = translateFromLanguage(translationKey, language, parameters);
  if (!translation) {
    console.info(
      `No translation found for key: ${translationKey}, fall back to default translation`
    );

    translation = translateFromLanguage(
      translationKey,
      defaultTranslation,
      parameters
    );
  }

  return translation ?? translationKey;
}

/**
 * Try to load a language by a language code.
 * If we fail to load the desired language, the default language is returned.
 */
export async function loadLanguage(
  languageCode: string,
  languageRegionCode?: string
): Promise<Translation> {
  const fullLanguageCode = languageRegionCode
    ? `${languageCode}-${languageRegionCode}`
    : languageCode;

  if (!availableLanguages.includes(fullLanguageCode)) {
    return { languageCode: defaultLanguageCode, language: defaultTranslation };
  }

  try {
    const data = await import(
      `../../assets/languages/${fullLanguageCode}.json`
    );
    return { languageCode, languageRegionCode, language: { ...data } };
  } catch (err) {
    console.warn(
      `<< loadLanguage() - failed to load language by code: ${fullLanguageCode}. Returning default language.`,
      err
    );
  }

  return { languageCode: defaultLanguageCode, language: defaultTranslation };
}

export async function getBasicData(): Promise<BasicDataAttributes> {
  const url = `${config.backendApi}/basic-data`;

  const json = await apiFetch<BasicDataResponse>(url, {
    headers: { ...defaultHeaders }
  });

  return json.data.attributes;
}
