import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';

import * as customerService from 'services/customerService/customerService';
import {
  displayApiError,
  displayGlobalError
} from 'store/globalErrorState/globalErrorState';
import { ApiError, ApiErrorDto } from 'models/errors/ApiError';
import {
  Address,
  CreateCustomerAttributes,
  CustomerAccountStatus,
  ICustomerConsentType
} from 'models/customer';
import { PhoneNumber } from 'models/phoneNumber';
import { ICaptchaResponse } from 'models/captcha';
import { JsonData } from 'models/jsonApi';
import type { RootState } from 'store';

type SignupCompleteStatus = {
  created: boolean;
  emailVerified: boolean;
  emailPreviouslyVerified: boolean;
};

export type SignupAttributes = {
  email: string;
  password: string;
  confirmedPassword: string;
};

export type FinalizeSignupAttributes = {
  firstName: string;
  lastName: string;
  address: Address;
  phoneNumber?: PhoneNumber;
  language: string;
  country: string;
  captchaCode: string;
  consent: boolean;
};

export type ISignUpState = {
  email: string;
  password: string;
  confirmedPassword: string;
  firstName: string;
  lastName: string;
  language: string;
  country: string;
  address: Address;
  phoneNumber: PhoneNumber;
  isLoading: boolean;
  isSubmitted: boolean;
  isFinalized: boolean;
  completeStatus: SignupCompleteStatus;
  captchaCode: string;
  captchaElement: string | null;
  consentType: ICustomerConsentType | null;
  consentStatus: boolean | null;
  error: ApiError | null;
};

export const defaultSignupState: ISignUpState = {
  email: '',
  password: '',
  confirmedPassword: '',
  firstName: '',
  lastName: '',
  language: '',
  country: '',
  address: {},
  phoneNumber: {
    dialCode: '',
    number: ''
  },
  isLoading: false,
  isSubmitted: false,
  isFinalized: false,
  completeStatus: {
    created: false,
    emailVerified: false,
    emailPreviouslyVerified: false
  },
  captchaCode: '',
  captchaElement: null,
  consentType: null,
  consentStatus: null,
  error: null
};

// Export slice
export const signUpSlice = createSlice({
  name: 'signup',
  initialState: defaultSignupState,
  reducers: {
    resetSignupState() {
      return defaultSignupState;
    },
    cancelFinalization: (state) => {
      state.password = defaultSignupState.password;
      state.confirmedPassword = defaultSignupState.confirmedPassword;
      state.isSubmitted = defaultSignupState.isSubmitted;
      state.isFinalized = defaultSignupState.isFinalized;
      state.completeStatus = defaultSignupState.completeStatus;
    },
    getCaptchaStart: (state) => {
      state.captchaElement = null;
    },
    getCaptchaSuccess: (state, action: PayloadAction<string>) => {
      state.captchaElement = action.payload;
      state.error = null;
    },
    getCaptchaFailure: (state, action: PayloadAction<ApiError>) => {
      state.error = action.payload;
    },
    submitCredentialsStart: (
      state,
      action: PayloadAction<SignupAttributes>
    ) => {
      state.email = action.payload.email;
      state.password = action.payload.password;
      state.confirmedPassword = action.payload.confirmedPassword;
      state.isLoading = true;
    },
    submitCredentialsSuccess: (
      state,
      action: PayloadAction<{
        captcha: string;
        consentType: ICustomerConsentType;
      }>
    ) => {
      const { captcha, consentType } = action.payload;
      state.captchaElement = captcha;
      state.consentType = consentType;

      state.isLoading = false;
      state.isSubmitted = true;
      state.error = null;
    },
    submitCredentialsFailure: (state, action: PayloadAction<ApiError>) => {
      state.isLoading = false;
      state.isSubmitted = false;
      state.error = action.payload;
    },
    submitFinalization: (
      state,
      action: PayloadAction<FinalizeSignupAttributes>
    ) => {
      const { phoneNumber } = action.payload;

      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.language = action.payload.language;
      state.country = action.payload.country;
      state.address = action.payload.address;

      if (phoneNumber) {
        state.phoneNumber = phoneNumber;
      }

      state.captchaCode = action.payload.captchaCode;
      state.consentStatus = action.payload.consent;
      state.isFinalized = true;
    },
    signupStart: (state) => {
      state.isLoading = true;
      state.error = null;
      state.isFinalized = false;
    },
    signupSuccess: (state, action: PayloadAction<CustomerAccountStatus>) => {
      state.isLoading = false;
      state.error = null;
      state.completeStatus.created = action.payload.accountExists;
      state.completeStatus.emailVerified = action.payload.emailVerified;
    },
    signupFailure: (state, action: PayloadAction<ApiError>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    verifyAccountStart: (state) => {
      state.isLoading = true;
    },
    // Action boolean tells if the account is already verified.
    verifyAccountSuccess: (state, action: PayloadAction<boolean>) => {
      state.isLoading = false;

      if (action.payload) {
        state.completeStatus.emailPreviouslyVerified = true;
      } else {
        state.completeStatus.emailVerified = true;
      }
      state.error = null;
    },
    verifyAccountFailure: (state, action: PayloadAction<ApiError>) => {
      state.isLoading = false;
      state.completeStatus.emailVerified = false;
      state.error = action.payload;
    },
    sendVerificationEmailStart: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    sendVerificationEmailSuccess: (state) => {
      state.isLoading = false;
      state.error = null;
    },
    sendVerificationEmailFailure: (state, action: PayloadAction<ApiError>) => {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

// Export actions
export const {
  resetSignupState,
  getCaptchaStart,
  getCaptchaSuccess,
  getCaptchaFailure,
  submitCredentialsStart,
  submitCredentialsSuccess,
  submitCredentialsFailure,
  submitFinalization,
  cancelFinalization,
  signupStart,
  signupSuccess,
  signupFailure,
  verifyAccountStart,
  verifyAccountSuccess,
  verifyAccountFailure,
  sendVerificationEmailStart,
  sendVerificationEmailSuccess,
  sendVerificationEmailFailure
} = signUpSlice.actions;

// Export reducer
export const signUpReducer = signUpSlice.reducer;

// Export selector
export const signUpSelector = (state: RootState) => state.signUp;

// Export thunks
export function submitEmailAndPassword(
  email: string,
  password: string,
  confirmedPassword: string
) {
  return async (dispatch: Dispatch) => {
    dispatch(submitCredentialsStart({ email, password, confirmedPassword }));
    try {
      // Check email status
      const emailStatus = await customerService.getAccountStatusByEmail(email);

      // Throw error if account already exist
      if (emailStatus.accountExists) {
        throw ApiErrorDto.fromError({
          message: 'Email unavailable',
          status: 400,
          code: 'email.unavailable'
        });
      }

      // Get captcha and consent
      const [captchaRes, consentType]: [
        ICaptchaResponse,
        ICustomerConsentType
      ] = await Promise.all([
        customerService.generateCaptcha(email),
        customerService.getConsentType()
      ]);

      dispatch(
        submitCredentialsSuccess({
          captcha: captchaRes.data.attributes.svg,
          consentType
        })
      );
    } catch (e: any) {
      dispatch(submitCredentialsFailure(e));
    }
  };
}

export function fetchCaptcha(email: string) {
  return async (dispatch: Dispatch) => {
    dispatch(getCaptchaStart());
    try {
      const response = await customerService.generateCaptcha(email);
      dispatch(getCaptchaSuccess(response.data.attributes.svg));
    } catch (e: any) {
      dispatch(getCaptchaFailure(e));
    }
  };
}

export function signUp(
  customer: CreateCustomerAttributes,
  captchaCode: string,
  meta?: JsonData
) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(signupStart());
      await customerService.create(customer, captchaCode, meta);

      const status = await customerService.getAccountStatusByEmail(
        customer.email
      );
      dispatch(signupSuccess(status));
    } catch (e: any) {
      dispatch(signupFailure(e));
      dispatch(displayApiError(e));
    }
  };
}

export function verifyCustomerAccount(email: string, code: string) {
  return async (dispatch: Dispatch) => {
    dispatch(verifyAccountStart());
    try {
      await customerService.verifyAccount(email, code);
      dispatch(verifyAccountSuccess(false));
    } catch (verificationError: any) {
      // If verifification fails we're checking if the account is previously verified
      try {
        const { emailVerified } =
          await customerService.getAccountStatusByEmail(email);
        // If the account is not verified we assume that the code is invalid.
        if (!emailVerified) {
          dispatch(verifyAccountFailure(verificationError));
          dispatch(displayGlobalError('signup.invalid.code'));
          return;
        }
        // If the account is verified we assume that the code has already been used.
        dispatch(verifyAccountSuccess(true));
      } catch (statusError: any) {
        // If the status request fails we act as if the code is invalid.
        dispatch(verifyAccountFailure(statusError));
        dispatch(displayGlobalError('signup.invalid.code'));
      }
    }
  };
}

export function resendVerificationEmail(email: string, iprId?: string) {
  return async (dispatch: Dispatch) => {
    dispatch(sendVerificationEmailStart());

    try {
      await customerService.resendVerificationEmail(email, iprId);
      dispatch(sendVerificationEmailSuccess());
      dispatch(displayGlobalError('signup.resend.success'));
    } catch (e: any) {
      dispatch(sendVerificationEmailFailure({ ...e }));
      dispatch(displayGlobalError('signup.resend.failure'));
    }
  };
}

export function activateAccount(email: string, uri: string, text: string) {
  return async (dispatch: Dispatch) => {
    dispatch(sendVerificationEmailStart());
    try {
      await customerService.activateLoginAccount(email, uri, text);
      dispatch(sendVerificationEmailSuccess());
      dispatch(displayGlobalError('signup.resend.success'));
    } catch (e: any) {
      dispatch(sendVerificationEmailFailure(e));
      dispatch(displayGlobalError('signup.resend.failure'));
    }
  };
}
