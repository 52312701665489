import { FC } from 'react';
import TableSmallCell from './TableSmallCell';

import {
  Table,
  TableHeadCell,
  TableBodyCell,
  SmallTable,
  SmallTableRow
} from './styled';

type TableKey = string | number;

export type TableColItem = {
  key: TableKey;
  item: string;
};

export type TableBodyItem = {
  key: TableKey;
  cols: TableColItem[];
};

type Props = {
  head: TableColItem[];
  body: TableBodyItem[];
};

const CardTable: FC<Props> = ({ head, body }) => {
  return (
    <>
      <SmallTable>
        {body.map((row, i) => (
          <SmallTableRow key={row.key} last={body.length === i + 1}>
            {row.cols.map((col, j) => (
              <TableSmallCell key={col.item} name={head[j].item}>
                {col.item}
              </TableSmallCell>
            ))}
          </SmallTableRow>
        ))}
      </SmallTable>
      <Table>
        {head ? (
          <thead>
            <tr>
              {head.map((col) => (
                <TableHeadCell key={col.key}>{col.item}</TableHeadCell>
              ))}
            </tr>
          </thead>
        ) : null}
        <tbody>
          {body.map((row, i) => (
            <tr key={row.key}>
              {row.cols.map((col) => (
                <TableBodyCell key={col.key} last={body.length === i + 1}>
                  {col.item || '-'}
                </TableBodyCell>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default CardTable;
