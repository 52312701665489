import styled from 'styled-components';
import { breakpoints } from 'styles';

type Props = {
  $border?: boolean;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div<Props>`
  display: flex;
  align-items: center;
  gap: 24px;
  border-bottom: ${({ theme, $border }) =>
    $border ? `1px solid ${theme.colors.grey2}` : 'none'};
  cursor: pointer;
  padding: 16px 0;
  margin: 0;

  @media (max-width: ${breakpoints.s}px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  p {
    font-size: 16px;
    margin: 0;
  }
`;

export const AdditionalText = styled.p`
  margin: 0;
`;
