import styled from 'styled-components';
import { breakpoints } from 'styles';

export const PageContent = styled.div`
  margin-bottom: 32px;

  h1 {
    margin-bottom: 16px;
  }

  h2 {
    margin: 24px 0 16px;
  }
`;

export const Card = styled.div`
  background-color: ${({ theme }) => theme.colors.surface};
  color: ${({ theme }) => theme.colors.onSurface};
  border-radius: 32px;

  p {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const DataGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  padding: 20px;

  @media (max-width: ${breakpoints.l}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const ConsentGrid = styled.div`
  padding: 8px 20px;
`;

export const EmptyText = styled.p`
  padding: 20px;
  margin: 0;
`;

export const BusinessAccountInfo = styled.p`
  margin: 16px 0 0;
`;
