import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'store';
import * as authService from 'services/authService/authService';
import { AuthSession, AuthTokensAttributes } from 'models/auth';
import { CustomerDto } from 'models/customer';
import { ApiError } from 'models/errors/ApiError';
import { displayApiError } from '../globalErrorState';
import { getCustomerSuccess } from '../customerState';
import * as localStorage from 'utils/localStorageUtils';

export type AuthState = {
  authCheck: boolean;
  session: AuthSession;
  isLoading: boolean;
  error: ApiError | null;
};

export const defaultAuthState: AuthState = {
  authCheck: false,
  session: {
    authenticated: false,
    refreshToken: null,
    accessToken: null,
    customerId: null
  },
  isLoading: false,
  error: null
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: defaultAuthState,
  reducers: {
    authCheck: (state) => {
      state.authCheck = true;
    },
    logoutStart: (state) => {
      state.isLoading = true;
    },
    logoutSuccess: (state) => {
      localStorage.removeItem('session');
      localStorage.removeItem('auth');
      localStorage.removeItem('customer');
      state.authCheck = false;
      state.isLoading = false;
      state.error = null;
      state.session = defaultAuthState.session;
    },
    validateTokenStart: (
      state,
      action: PayloadAction<AuthTokensAttributes>
    ) => {
      const { accessToken, refreshToken } = action.payload;

      localStorage.setItem('session', { accessToken, refreshToken });

      state.session.accessToken = accessToken;
      state.session.refreshToken = refreshToken;
      state.isLoading = true;
    },
    validateTokenSuccess: (
      state,
      action: PayloadAction<{ customerId: string }>
    ) => {
      const { customerId } = action.payload;
      state.session.customerId = customerId;
      state.session.authenticated = true;
      state.isLoading = false;
      state.error = null;
    },
    validateTokenFail: (state, action: PayloadAction<ApiError>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    refreshTokenSuccess: (
      state,
      action: PayloadAction<AuthTokensAttributes>
    ) => {
      const { accessToken, refreshToken } = action.payload;

      localStorage.setItem('session', { accessToken, refreshToken });

      state.session.accessToken = accessToken;
      state.session.refreshToken = refreshToken;
      state.session.authenticated = true;
    }
  }
});

// Export actions
export const {
  authCheck,
  logoutStart,
  logoutSuccess,
  validateTokenStart,
  validateTokenSuccess,
  validateTokenFail,
  refreshTokenSuccess
} = authSlice.actions;

// Export reducer
export const authReducer = authSlice.reducer;

// Export selector
export const authSelector = (state: RootState) => state.auth;

// Export thunks
export function checkSession() {
  return async (dispatch: Dispatch) => {
    const session = localStorage.getItem<AuthTokensAttributes>('session');
    if (session) {
      const { accessToken, refreshToken } = session;
      dispatch(validateTokenStart({ accessToken, refreshToken }));
      try {
        const validateTokenResponse =
          await authService.validateToken(accessToken);
        const customer = CustomerDto.fromJsonApiData(
          validateTokenResponse.data
        );
        dispatch(getCustomerSuccess(customer.toJson()));
        dispatch(validateTokenSuccess({ customerId: customer.id }));
      } catch (e: any) {
        dispatch(validateTokenFail(e));
        dispatch(logoutSuccess());
      }
    }
    dispatch(authCheck());
  };
}

export function logout(accessToken?: string) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(logoutStart());
      if (accessToken) {
        await authService.logoutWithToken(accessToken);
      }
    } catch {
      // Do nothing
    } finally {
      dispatch(logoutSuccess());
      dispatch(authCheck());
    }
  };
}

export function validateToken(accessToken: string, refreshToken: string) {
  return async (dispatch: Dispatch) => {
    dispatch(validateTokenStart({ accessToken, refreshToken }));
    try {
      const validateTokenResponse =
        await authService.validateToken(accessToken);
      const customer = CustomerDto.fromJsonApiData(validateTokenResponse.data);
      dispatch(getCustomerSuccess(customer.toJson()));
      dispatch(validateTokenSuccess({ customerId: customer.id }));
    } catch (e: any) {
      dispatch(validateTokenFail(e));
      dispatch(displayApiError(e));
    }
  };
}
