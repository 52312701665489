import styled from 'styled-components';

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
`;

export const Image = styled.img`
  height: 5rem;
  width: auto;
`;

export const Form = styled.form`
  width: 100%;
  margin-top: 2rem;
  text-align: center;
`;

export const Divider = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 24px 0;

  div {
    width: 100%;
    height: 0;
    border-top: 1px solid ${({ theme }) => theme.colors.border};
  }

  p {
    margin: 0 16px;
    text-align: center;
    font-size: 18px;
    text-transform: lowercase;
    color: ${({ theme }) => theme.colors.subtle};
  }
`;
