import { FC, useMemo } from 'react';
import Heading from '../../Heading';
import { HeaderContainer, HeaderColumnLeft, HeaderColumnRight } from './styled';

type Props = {
  title: string;
  subtitle?: string;
  image?: any;
  imageAlt?: string;
  responsiveImage?: boolean;
};

const PageHeader: FC<Props> = ({
  title,
  subtitle,
  image,
  imageAlt,
  responsiveImage = false
}) => {
  // Render image
  const headerImage = useMemo(() => {
    if (!image) {
      return null;
    }
    return (
      <HeaderColumnRight $responsiveImage={responsiveImage}>
        <img src={image} alt={imageAlt ?? 'Header image'} />
      </HeaderColumnRight>
    );
  }, [image, imageAlt, responsiveImage]);

  return (
    <HeaderContainer>
      <HeaderColumnLeft $titleMargin={!!subtitle}>
        <Heading>{title}</Heading>
        {subtitle ? <p>{subtitle}</p> : null}
      </HeaderColumnLeft>
      {headerImage}
    </HeaderContainer>
  );
};

export default PageHeader;
