import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Name = styled.p`
  color: ${({ theme }) => theme.colors.grey6};
  font-size: 12px;
  margin: 0;
`;

export const Value = styled.p`
  margin: 0;
`;
