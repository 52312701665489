import { FC } from 'react';
import { ThemeColor } from 'styles';
import { Marker } from './styled';

type Props = {
  color: ThemeColor;
};

const StatusMarker: FC<Props> = ({ color }) => {
  return <Marker data-testid="status-marker" color={color} />;
};

export default StatusMarker;
