import { FC, ReactNode } from 'react';
import { Container, Name, Value } from './styled';

type Props = {
  name: string;
  children: ReactNode;
};

const TableSmallCell: FC<Props> = ({ name, children }) => {
  return (
    <Container>
      <Name>{name}</Name>
      <Value>{children ?? '–'}</Value>
    </Container>
  );
};

export default TableSmallCell;
