import { FC } from 'react';
import { Rect } from './styled';

type Props = {
  isOpen: boolean;
};

const ExpandIcon: FC<Props> = ({ isOpen }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" data-testid="expand-icon">
      <Rect x="1" y="9" width="18" height="2" rx="0.5" />
      <Rect
        x="1"
        y="9"
        width="18"
        height="2"
        rx="0.5"
        animate={{
          rotate: isOpen ? 0 : -90
        }}
        transition={{ duration: 0.2, type: 'tween' }}
      />
    </svg>
  );
};

export default ExpandIcon;
