import { FC, useEffect } from 'react';
import {
  Navigate,
  Outlet,
  createBrowserRouter,
  RouterProvider
} from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { authSelector, checkSession } from 'store/authState';
import { displayGlobalError } from 'store/globalErrorState';

import LoginPage from 'pages/LoginPage';
import MyProfilePage from 'pages/ProfilePage';
import SignUpPage from 'pages/SignUpPage';
import ProductsPage from 'pages/ProductsPage';
import ProductDetailsPage from 'pages/ProductsPage/ProductDetailsPage';
import ProductRegistrationPage from 'pages/ProductRegistrationPage';
import RegisterInviteProductsPage from 'pages/RegisterInviteProductsPage';
import RegisterProfessionalProductsPage from 'pages/RegisterProfessionalProductsPage';
import ProductInformationPage from 'pages/ProductInformationPage';
import ResetPasswordPage from 'pages/ResetPasswordPage';
import VerifyEmailPage from 'pages/VerifyEmailPage';
import ConfirmChangeEmailPage from 'pages/ConfirmChangeEmailPage';
import ConfirmDeleteAccountPage from 'pages/ConfirmDeleteAccountPage';
import ActivateAccountPage from 'pages/ActivateAccountPage';
import EmailVerificationPage from 'pages/VerifyEmailPage/EmailVerificationPage';
import EditProfilePage from 'pages/ProfilePage/EditProfilePage';
import NotFoundPage from 'pages/NotFoundPage';

import ErrorBoundary from 'components/fragments/ErrorBoundary';
import Layout from 'components/fragments/Layout';
import Loader from 'components/UI/Loader';
import PageWrapper from 'components/UI/Page/PageWrapper';

import * as routes from './routes';

// Protected route
const PrivateRoute: FC = () => {
  const dispatch = useAppDispatch();

  const {
    session: { authenticated }
  } = useAppSelector(authSelector);

  useEffect(() => {
    if (!authenticated) {
      dispatch(displayGlobalError('not.authenticated'));
    }
  }, [authenticated, dispatch]);

  if (!authenticated) {
    return <Navigate to={routes.LOGIN} />;
  }

  return <Outlet />;
};

// Root route
const RootRoute: FC = () => {
  const {
    session: { authenticated }
  } = useAppSelector(authSelector);

  if (!authenticated) {
    return <Navigate to={routes.LOGIN} />;
  }

  return <Navigate to={routes.PRODUCTS} />;
};

// Router
const router = createBrowserRouter([
  {
    element: <ErrorBoundary />,
    children: [
      {
        element: <Layout />,
        children: [
          {
            element: <PrivateRoute />,
            children: [
              {
                path: routes.INVITE_PRODUCTS_REG,
                element: <RegisterInviteProductsPage />
              },
              {
                path: routes.PRODUCTS,
                children: [
                  {
                    index: true,
                    element: <ProductsPage />
                  },
                  {
                    path: ':id',
                    element: <ProductDetailsPage />
                  }
                ]
              },
              {
                path: routes.PROFILE,
                children: [
                  {
                    index: true,
                    element: <MyProfilePage />
                  },
                  {
                    path: 'edit',
                    element: <EditProfilePage />
                  }
                ]
              }
            ]
          },
          {
            path: routes.SIGNUP,
            element: <SignUpPage />
          },
          {
            path: routes.VERIFY_EMAIL,
            element: <VerifyEmailPage />
          },
          {
            path: routes.ACTIVATE_ACCOUNT,
            element: <ActivateAccountPage />
          },
          {
            path: routes.CONFIRM_CHANGE_EMAIL,
            element: <ConfirmChangeEmailPage />
          },
          {
            path: routes.CONFIRM_DELETE_ACCOUNT,
            element: <ConfirmDeleteAccountPage />
          },
          {
            path: routes.EMAIL_VERIFICATION,
            element: <EmailVerificationPage />
          },
          {
            path: routes.RESET_PASSWORD,
            element: <ResetPasswordPage />
          },
          {
            path: routes.PRODUCT_REG,
            element: <ProductRegistrationPage />
          },
          {
            path: routes.PRODUCT_INFO,
            element: <ProductInformationPage />
          },
          {
            path: routes.PRODUCT_REG_PROFESSIONAL,
            element: <RegisterProfessionalProductsPage />
          },
          {
            path: routes.LOGIN,
            element: <LoginPage />
          },
          {
            path: routes.ROOT,
            element: <RootRoute />
          },
          {
            path: '*',
            element: <NotFoundPage />
          }
        ]
      }
    ]
  }
]);

const Router: FC = () => {
  const dispatch = useAppDispatch();
  const { authCheck } = useAppSelector(authSelector);

  // Check auth
  useEffect(() => {
    dispatch(checkSession());
  }, [dispatch]);

  if (!authCheck) {
    return (
      <PageWrapper>
        <Loader center size="large" />
      </PageWrapper>
    );
  }

  return <RouterProvider router={router} />;
};

export default Router;
