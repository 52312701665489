import { MenuItemVisibility, MenuLinkItem } from 'models/layout';
import * as routes from 'router/routes';
import defaultTranslation from 'assets/languages/en.json';

// Language
export type AppTranslation = typeof defaultTranslation;
export type Translation = {
  languageCode: string;
  languageRegionCode?: string;
  language: AppTranslation;
};

export const defaultLanguageCode = 'en';
export const defaultCountryCode = 'GB';
export const supportedNorwegianLangCode = 'nb';
export const unSupportedNorwegianLangCodes = ['no', 'nn'];
export const supportedRegionalLangCodes = ['pt-BR'];

// Links
export const privacyPolicyUrl = 'https://www.husqvarna.com/privacy/';
export const fleetServicesUrl = 'https://www.husqvarna.com/fleet-services/';
export const howToFindProductInfoUrl =
  'https://www.husqvarna.com/support/husqvarna-self-service/ka-01030/';

// Regex
export const mailRegex =
  /^[-!#$%&'*+/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-?\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
export const noSpecialCharsRegex = /[^a-zA-Z0-9]/g;

// Other
export const enum contractTypes {
  TermsOfUse = 'TERMS_AND_CONDITIONS'
  // PrivacyPolicy = 'PRIVACY_POLICY'
}
export const mavenoidMainProductId = 9595839;

// Menus
export const moreMenuItems = (iprId: string) => [
  {
    id: 3,
    name: 'Delete my product (Not done)',
    action: () => console.log(`Delete ${iprId}`)
  }
];

export const menuLinks: MenuLinkItem[] = [
  {
    name: 'menu.my_products',
    link: routes.PRODUCTS,
    visibility: MenuItemVisibility.Private
  },
  {
    name: 'menu.my_profile',
    link: routes.PROFILE,
    visibility: MenuItemVisibility.Private
  },
  {
    name: 'menu.login',
    link: routes.LOGIN,
    visibility: MenuItemVisibility.Public
  },
  {
    name: 'menu.signup',
    link: routes.SIGNUP,
    visibility: MenuItemVisibility.Public
  },
  {
    name: 'menu.product_registration',
    link: routes.PRODUCT_REG,
    visibility: MenuItemVisibility.Hybrid
  }
];

export const externalMenuLinks = [
  {
    link: 'https://husqvarna.com',
    name: 'menu.husqvarna'
  },
  {
    link: 'https://www.husqvarna.com/contact/',
    name: 'menu.support'
  }
];

export const externalFooterLinks = [
  {
    link: privacyPolicyUrl,
    name: 'menu.privacy'
  },
  {
    link: 'https://www.husqvarna.com/terms/',
    name: 'menu.terms'
  },
  {
    link: 'https://www.husqvarna.com/cookies/',
    name: 'menu.cookies'
  },
  {
    link: 'https://www.husqvarna.com/imprint/',
    name: 'menu.imprint'
  }
];
