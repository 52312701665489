import { FC, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useFetchInvite } from 'hooks/useFetchInvite';
import { useQuery } from 'hooks/useQuery';
import { useTranslations } from 'hooks/useTranslations';
import * as routes from 'router/routes';
import { signUpSelector, verifyCustomerAccount } from 'store/signupState';
import { showInfoToast, showSuccessToast } from 'utils/toastUtils';

import Loader from 'components/UI/Loader';
import PageImageWrapper from 'components/UI/Page/PageImageWrapper';

const EmailVerificationPage: FC = () => {
  const t = useTranslations();
  const dispatch = useAppDispatch();
  const {
    completeStatus: { emailVerified, emailPreviouslyVerified },
    error
  } = useAppSelector(signUpSelector);

  // Params
  const [email, code, iprId, inviteCode] = useQuery(
    'email',
    'code',
    'iprId',
    'inviteId'
  );
  const isInvalid = !email && !code;

  // Hooks
  useFetchInvite({ iprId, code: inviteCode });

  // Verify
  useEffect(() => {
    // Email & code
    if (email && code) {
      dispatch(verifyCustomerAccount(email, code));
    }
  }, [dispatch, email, code]);

  // Success toast
  useEffect(() => {
    if (emailVerified) {
      showSuccessToast(t('email_verification_page.account_verified'));
    }
    if (emailPreviouslyVerified) {
      showInfoToast(t('email_verification_page.account_already_verified'));
    }
  }, [emailVerified, emailPreviouslyVerified, t]);

  if (error && email) {
    return (
      <Navigate
        to={`${routes.VERIFY_EMAIL}?email=${encodeURIComponent(email)}`}
      />
    );
  }

  // Redirect to login if invalid or verified
  if (isInvalid || emailVerified || emailPreviouslyVerified) {
    return <Navigate to={routes.LOGIN} />;
  }

  return (
    <PageImageWrapper>
      <Loader center size="large" />
    </PageImageWrapper>
  );
};

export default EmailVerificationPage;
