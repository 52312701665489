import { FC, useCallback, useEffect, useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { authSelector } from 'store/authState';
import { scanSelector, closeScanner, scanResult } from 'store/scanState';
import { customerSelector } from 'store/customerState';
import { useGetProductInvitesByEmailQuery } from 'store/productService';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

import BarcodeScanner from 'components/modals/BarcodeScanner';
import Navbar from 'components/fragments/Navbar';

import { Main } from './styled';

const Layout: FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { scanActive } = useAppSelector(scanSelector);
  const { customer } = useAppSelector(customerSelector);
  const { session } = useAppSelector(authSelector);

  // Hooks
  const { data = [] } = useGetProductInvitesByEmailQuery(
    { email: customer?.email },
    { skip: !session?.authenticated || !customer?.email }
  );

  // Scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  // Close scanner
  const onCloseScanner = useCallback(
    () => dispatch(closeScanner()),
    [dispatch]
  );

  // Scan result
  const onScanResult = useCallback(
    (result: any) => dispatch(scanResult(result)),
    [dispatch]
  );

  // Render scanner
  const scanner = useMemo(() => {
    if (!scanActive) {
      return null;
    }
    return (
      <BarcodeScanner onClose={onCloseScanner} scanResult={onScanResult} />
    );
  }, [scanActive, onCloseScanner, onScanResult]);

  return (
    <>
      <Navbar invites={data} session={session} />
      <Main>
        <Outlet />
      </Main>
      {scanner}
    </>
  );
};

export default Layout;
