import styled from 'styled-components';
import { breakpoints } from 'styles';

type Props = {
  $columns?: number;
};

export const Container = styled.div<Props>`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: 100%;
  max-width: 1500px;
  margin-bottom: 16px;

  @media (max-width: ${breakpoints.m}px) {
    display: flex;
    flex-direction: column;
    gap: 0;
  }

  @media (min-width: ${breakpoints.xl}px) {
    grid-template-columns: repeat(${({ $columns }) => $columns}, 1fr);
  }
`;
