import styled from 'styled-components';
import { motion } from 'framer-motion';
import { breakpoints } from 'styles';

export const DrawerBackground = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000000aa;
  cursor: pointer;
  z-index: 99;
`;

type DrawerProps = {
  position: 'left' | 'right';
};

export const DrawerContainer = styled(motion.div)<DrawerProps>`
  position: fixed;
  z-index: 100;
  width: 100%;
  max-width: 600px;
  background-color: ${({ theme }) => theme.colors.surface};
  top: 0;
  bottom: 0;
  right: ${({ position }) => (position === 'right' ? '0' : 'unset')};
  left: ${({ position }) => (position === 'left' ? '0' : 'unset')};
`;

export const DrawerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 32px 24px 48px;

  h3 {
    margin: 0;
  }

  @media (max-width: ${breakpoints.s}px) {
    padding: 12px 8px 12px 20px;
  }
`;

export const DrawerBody = styled.div`
  overflow-y: auto;
  height: calc(100vh - 96px);

  @media (max-width: ${breakpoints.s}px) {
    height: calc(100vh - 72px);
  }
`;
