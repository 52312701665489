import { FC, useCallback } from 'react';

import { useQuery } from 'hooks/useQuery';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { resendVerificationEmail, signUpSelector } from 'store/signupState';
import { inviteSelector } from 'store/inviteState';

import VerifyAccount from 'components/fragments/VerifyAccount';
import Loader from 'components/UI/Loader';

const VerifyEmailPage: FC = () => {
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector(signUpSelector);
  const { invite } = useAppSelector(inviteSelector);

  // Params
  const [email] = useQuery('email');

  // Resend verification
  const handleResendSubmit = useCallback(() => {
    if (email) {
      dispatch(resendVerificationEmail(email, invite?.products[0].iprId));
    }
  }, [dispatch, invite, email]);

  if (isLoading) {
    return <Loader center size="large" />;
  }

  return (
    <VerifyAccount
      title="verify_email_page.title"
      description={[
        'verify_email_page.paragraph',
        'verify_email_page.paragraph2'
      ]}
      buttonText="verify_email_page.btn_resend"
      submitForm={handleResendSubmit}
      email={email}
    />
  );
};

export default VerifyEmailPage;
