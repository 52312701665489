export type PhoneCountryMeta = {
  name: string;
  dialCode: string;
  countryCode: string;
};

function dialCodeSortFn(a: PhoneCountryMeta, b: PhoneCountryMeta) {
  if (a.dialCode > b.dialCode) {
    return -1;
  }
  if (b.dialCode > a.dialCode) {
    return 1;
  }
  return 0;
}

export function sortMetaByDialCode(
  meta: PhoneCountryMeta[],
  copy = false
): PhoneCountryMeta[] {
  if (copy) {
    return [...meta].sort(dialCodeSortFn);
  }

  return meta.sort(dialCodeSortFn);
}

/**
 * Finding a country phone meta matching a default dial code or
 * returning the phone meta of the app default country
 */
export function findDefaultCountryPhoneMeta(
  countryCodes: PhoneCountryMeta[],
  defaultDialCode?: string,
  defaultCountry?: string
) {
  const validList = countryCodes.filter(
    (cc) => cc.dialCode === defaultDialCode || cc.countryCode === defaultCountry
  );

  if (!validList.length) {
    return null;
  }

  if (defaultCountry && validList.length > 1) {
    const found = validList.find(
      (cc) =>
        cc.dialCode === defaultDialCode && cc.countryCode === defaultCountry
    );
    return found ?? validList[0];
  }

  return validList[0];
}
