import styled from 'styled-components';

export const ModalHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const ModalTitle = styled.p`
  color: ${({ theme }) => theme.colors.subtle};
  margin: 0;
  padding: 8px 16px;
`;

export const ModalClose = styled.div`
  display: flex;
  padding: 24px 24px 16px;
  cursor: pointer;

  img {
    height: 32px;
  }
`;

export const ModalBody = styled.div`
  max-height: 550px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 16px 40px 40px;
`;
