import { useEffect, useMemo } from 'react';
import {
  useGetProductInviteByCodeQuery,
  useGetProductInviteByPriQuery
} from 'store/productService';
import { localizationSelector } from 'store/localizationState';
import { setInvite } from 'store/inviteState';
import { useAppDispatch, useAppSelector } from './redux';

type Props = {
  iprId?: string | null;
  husqvarnaId?: string | null;
  pnc?: string | null;
  serialNumber?: string | null;
  code?: string | null;
};

export function useFetchInvite({
  iprId,
  husqvarnaId,
  pnc,
  serialNumber,
  code
}: Props) {
  const dispatch = useAppDispatch();
  const { languageCode: langCode } = useAppSelector(localizationSelector);

  // Hooks
  const codeInvite = useGetProductInviteByCodeQuery({ code }, { skip: !code });
  const priInvite = useGetProductInviteByPriQuery(
    { pnc, serialNumber, husqvarnaId, iprId, langCode },
    { skip: !husqvarnaId && !iprId && !(pnc && serialNumber) }
  );
  const invite = useMemo(
    () => (code ? codeInvite : priInvite),
    [code, codeInvite, priInvite]
  );

  // Set invite
  useEffect(() => {
    const data = invite.data || priInvite.data;
    if (data) {
      dispatch(setInvite(data));
    }
  }, [dispatch, invite, priInvite]);

  return {
    data: invite.data,
    isSuccess: invite.isSuccess,
    isLoading: invite.isLoading,
    isError: invite.isError,
    error: invite.error
  };
}
