import { FC, ReactNode } from 'react';

import { useTranslations } from 'hooks/useTranslations';

import {
  PageContainer,
  ImageSection,
  ImageContent,
  ContentSection,
  ContentContainer,
  FlexContainer,
  ImageTitle,
  ImageDescription
} from './styled';
import Copyright from 'components/UI/Copyright';

type Props = {
  children: ReactNode;
  alignTop?: boolean;
};

const PageImageWrapper: FC<Props> = ({ children, alignTop = false }) => {
  const t = useTranslations();
  return (
    <PageContainer>
      <ImageSection>
        <ImageContent>
          <ImageTitle>{t('start.image_title')}</ImageTitle>
          <ImageDescription>{t('start.image_description')}</ImageDescription>
        </ImageContent>
      </ImageSection>
      <ContentSection>
        <Copyright fullHeight>
          <FlexContainer $alignTop={alignTop}>
            <ContentContainer>{children}</ContentContainer>
          </FlexContainer>
        </Copyright>
      </ContentSection>
    </PageContainer>
  );
};

export default PageImageWrapper;
