import { FC, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';

import { openScanner, scanSelector, resetScanState } from 'store/scanState';
import { inviteSelector, resetInviteState } from 'store/inviteState';

import { ProductRegistrationInfo } from 'models/productRegistration';
import * as routes from 'router/routes';
import { useQuery } from 'hooks/useQuery';
import { useTranslations } from 'hooks/useTranslations';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { howToFindProductInfoUrl } from 'utils/constants';
import { removeSpecialChars } from 'utils/stringUtils';
import labelIconPath from 'assets/images/labelicon.png';
import qrCode from 'assets/vectors/qr.svg';

import SeoHelmet from 'components/fragments/SeoHelmet';
import InputField from 'components/UI/InputField';
import Button from 'components/UI/Buttons/Button';
import PageWrapper from 'components/UI/Page/PageWrapper';
import PageHeader from 'components/UI/Page/PageHeader';
import Copyright from 'components/UI/Copyright';

import { ImageContainer, Image, Form, Divider } from './styled';
import { Link } from 'styles';

const ProductRegistrationPage: FC = () => {
  const t = useTranslations();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { scanResult } = useAppSelector(scanSelector);
  const { invite } = useAppSelector(inviteSelector);

  // Params
  const [pnc, serialNumber, husqvarnaId] = useQuery(
    'pnc',
    'serialNumber',
    'husqvarnaId'
  );

  // Form
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    resetField,
    setValue,
    setError
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      pnc,
      serialNumber,
      husqvarnaId
    }
  });

  // Watch input fields
  const [pncInput, serialNumberInput, husqvarnaIdInput] = watch([
    'pnc',
    'serialNumber',
    'husqvarnaId'
  ]);

  // Apply scan result to HID field
  useEffect(() => {
    if (scanResult) {
      setValue('husqvarnaId', scanResult);
    }
  }, [scanResult, setValue]);

  // Clear existing invite and reset scan state
  useEffect(() => {
    if (invite) {
      dispatch(resetInviteState());
    }

    return () => {
      dispatch(resetScanState());
    };
  }, [dispatch, invite]);

  // Submit
  const onSubmit: SubmitHandler<ProductRegistrationInfo> = useCallback(
    (values) => {
      const qPnc = removeSpecialChars(values.pnc);
      const qSerial = removeSpecialChars(values.serialNumber);
      const qHid = removeSpecialChars(values.husqvarnaId);

      // Validate Husqvarna Id
      if (qHid && qHid.length !== 25) {
        return setError('husqvarnaId', {
          message: 'productregistration.form.husqvarnaId_invalid'
        });
      }

      // Validate PNC
      if (qPnc && qPnc.length !== 9) {
        return setError('pnc', {
          message: 'productregistration.form.pnc_invalid'
        });
      }

      const params = qHid
        ? `husqvarnaId=${qHid}`
        : `productno=${qPnc}&serialNumber=${qSerial}`;

      navigate(`${routes.PRODUCT_INFO}?${params}`);
    },
    [navigate, setError]
  );

  // Validate
  const validateField = useCallback((condition: boolean, message: string) => {
    if (!condition) {
      return false;
    }
    return {
      value: true,
      message
    };
  }, []);

  // Open scanner
  const onClickBarcode = useCallback(() => dispatch(openScanner()), [dispatch]);

  return (
    <PageWrapper responsive={false}>
      <Copyright>
        <SeoHelmet title="productregistration.meta_title" />
        <ImageContainer>
          <Image src={labelIconPath} alt="product registration" />
        </ImageContainer>
        <PageHeader
          title={t('productregistration.title')}
          subtitle={t('productregistration.subtitle')}
        />
        <Link
          href={howToFindProductInfoUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('productregistration.how_to_find_link')}
        </Link>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <InputField
            name="husqvarnaId"
            description="productregistration.form.husqvarnaId"
            error={errors.husqvarnaId}
            register={register('husqvarnaId', {
              required: validateField(
                !pncInput && !serialNumberInput,
                'productregistration.form.husqvarnaId_required'
              )
            })}
            icon={{
              src: qrCode,
              alt: 'qr',
              onClick: onClickBarcode
            }}
            disabled={!!(pncInput || serialNumberInput)}
            reset={resetField}
          />
          <Divider>
            <div />
            <p>{t('productregistration.form.or')}</p>
            <div />
          </Divider>
          <InputField
            name="pnc"
            description="productregistration.form.pnc"
            error={errors.pnc}
            register={register('pnc', {
              required: validateField(
                !husqvarnaIdInput,
                'productregistration.form.pnc_required'
              )
            })}
            disabled={!!husqvarnaIdInput}
            reset={resetField}
          />
          <InputField
            name="serialNumber"
            description="productregistration.form.serial_number"
            error={errors.serialNumber}
            register={register('serialNumber', {
              required: validateField(
                !husqvarnaIdInput,
                'productregistration.form.serial_number_required'
              )
            })}
            disabled={!!husqvarnaIdInput}
            reset={resetField}
          />
          <Button submit fullWidth>
            {t('productregistration.form.btn_submit')}
          </Button>
        </Form>
      </Copyright>
    </PageWrapper>
  );
};

export default ProductRegistrationPage;
