import { FC, useCallback, useMemo, useState } from 'react';

import scanAim from 'assets/vectors/aim.svg';
import closeIcon from 'assets/vectors/close-icon.svg';
import { useTranslations } from 'hooks/useTranslations';

import Heading, { Type as HeadingType } from 'components/UI/Heading';
import Loader from 'components/UI/Loader';

import {
  Container,
  Header,
  IconButton,
  Scanner,
  TextContainer,
  ScanContent,
  ScanLine,
  ScanLoad
} from './styled';

// Filter scan
function getHIdFromScan(data: string) {
  const search = data.substring(data.indexOf('?'));
  const params = new URLSearchParams(search);
  return params.get('HID');
}

type Props = {
  delay?: number;
  onClose: () => void;
  scanResult: (data: any) => void;
};

const BarcodeScanner: FC<Props> = ({ delay = 200, onClose, scanResult }) => {
  const t = useTranslations();

  // State
  const [loaded, setLoaded] = useState<boolean>(false);

  // Load
  const onLoad = useCallback(() => setLoaded(true), []);

  // Scan
  const onScan = useCallback(
    (data: string) => {
      if (!data) {
        return null;
      }

      const hid = getHIdFromScan(data);
      if (hid) {
        scanResult(hid);
        onClose();
      }
    },
    [scanResult, onClose]
  );

  // Error
  const onError = useCallback(
    (error: any) => {
      if (error) {
        onClose();
      }
    },
    [onClose]
  );

  // Render aim
  const scannerAim = useMemo(() => {
    if (!loaded) {
      return (
        <ScanLoad>
          <Loader size="large" />
        </ScanLoad>
      );
    }
    return (
      <>
        <ScanLine
          animate={{
            translateY: [-100, 90, -100]
          }}
          transition={{
            repeat: Infinity,
            repeatDelay: 0,
            duration: 3
          }}
        />
        <img src={scanAim} alt="scan" />
      </>
    );
  }, [loaded]);

  return (
    <Container>
      <Header>
        <IconButton onClick={onClose}>
          <img src={closeIcon} alt="Close" />
        </IconButton>
      </Header>
      <TextContainer>
        <Heading type={HeadingType.h4}>{t('scanner.title')}</Heading>
        <p>{t('scanner.description')}</p>
      </TextContainer>
      <ScanContent>
        {scannerAim}
        <Scanner
          delay={delay}
          onScan={onScan}
          onError={onError}
          onLoad={onLoad}
        />
      </ScanContent>
    </Container>
  );
};

export default BarcodeScanner;
