import { FC, useCallback } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import moment from 'moment';

import { useTranslations } from 'hooks/useTranslations';
import InputField from 'components/UI/InputField';
import Button from 'components/UI/Buttons/Button';
import Modal from 'components/UI/Modal';

import { ModalDescription, ModalForm, ModalTitle } from 'styles';
import { ButtonGroup } from './styled';

type Props = {
  isOpen: boolean;
  close: () => void;
  submit?: (soldDate: number) => void;
};

const CalendarInputModal: FC<Props> = ({ isOpen, close, submit }) => {
  const t = useTranslations();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch
  } = useForm();

  const watchSoldDate = watch('soldDate', undefined);

  const onSubmit: SubmitHandler<FieldValues> = ({ soldDate }) => {
    const unixTimestamp = moment.utc(soldDate).valueOf();
    if (submit) {
      submit(unixTimestamp);
    }
  };

  const setDate = useCallback(
    (date: Date) => {
      setValue('soldDate', moment(date).format('YYYY-MM-DD'), {
        shouldDirty: true
      });
    },
    [setValue]
  );

  const onClose = useCallback(() => close(), [close]);

  return (
    <Modal isOpen={isOpen}>
      <ModalTitle>{t('productregistration.date_popup.header')}</ModalTitle>
      <ModalDescription>
        {t('productregistration.date_popup.sub_header')}
      </ModalDescription>
      <ModalForm onSubmit={handleSubmit(onSubmit)}>
        <InputField
          type="date"
          outlined
          name="soldDate"
          description="productregistration.date_popup.purchase_date"
          defaultValue={moment().format('YYYY-MM-DD')}
          maxDate={moment().toDate()}
          defaultDate={watchSoldDate && new Date(watchSoldDate)}
          setDate={setDate}
          error={errors.soldDate}
          register={register('soldDate', {
            required: {
              value: true,
              message: 'productregistration.date_popup.required'
            },
            validate: (value) =>
              moment(value).isSameOrBefore(moment().format('YYYY-MM-DD')) ||
              t('productregistration.date_popup.past')
          })}
        />
        <ButtonGroup>
          <Button onClick={onClose} color="transparent" textColor="primary">
            {t('productregistration.date_popup.cancel')}
          </Button>
          <Button submit>{t('productregistration.date_popup.confirm')}</Button>
        </ButtonGroup>
      </ModalForm>
    </Modal>
  );
};

export default CalendarInputModal;
