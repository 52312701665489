import { FC, useMemo } from 'react';

type Props = {
  isChecked: boolean;
};

const CheckboxVector: FC<Props> = ({ isChecked }) => {
  // Svg paths
  const paths = useMemo(() => {
    if (isChecked) {
      return (
        <g fill="none" fillRule="evenodd">
          <rect
            width="20"
            height="20"
            fill="#273A60"
            fillRule="nonzero"
            rx="7"
          />
          <path
            stroke="#FFF"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m5 11 4 4 6-9"
          />
        </g>
      );
    }
    return (
      <rect
        width="18"
        height="18"
        fill="none"
        fillRule="evenodd"
        stroke="#686867"
        strokeWidth="2"
        rx="6"
        transform="translate(1 1)"
      />
    );
  }, [isChecked]);

  return (
    <svg width="20" height="20" viewBox="0 0 20 20">
      {paths}
    </svg>
  );
};

export default CheckboxVector;
