import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';

import * as config from 'config';
import { store } from 'store';
import App from './App';

// Sentry sample rate
const sampleRate = config.isDev ? 1.0 : 0.1;

// Init Sentry
Sentry.init({
  dsn: config.sentryDsn,
  environment: config.environment,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration()
  ],
  // Performance Monitoring
  tracesSampleRate: sampleRate,
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    'https://bff.dev-mypages.dss.husqvarnagroup.net/v1',
    'https://bff.qa-mypages.dss.husqvarnagroup.net/v1',
    'https://bff.mypages.dss.husqvarnagroup.net/v1'
  ],
  // Session Replay
  replaysSessionSampleRate: sampleRate,
  replaysOnErrorSampleRate: 1.0
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <HelmetProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </HelmetProvider>
  </React.StrictMode>
);
