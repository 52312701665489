import { FC, ReactNode, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import backArrow from 'assets/vectors/back-arrow.svg';

import { StyledBackButton } from './styled';

type Props = {
  children?: ReactNode;
  backUrl?: string;
  onClick?: () => void;
};

const BackButton: FC<Props> = ({ children, backUrl, onClick }) => {
  const navigate = useNavigate();

  // Go back
  const onBackClick = useCallback(() => {
    if (onClick) {
      return onClick();
    }
    if (backUrl) {
      return navigate(backUrl);
    }
    return navigate(-1);
  }, [navigate, onClick, backUrl]);

  return (
    <StyledBackButton onClick={onBackClick}>
      <img src={backArrow} alt="Back" />
      {children}
    </StyledBackButton>
  );
};

export default BackButton;
