import { FC } from 'react';
import { SDiv } from './styled';

type Props = {
  isVertical?: boolean;
};

const Divider: FC<Props> = ({ isVertical = false }) => {
  return <SDiv $isVertical={isVertical} data-testid="divider" />;
};

export default Divider;
