import styled from 'styled-components';

export const Container = styled.div`
  max-width: 500px;
  text-align: center;
  margin: 0 auto;
`;

export const ButtonGrid = styled.div`
  max-width: 200px;
  margin: 0 auto;
`;
