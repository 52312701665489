import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

export const Badges = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.grey6};
  text-decoration: underline;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
`;
