import styled from 'styled-components';
import { motion } from 'framer-motion';

export const ModalBackground = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  background-color: #000000aa;
  cursor: pointer;
  z-index: 2;
`;

type InnerProps = {
  $large: boolean;
};

export const ModalContainer = styled(motion.div)<InnerProps>`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  padding: 16px;
  max-width: ${({ $large }) => ($large ? '700px' : '500px')};
  width: 100%;
  min-height: 200px;
`;

export const ModalInner = styled.div`
  background-color: ${({ theme }) => theme.colors.surface};
  display: flex;
  flex-direction: column;
  border-radius: 32px;
  max-height: 600px;
  height: 100%;
`;
