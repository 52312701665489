import { toast } from 'react-toastify';

export enum ToastType {
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error'
}

export const showToast = (message: string, type = ToastType.ERROR) => {
  toast[type](message, {
    position: 'top-center',
    closeOnClick: true,
    hideProgressBar: false,
    draggable: false,
    autoClose: 16000
  });
};

export const showErrorToast = (message: string) =>
  showToast(message, ToastType.ERROR);
export const showWarningToast = (message: string) =>
  showToast(message, ToastType.WARNING);
export const showInfoToast = (message: string) =>
  showToast(message, ToastType.INFO);
export const showSuccessToast = (message: string) =>
  showToast(message, ToastType.SUCCESS);
