import styled from 'styled-components';
import { breakpoints } from 'styles';

export const Main = styled.main`
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
  height: calc(100% - 96px);

  @media (max-width: ${breakpoints.s}px) {
    height: calc(100% - 72px);
  }
`;
