import { useEffect, useState, FC, useCallback, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { AnimatePresence, motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

import { signUpSelector, submitEmailAndPassword } from 'store/signupState';
import { marketSelector } from 'store/marketState';
import { inviteSelector } from 'store/inviteState';

import * as routes from 'router/routes';
import { useQuery } from 'hooks/useQuery';
import { useTranslations } from 'hooks/useTranslations';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { mailRegex, fleetServicesUrl, privacyPolicyUrl } from 'utils/constants';
import { showInfoToast, showErrorToast } from 'utils/toastUtils';

import passwordIcon from 'assets/vectors/password.svg';

import TermsUseModal from 'components/modals/TermsUseModal';
import PageHeader from 'components/UI/Page/PageHeader';
import Heading, { Type } from 'components/UI/Heading';
import Icon, { IconType } from 'components/UI/Icon';
import Button from 'components/UI/Buttons/Button';
import InputField from 'components/UI/InputField';
import Loader from 'components/UI/Loader';

import {
  Form,
  ButtonContainer,
  FormText,
  TextAction,
  MessageLink,
  SelectButtonGrid,
  Card,
  ExternalLink
} from './styled';

type FormValues = {
  email: string;
  password: string;
  confirmedPassword: string;
};

const CredentialSignup: FC = () => {
  const t = useTranslations();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { email, password, confirmedPassword, isLoading, error } =
    useAppSelector(signUpSelector);
  const { termsOfUseDocumentUrl } = useAppSelector(marketSelector);
  const { invite } = useAppSelector(inviteSelector);

  // Params
  const [defaultEmail, accountType] = useQuery('email', 'accountType');

  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
    resetField,
    getValues
  } = useForm<FormValues>({
    defaultValues: {
      email: defaultEmail ?? email,
      password,
      confirmedPassword
    }
  });

  // State
  const [termsModalOpen, setTermsModalOpen] = useState<boolean>(false);

  // Error handling
  useEffect(() => {
    if (error) {
      switch (error.code) {
        case 'email.unavailable':
          showInfoToast(t('errors.email_unavailable'));
          setError('email', {
            message: t('errors.email_unavailable'),
            type: 'validate'
          });
          break;
        case 'email.domain.blocked':
          showErrorToast(error.message);
          setError('email', {
            message: error.message
          });
          break;
        default:
          showErrorToast(error.message);
      }
    }
  }, [setError, t, error]);

  // Submit form
  const submitForm: SubmitHandler<FormValues> = useCallback(
    (values) => {
      dispatch(
        submitEmailAndPassword(
          values.email,
          values.password,
          values.confirmedPassword
        )
      );
    },
    [dispatch]
  );

  // Actions
  const openTermsModal = useCallback(() => setTermsModalOpen(true), []);
  const closeTermsModal = useCallback(() => setTermsModalOpen(false), []);
  const onLogin = useCallback(() => navigate(routes.LOGIN), [navigate]);
  const onTabClick = useCallback(
    (type: string) => () => {
      navigate(`${routes.SIGNUP}?accountType=${type}`);
    },
    [navigate]
  );

  // Check if organization
  const isBusiness = useMemo(() => accountType === 'business', [accountType]);

  // Account type tabs
  const accountTypeTabs = useMemo(() => {
    if (invite) {
      return null;
    }
    return (
      <div>
        <Heading type={Type.h4}>
          {t('signup.credentials_form.label_customer_type')}
        </Heading>
        <SelectButtonGrid>
          <Button
            color={isBusiness ? 'background' : 'primary'}
            textColor={isBusiness ? 'primary' : 'onPrimary'}
            onClick={onTabClick('private')}
            border={isBusiness}
            fullWidth
          >
            {t('signup.credentials_form.tab_private')}
          </Button>
          <Button
            color={isBusiness ? 'primary' : 'background'}
            textColor={isBusiness ? 'onPrimary' : 'primary'}
            onClick={onTabClick('business')}
            border={!isBusiness}
            fullWidth
          >
            {t('signup.credentials_form.tab_business')}
          </Button>
        </SelectButtonGrid>
      </div>
    );
  }, [t, onTabClick, isBusiness, invite]);

  // Show Login Link
  const showLoginLink = useMemo(() => {
    if (errors.email && errors.email.type === 'validate') {
      return <MessageLink to={routes.LOGIN}>{t('login.title')}</MessageLink>;
    }
    return null;
  }, [errors.email, t]);

  // Loading
  if (isLoading) {
    return <Loader center size="large" />;
  }

  return (
    <>
      <PageHeader
        title={t('signup.credentials_form.title')}
        subtitle={t('signup.credentials_form.subtitle')}
        image={passwordIcon}
        imageAlt="credentials"
      />
      {accountTypeTabs}
      <AnimatePresence mode="wait">
        <motion.div
          key={isBusiness ? 'business' : 'private'}
          initial={false}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: -16, opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          {isBusiness ? (
            <Card>
              <Heading type={Type.h2}>
                {t('signup.credentials_form.tab_business_title')}
              </Heading>
              <p>{t('signup.credentials_form.tab_business_text_1')}</p>
              <p>{t('signup.credentials_form.tab_business_text_2')}</p>
              <ExternalLink href={fleetServicesUrl} target="_blank">
                <Icon type={IconType.ExternalLink} />
                <span>{t('signup.credentials_form.tab_business_link')}</span>
              </ExternalLink>
            </Card>
          ) : (
            <Form onSubmit={handleSubmit(submitForm)}>
              <InputField
                name="email"
                description="signup.credentials_form.label_email"
                error={errors.email}
                helperAction={showLoginLink}
                register={register('email', {
                  required: {
                    value: true,
                    message: t('signup.credentials_form.email_required')
                  },
                  pattern: {
                    value: mailRegex,
                    message: t('signup.credentials_form.email_invalid')
                  }
                })}
                reset={resetField}
              />
              <InputField
                name="password"
                description="signup.credentials_form.label_password"
                type="password"
                error={errors.password}
                register={register('password', {
                  required: {
                    value: true,
                    message: t('signup.credentials_form.password_required')
                  },
                  minLength: {
                    value: 6,
                    message: 'input.password_too_short'
                  }
                })}
                reset={resetField}
              />
              <InputField
                name="confirmedPassword"
                description="signup.credentials_form.label_password_confirmation"
                type="password"
                error={errors.confirmedPassword}
                register={register('confirmedPassword', {
                  required: {
                    value: true,
                    message: t('signup.credentials_form.password_required')
                  },
                  validate: (value) =>
                    value === getValues('password') ||
                    t('signup.credentials_form.unconfirmed_password')
                })}
                reset={resetField}
              />
              <FormText>{t('signup.credentials_form.legal')}</FormText>
              <FormText>
                {t('signup.credentials_form.contract_info_1')}
                <TextAction onClick={openTermsModal}>
                  {t('signup.credentials_form.terms_of_use')}
                </TextAction>
                {t('signup.credentials_form.contract_info_2')}
                <a
                  href={privacyPolicyUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('signup.credentials_form.privacy_policy')}
                </a>
                .
              </FormText>
              <ButtonContainer>
                <Button submit fullWidth>
                  {t('signup.credentials_form.btn_register')}
                </Button>
                <Button
                  onClick={onLogin}
                  color="background"
                  textColor="primary"
                  border
                >
                  {t('signup.credentials_form.btn_login')}
                </Button>
              </ButtonContainer>
              <TermsUseModal
                isOpen={termsModalOpen}
                close={closeTermsModal}
                documentUrl={termsOfUseDocumentUrl}
              />
            </Form>
          )}
        </motion.div>
      </AnimatePresence>
    </>
  );
};

export default CredentialSignup;
