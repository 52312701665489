import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  p {
    font-size: 16px;
    margin: 0;
  }

  h5 {
    margin: 0;
  }

  > div:nth-child(odd) {
    > div {
      background-color: ${({ theme }) => theme.colors.grey5};
    }
  }
  > div:nth-child(even) {
    > div {
      background-color: ${({ theme }) => theme.colors.white};
    }
  }
`;

export const DataRow = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
`;

export const DataCell = styled.div`
  flex: 1;
  font-size: 16px;
  padding: 8px 12px;
`;
