import { FC, useEffect, useCallback } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';

import { authSelector, validateToken } from 'store/authState';
import { checkInvite, inviteSelector } from 'store/inviteState';
import { localizationSelector } from 'store/localizationState';
import { displayGlobalError } from 'store/globalErrorState';
import { loginRedirect } from 'services/authService';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useTranslations } from 'hooks/useTranslations';
import { useQuery } from 'hooks/useQuery';
import * as routes from 'router/routes';

import Heading from 'components/UI/Heading';
import Button from 'components/UI/Buttons/Button';
import PageImageWrapper from 'components/UI/Page/PageImageWrapper';
import SeoHelmet from 'components/fragments/SeoHelmet';

import { ForgotPasswordContainer, LoginContainer } from './styled';

const LoginPage: FC = () => {
  const t = useTranslations();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { invite, shouldComplete } = useAppSelector(inviteSelector);
  const {
    session: { authenticated }
  } = useAppSelector(authSelector);
  const { languageCode = 'en' } = useAppSelector(localizationSelector);

  // Params
  const [accessToken, refreshToken, error] = useQuery(
    'access_token',
    'refresh_token',
    'error'
  );

  // Check invite
  useEffect(() => {
    dispatch(checkInvite());
  }, [dispatch]);

  // Validate token
  useEffect(() => {
    if (accessToken && refreshToken) {
      dispatch(validateToken(accessToken, refreshToken));
    }
  }, [dispatch, accessToken, refreshToken]);

  // Handle DIAM error
  useEffect(() => {
    if (error) {
      dispatch(displayGlobalError('login.error'));
    }
  }, [dispatch, error]);

  // Actions
  const onLogin = useCallback(
    () => loginRedirect(languageCode),
    [languageCode]
  );
  const onSignUp = useCallback(() => navigate(routes.SIGNUP), [navigate]);
  const onForgotPassword = useCallback(
    () => navigate(routes.RESET_PASSWORD),
    [navigate]
  );

  // Register invites
  if (authenticated && invite && shouldComplete) {
    return <Navigate to={routes.INVITE_PRODUCTS_REG} />;
  }

  // Redirect to products
  if (authenticated) {
    return <Navigate to={routes.PRODUCTS} />;
  }

  return (
    <PageImageWrapper>
      <SeoHelmet title="login.meta_title" />
      <Heading>{t('login.title')}</Heading>
      <p>{t('login.description')}</p>
      <LoginContainer>
        <Button onClick={onLogin}>{t('login.btn_login')}</Button>
        <Button
          onClick={onSignUp}
          color="background"
          textColor="primary"
          border
        >
          {t('login.btn_signup')}
        </Button>
        <ForgotPasswordContainer>
          <Button
            onClick={onForgotPassword}
            color="transparent"
            textColor="primary"
          >
            {t('login.btn_forgot_password')}
          </Button>
        </ForgotPasswordContainer>
      </LoginContainer>
    </PageImageWrapper>
  );
};

export default LoginPage;
