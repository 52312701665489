import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryAuth } from 'utils/fetchUtils/fetchUtils';
import {
  CustomerAccountStatus,
  CustomerConsent,
  CustomerConsentQuery,
  CustomerConsentResponse,
  CustomerConsentUpdateResponse
} from 'models/customer';

export const customerApi = createApi({
  reducerPath: 'customerApi',
  baseQuery: baseQueryAuth,
  tagTypes: ['Customer', 'Consent'],
  endpoints: (builder) => ({
    getAccountStatusByEmail: builder.mutation<CustomerAccountStatus, string>({
      query: (email: string) =>
        `/customers/account-status/${encodeURIComponent(email)}`,
      transformResponse: (res: any) => res.data.attributes
    }),
    getCustomerConsents: builder.query<CustomerConsent[], CustomerConsentQuery>(
      {
        query: ({ customerId }) => `/customers/${customerId}/consents`,
        transformResponse: (res: CustomerConsentResponse) => res.data,
        providesTags: ['Consent']
      }
    ),
    updateCustomerConsent: builder.mutation<string, CustomerConsentQuery>({
      query: ({ customerId, consentTypeId, body }) => ({
        url: `/customers/${customerId}/consents/${consentTypeId}`,
        method: 'PUT',
        body: {
          data: {
            type: 'customer-consent',
            attributes: {
              status: body?.status
            }
          }
        }
      }),
      transformResponse: (res: CustomerConsentUpdateResponse) =>
        res.data.attributes.status,
      invalidatesTags: ['Consent']
    })
  })
});

export const {
  useGetAccountStatusByEmailMutation,
  useGetCustomerConsentsQuery,
  useUpdateCustomerConsentMutation
} = customerApi;
