import styled, { DefaultTheme } from 'styled-components';
import { boxShadows } from 'styles';

type InputProps = {
  disabled?: boolean;
  $error?: boolean;
  $focus?: boolean;
  $outlined?: boolean;
};

type CountryButtonProps = {
  $isActive: boolean;
};

function handleBackgroundColor(
  theme: DefaultTheme,
  disabled?: boolean,
  error?: boolean
) {
  if (disabled) {
    return theme.colors.background;
  }

  if (error) {
    return theme.colors.subtleError;
  }

  return theme.colors.surface;
}

function handleBorderColor(
  theme: DefaultTheme,
  focus?: boolean,
  error?: boolean,
  outlined?: boolean
) {
  if (error) {
    return theme.colors.error;
  }

  if (focus) {
    return theme.colors.grey7;
  }

  if (outlined) {
    return theme.colors.grey3;
  }

  return theme.colors.grey3;
}

// Handle label color
const handleLabelColor = (
  theme: DefaultTheme,
  error?: boolean,
  focus?: boolean
) => {
  if (error) {
    return theme.colors.error;
  }

  if (focus) {
    return theme.colors.primary;
  }

  return theme.colors.onSurface;
};

export const LabelFloat = styled.div`
  position: relative;
`;
export const LabelFloatGroup = styled.div<InputProps>`
  display: flex;
  background-color: ${({ theme, disabled, $error }) =>
    handleBackgroundColor(theme, disabled, $error)};
  border-radius: 8px;
  opacity: ${({ disabled }) => (disabled ? '0.7' : '1')};
  background-clip: padding-box;
  border: 1px solid;
  border-color: ${({ theme, $focus, $error, $outlined }) =>
    handleBorderColor(theme, $focus, $error, $outlined)};
`;

export const CountryGroup = styled.div`
  height: 54px;
  background-color: transparent;
  padding: 0 8px 0 14px;
`;

export const CountryButton = styled.button<CountryButtonProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 0;
  outline: none;
  border: none;
  background-color: transparent;

  > svg {
    height: 20px;
  }
`;

export const ArrowIcon = styled.img<CountryButtonProps>`
  transform: rotate(${({ $isActive }) => ($isActive ? '-180deg' : '0')});
  transition: transform 0.2s;
  width: 24px;
  height: 24px;
`;

const labelSmall = `
  font-size: 12px;
  margin-top: 8px;
`;

export const DialCodeLabel = styled.label`
  margin: 0;
  height: 54px;
  padding: 24px 4px 6px 0px;
  font-size: 18px;
  text-align: right;
  color: ${({ theme }) => theme.colors.primary};
  background-color: transparent;
  border: none;
`;

export const DialCodeInput = styled.input`
  display: none;
`;

export const LabelFloatInput = styled.input<InputProps>`
  flex: 1;
  height: 54px;
  outline: none;
  border: none;
  padding: 24px 14px 6px 0px;
  font-size: 18px;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -webkit-appearance: none;
  background-color: ${({ theme, disabled, $error }) =>
    handleBackgroundColor(theme, disabled, $error)};
  border-radius: 8px;
  caret-color: ${({ theme }) => theme.colors.primary};
  width: auto;

  &::placeholder {
    color: transparent;
  }

  + label {
    ${labelSmall}
  }
`;

export const LabelFloatLabel = styled.label<InputProps>`
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 78px;
  margin-top: 16px;
  font-size: 18px;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  color: ${({ theme, $error, $focus }) =>
    handleLabelColor(theme, $error, $focus)};
`;

export const LabelFloatMessage = styled.div<InputProps>`
  min-height: 24px;
  padding: 4px 16px;
  margin-bottom: 8px;
  display: flex;

  p {
    margin: 0;
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme, $error }) => {
      if ($error) {
        return theme.colors.error;
      }

      return theme.colors.onSurface;
    }};
  }
`;

type SelectOptionsProps = {
  $isActive: boolean;
};

type OptionProps = {
  selected: boolean;
  disabled?: boolean;
};

export const SelectOptions = styled.div<SelectOptionsProps>`
  position: absolute;
  display: block;
  max-height: 300px;
  top: 64px;
  left: 0;
  right: 0;
  border-radius: 8px;
  transition: all 0.5s;
  background: ${({ theme }) => theme.colors.surface};
  opacity: ${({ $isActive }) => ($isActive ? '1' : '0')};
  visibility: ${({ $isActive }) => ($isActive ? 'visible' : 'hidden')};
  pointer-events: ${({ $isActive }) => ($isActive ? 'all' : 'none')};
  box-shadow: ${boxShadows.normal};
  overflow: hidden;
  z-index: 2;
`;

export const OptionSearchGroup = styled.div``;

export const OptionSearchInput = styled.input`
  height: 54px;
  width: 100%;
  outline: none;
  border: none;
  padding: 24px 14px 6px 14px;
  font-size: 18px;
  transition: all 0.2s ease;
  -webkit-appearance: none;
  caret-color: ${({ theme }) => theme.colors.primary};

  &::placeholder {
    color: transparent;
  }

  &:focus + label,
  &:not(:placeholder-shown) + label {
    ${labelSmall}
  }

  &:-webkit-autofill + label {
    ${labelSmall}
  }
`;

export const OptionSearchLabel = styled.label<InputProps>`
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 14px;
  margin-top: 16px;
  font-size: 18px;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  color: ${({ theme, $error, $focus }) =>
    handleLabelColor(theme, $error, $focus)};
`;

export const OptionGroup = styled.div`
  width: 100%;
  max-height: 246px;
  overflow-y: scroll;
`;

export const Option = styled.div<OptionProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  cursor: ${({ disabled = false }) => (disabled ? 'inherit' : 'pointer')};
  transition: all 0.5s;

  :hover {
    background-color: ${({ theme, disabled = false }) =>
      !disabled ? theme.colors.grey5 : 'transparent'};
  }
`;

export const OptionContent = styled.div`
  display: flex;
  align-items: center;

  p {
    margin: 0 0 0 16px;
    white-space: nowrap;
    overflow: hidden;
    font-size: 18px;
    line-height: 60px;
  }

  > svg {
    height: 20px;
    margin-right: 16px;
  }
`;

export const OptionMark = styled.mark`
  background-color: ${({ theme }) => theme.colors.primary10};
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 600;
`;

export const DefaultIcon = styled.img`
  height: 24px;
`;
