import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { Customer, ICustomerUpdate } from 'models/customer';

import * as customerService from 'services/customerService';
import * as localStorage from 'utils/localStorageUtils';
import { displayApiError } from '../globalErrorState';
import type { RootState } from 'store';

export type CustomerState = {
  customer: Customer | null;
  isLoading: boolean;
  errors: string[];
  completedUpdate: boolean;
};

const defaultCustomerState: CustomerState = {
  customer: null,
  isLoading: false,
  errors: [],
  completedUpdate: false
};

export const customerSlice = createSlice({
  name: 'customer',
  initialState: defaultCustomerState,
  reducers: {
    getCustomer: (state) => {
      state.isLoading = true;
    },
    getCustomerSuccess: (state, action: PayloadAction<Customer>) => {
      localStorage.setItem('customer', action.payload);

      state.customer = action.payload;
      state.isLoading = false;
      state.errors = [];
    },
    getCustomerFailed: (state, action: PayloadAction<string>) => {
      state.customer = null;
      state.isLoading = false;
      state.errors = [action.payload];
    },
    patchCustomer: (state) => {
      state.isLoading = true;
    },
    patchCustomerSuccess: (state, action: PayloadAction<Customer>) => {
      state.customer = action.payload;

      localStorage.setItem('customer', action.payload);

      state.completedUpdate = true;
      state.isLoading = false;
      state.errors = [];
    },
    patchCustomerFailed: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.errors = [action.payload];
    },
    resetPatchProcess: (state) => {
      state.completedUpdate = false;
      state.isLoading = false;
    },
    addGroup: (state) => {
      state.isLoading = true;
    },
    addGroupSuccess: (state) => {
      state.isLoading = false;
    },
    addGroupFailure: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.errors = [action.payload];
    }
  }
});

// Export actions
export const {
  getCustomer,
  getCustomerSuccess,
  getCustomerFailed,
  patchCustomer,
  patchCustomerSuccess,
  patchCustomerFailed,
  resetPatchProcess,
  addGroup,
  addGroupSuccess,
  addGroupFailure
} = customerSlice.actions;

// Export reducer
export const customerReducer = customerSlice.reducer;

// Export selector
export const customerSelector = (state: RootState) => state.customer;

// Export thunks
export function fetchCustomer(accessToken: string, customerId: string) {
  return async (dispatch: Dispatch) => {
    dispatch(getCustomer());
    try {
      const customer = await customerService.getCustomer(
        accessToken,
        customerId
      );
      dispatch(getCustomerSuccess(customer.toJson()));
    } catch (e: any) {
      dispatch(displayApiError(e));

      dispatch(getCustomerFailed(e.message));
    }
  };
}

export function updateCustomer(
  accessToken: string,
  customerId: string,
  customerUpdate: ICustomerUpdate
) {
  return async (dispatch: Dispatch) => {
    dispatch(patchCustomer());
    try {
      const response = await customerService.updateCustomer(
        customerId,
        customerUpdate,
        accessToken
      );
      dispatch(patchCustomerSuccess(response.toJson()));
    } catch (e: any) {
      dispatch(displayApiError(e));
      dispatch(patchCustomerFailed(e.message));
    }
  };
}
