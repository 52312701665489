import styled from 'styled-components';
import { breakpoints } from 'styles';

type ContentProps = {
  $fullHeight: boolean;
};

export const Content = styled.div<ContentProps>`
  min-height: 100%;
  display: ${({ $fullHeight }) => ($fullHeight ? 'flex' : 'block')};
  padding-bottom: 165px;

  @media (max-width: ${breakpoints.s}px) {
    padding-bottom: 290px;
  }
`;

export const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 16px;
  max-width: 500px;
  margin: -165px auto 0;

  @media (max-width: ${breakpoints.s}px) {
    margin-top: -290px;
    padding: 64px 16px;
  }
`;

export const Logo = styled.img`
  margin-bottom: 8px;
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.subtle};
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  margin: 0;
`;

export const Links = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-top: 16px;

  @media (max-width: ${breakpoints.s}px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 24px;
  }
`;

export const Link = styled.a`
  color: ${({ theme }) => theme.colors.grey6};
  font-family: Roboto, Arial, sans-serif;
  text-decoration: underline;
  font-style: normal;
  font-weight: 350;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.4px;
  -webkit-font-smoothing: antialiased;
  text-align: center;
`;
