import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { DIAMGroup, ICustomerDeleteAccountAttributes } from 'models/customer';
import { ApiError } from 'models/errors/ApiError';
import * as customerService from 'services/customerService/customerService';
import { displayApiError } from '../globalErrorState/globalErrorState';
import type { RootState } from 'store';

type RequestAttributes = {
  isCompleted: boolean;
  isLoading: boolean;
  error: ApiError | null;
};

export type ValidationAttributes = {
  email: string | null;
  groups: DIAMGroup[];
  isLoading: boolean;
  error: ApiError | null;
};

export type ConfirmAttributes = {
  isCompleted: boolean;
  isLoading: boolean;
  error: ApiError | null;
};

export type DeleteAccountState = {
  request: RequestAttributes;
  validation: ValidationAttributes;
  confirm: ConfirmAttributes;
};

export const defaultDeleteAccountState: DeleteAccountState = {
  request: {
    isCompleted: false,
    isLoading: false,
    error: null
  },
  validation: {
    email: null,
    groups: [],
    isLoading: false,
    error: null
  },
  confirm: {
    isCompleted: false,
    isLoading: false,
    error: null
  }
};

const deleteAccountSlice = createSlice({
  name: 'deleteAccount',
  initialState: defaultDeleteAccountState,
  reducers: {
    deleteRequestStart: (state) => {
      state.request.isLoading = true;
      state.request.isCompleted = false;
      state.request.error = null;
    },
    deleteRequestSuccess: (state) => {
      state.request.isLoading = false;
      state.request.isCompleted = true;
    },
    deleteRequestFailure: (state, action: PayloadAction<ApiError>) => {
      state.request.isLoading = false;
      state.request.isCompleted = false;
      state.request.error = action.payload;
    },
    deleteValidationStart: (state) => {
      state.validation.isLoading = true;
      state.validation.groups = [];
      state.validation.error = null;
    },
    deleteValidationSuccess: (
      state,
      action: PayloadAction<ICustomerDeleteAccountAttributes>
    ) => {
      state.validation.isLoading = false;
      state.validation.groups = action.payload.groups;
      state.validation.email = action.payload.email;
      state.validation.error = null;
    },
    deleteValidationFailure: (state, action: PayloadAction<ApiError>) => {
      state.validation.isLoading = false;
      state.validation.error = action.payload;
    },
    deleteConfirmStart: (state) => {
      state.confirm.isLoading = true;
      state.confirm.isCompleted = false;
      state.confirm.error = null;
    },
    deleteConfirmSuccess: (state) => {
      state.confirm.isLoading = false;
      state.confirm.isCompleted = true;
      state.confirm.error = null;
    },
    deleteConfirmFailure: (state, action: PayloadAction<ApiError>) => {
      state.confirm.isLoading = false;
      state.confirm.error = action.payload;
    },
    resetDeleteAccountState: () => defaultDeleteAccountState
  }
});

// Actions
export const {
  deleteRequestStart,
  deleteRequestSuccess,
  deleteRequestFailure,
  deleteValidationStart,
  deleteValidationSuccess,
  deleteValidationFailure,
  deleteConfirmStart,
  deleteConfirmSuccess,
  deleteConfirmFailure,
  resetDeleteAccountState
} = deleteAccountSlice.actions;

// Reducer
export const deleteAccountReducer = deleteAccountSlice.reducer;

// Selector
export const deleteAccountSelector = (state: RootState) => state.deleteAccount;

// Thunks
export function deleteAccountRequest(customerId: string, token: string) {
  return async (dispatch: Dispatch) => {
    dispatch(deleteRequestStart());
    try {
      await customerService.requestDeleteAccount(customerId, token);
      dispatch(deleteRequestSuccess());
    } catch (e: any) {
      dispatch(displayApiError(e));
      dispatch(deleteRequestFailure(e));
    }
  };
}

export function deleteAccountValidation(customerId: string, code: string) {
  return async (dispatch: Dispatch) => {
    dispatch(deleteValidationStart());
    try {
      const response = await customerService.validateDeleteAccount(
        customerId,
        code
      );
      dispatch(deleteValidationSuccess(response.data.attributes));
    } catch (e: any) {
      dispatch(displayApiError(e));
      dispatch(deleteValidationFailure(e));
    }
  };
}

export function deleteAccountConfirm(
  customerId: string,
  code: string,
  password: string
) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(deleteConfirmStart());
      await customerService.confirmDeleteAccount(customerId, code, password);
      dispatch(deleteConfirmSuccess());
    } catch (e: any) {
      dispatch(displayApiError(e));
      dispatch(deleteConfirmFailure(e));
    }
  };
}
