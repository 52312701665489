import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';

export const StyledToast = styled(ToastContainer)`
  --toastify-toast-width: 500px;

  .Toastify__toast {
    font-weight: 900;
    padding: 16px;
    color: ${({ theme }) => theme.colors.onPrimary};
  }

  .Toastify__progress-bar {
    background-color: rgba(255, 255, 255, 0.7);
  }

  .Toastify__toast--error {
    background-color: ${({ theme }) => theme.colors.error};
  }

  .Toastify__toast--info {
    background-color: ${({ theme }) => theme.colors.info};
  }

  .Toastify__toast--warning {
    background-color: ${({ theme }) => theme.colors.warning};
  }

  .Toastify__toast--success {
    background-color: ${({ theme }) => theme.colors.success};
  }

  .Toastify__toast-icon {
    display: none;
  }

  .Toastify__close-button {
    color: ${({ theme }) => theme.colors.onPrimary};
  }
`;
