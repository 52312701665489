import { configureStore } from '@reduxjs/toolkit';

import { isDev, isTest } from 'config';
import { rtkQueryError } from 'utils/fetchUtils/fetchUtils';
import { authReducer } from './authState/authState';
import { changeEmailReducer } from './changeEmailState/changeEmailState';
import { customerApi } from './customerService';
import { customerReducer } from './customerState/customerState';
import { deleteAccountReducer } from './deleteAccountState/deleteAccountState';
import { globalErrorReducer } from './globalErrorState/globalErrorState';
import { inviteReducer } from './inviteState/inviteState';
import { localizationReducer } from './localizationState/localizationState';
import { marketReducer } from './marketState/marketState';
import { passwordResetReducer } from './passwordResetState/passwordResetState';
import { productApi } from './productService';
import { configApi } from './configService';
import { signUpReducer } from './signupState/signupState';
import { scanReducer } from './scanState';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    changeEmail: changeEmailReducer,
    customer: customerReducer,
    deleteAccount: deleteAccountReducer,
    globalError: globalErrorReducer,
    invite: inviteReducer,
    localization: localizationReducer,
    market: marketReducer,
    passwordReset: passwordResetReducer,
    [productApi.reducerPath]: productApi.reducer,
    [customerApi.reducerPath]: customerApi.reducer,
    [configApi.reducerPath]: configApi.reducer,
    scan: scanReducer,
    signUp: signUpReducer
  },
  middleware: (getDefaultMiddleware) => {
    // Middlewares
    const middlewares = [
      productApi.middleware,
      customerApi.middleware,
      configApi.middleware
    ];

    if (!isTest) {
      middlewares.push(rtkQueryError);
    }

    return getDefaultMiddleware().concat(middlewares);
  },
  devTools: isDev
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
