import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled.div`
  overflow: hidden;
  margin-bottom: 16px;
`;

type Props = {
  $isOpen: boolean;
};

export const SectionButton = styled.button<Props>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.colors.grey6};
  background-color: ${({ $isOpen, theme }) =>
    $isOpen ? theme.colors.grey1 : 'transparent'};
  font-size: 20px;
  line-height: 28px;
  font-weight: bold;
  border: none;
  border-radius: 12px;
  padding: 8px 12px;
  margin: 0;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey1};
  }
`;

export const ContentContainer = styled(motion.div)`
  height: 100%;
`;

export const Content = styled.div`
  padding: 24px 12px 0 12px;
`;
