import styled, { keyframes } from 'styled-components';

type Props = {
  $size: 'small' | 'medium' | 'large';
  $center: boolean;
  $padding: boolean;
};

// Animiation
const loadingAnimation = keyframes`
  to {
    transform: rotate(1turn);
  }
`;

// Sizes
const sizes = {
  small: '24px',
  medium: '32px',
  large: '48px'
};

// Width
function getWidth({ $size }: Props) {
  return sizes[$size];
}

// Margins
function getMargins({ $center, $padding }: Props) {
  if ($center && $padding) {
    return '32px auto';
  }
  if ($center) {
    return '0 auto';
  }
  if ($padding) {
    return '32px 0';
  }
  return 'initial';
}

export const Root = styled.div<Props>`
  width: ${getWidth};
  padding: calc(${getWidth} / 7);
  margin: ${getMargins};
  aspect-ratio: 1;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.primary};
  mask:
    conic-gradient(#0000 10%, #000),
    linear-gradient(#000 0 0) content-box;
  mask-composite: subtract;
  animation: ${loadingAnimation} 1s infinite linear;
`;
