import { FC, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import { authSelector } from 'store/authState';
import { localizationSelector } from 'store/localizationState';
import { useGetRegisteredProductDetailsQuery } from 'store/productService';

import { useTranslations } from 'hooks/useTranslations';
import { useAppSelector } from 'hooks/redux';
import { mavenoidMainProductId, moreMenuItems } from 'utils/constants';
import * as routes from 'router/routes';
import * as config from 'config';

import SeoHelmet from 'components/fragments/SeoHelmet';
import Chatbot from 'components/fragments/Chatbot';
import ExpandableSection from 'components/UI/ExpandableSection';
import Loader from 'components/UI/Loader';
import Heading from 'components/UI/Heading';
import { IconType } from 'components/UI/Icon';
import PageState from 'components/UI/Page/PageState';
import ProductDealer from 'components/UI/Product/ProductDealer';
import { ProductDocument } from 'components/UI/Product/ProductDocument';
import ProductImage from 'components/UI/Product/ProductImage';
import ProductInformation from 'components/UI/Product/ProductInformation';
import ProductServiceBook from 'components/UI/Product/ProductServiceBook';
import ProductServices from 'components/UI/Product/ProductServices';
import PageWrapper from 'components/UI/Page/PageWrapper';
import BackButton from 'components/UI/Buttons/BackButton';
import Button from 'components/UI/Buttons/Button';
import MoreModal from 'components/UI/MoreModal';

import {
  Container,
  Description,
  DetailsContainer,
  PageContainer,
  ProductDescription,
  ProductHeader,
  ProductOffer,
  ResponsiveContainer,
  Type
} from './styled';

const ProductTabs: FC = () => {
  const t = useTranslations();
  const { id } = useParams();
  const { session } = useAppSelector(authSelector);
  const { languageCode } = useAppSelector(localizationSelector);

  // State
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  // Hooks
  const { data } = useGetRegisteredProductDetailsQuery(
    {
      customerId: session?.customerId ?? undefined,
      langCode: languageCode,
      iprId: id
    },
    { skip: !session?.authenticated }
  );

  // Handle modal close
  const onModalClose = useCallback(() => setModalIsOpen(false), []);

  // Redirect to offer
  const onOfferClick = useCallback(() => {
    // TODO: Remove conditional logic for order key once Keylight is deployed to Live
    const orderKeyQuery = config.isLive
      ? ''
      : `&orderKey=${config.keylightOrderKey}`;
    window.open(
      `${config.keylightUrl}/checkout?iprId=${data?.product.iprId}${orderKeyQuery}`
    );
  }, [data]);

  if (!data?.product) {
    return (
      <PageWrapper>
        <Loader center size="large" />
      </PageWrapper>
    );
  }

  const { product, dealer, documents, serviceContracts, hasOffers, warranty } =
    data;
  const { iprId, description, productCategory, imageUrl, modelName } = product;

  return (
    <ResponsiveContainer>
      <SeoHelmet title="productdetails.meta_title" />
      <ProductImage src={imageUrl} />
      <PageContainer>
        <Container>
          <ProductHeader>
            <BackButton backUrl={routes.PRODUCTS}>
              {t('productinfo.btn_back')}
            </BackButton>
            <Type>{productCategory?.toLowerCase() ?? '—'}</Type>
            <Heading>{modelName ?? '—'}</Heading>
          </ProductHeader>
          <ProductDescription>
            {description && <Description>{description}</Description>}
          </ProductDescription>
          {hasOffers && (
            <ProductOffer>
              <p>{t('productdetails.offers_text')}</p>
              <Button onClick={onOfferClick} color="success">
                {t('productdetails.btn_offers')}
              </Button>
            </ProductOffer>
          )}
          <DetailsContainer>
            <ExpandableSection
              title={t('productdetails.section_product_information')}
              openByDefault
            >
              <ProductInformation product={product} warranty={warranty} />
            </ExpandableSection>
            <ExpandableSection
              title={t('productdetails.section_documents')}
              openByDefault
            >
              {!documents.length ? (
                <PageState
                  iconType={IconType.Document}
                  title={t('productdetails.documents_empty')}
                  small
                />
              ) : (
                documents.map((document) => (
                  <ProductDocument
                    key={document.id}
                    document={document}
                    languageCode={languageCode}
                  />
                ))
              )}
            </ExpandableSection>
            <ExpandableSection title={t('productdetails.section_services')}>
              <ProductServices
                serviceContracts={serviceContracts}
                languageCode={languageCode}
              />
            </ExpandableSection>
            {dealer && (
              <ExpandableSection
                title={t('productdetails.section_dealer_information')}
              >
                <ProductDealer dealer={dealer} />
              </ExpandableSection>
            )}
            <ExpandableSection title={t('productdetails.section_dsb')}>
              <ProductServiceBook data={data.serviceRecords} />
            </ExpandableSection>
          </DetailsContainer>
        </Container>
      </PageContainer>
      <MoreModal
        isOpen={modalIsOpen}
        close={onModalClose}
        title={t('productdetails.more_modal_title')}
        menu={moreMenuItems(iprId)}
      />
      <Chatbot productId={mavenoidMainProductId} langCode={languageCode} />
    </ResponsiveContainer>
  );
};

export default ProductTabs;
