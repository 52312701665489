import styled from 'styled-components';
import { ThemeColor } from 'styles';

type Props = {
  color: ThemeColor;
};

export const Marker = styled.div<Props>`
  background-color: ${({ theme, color }) => theme.colors[color]};
  width: 12px;
  height: 12px;
  border-radius: 6px;
`;
