import { FC, useMemo } from 'react';

import FAQIcon from 'assets/vectors/iconsFAQ.svg';
import QuickGuideIcon from 'assets/vectors/iconsNotificationInfoOutlined.svg';
import ManualsIcon from 'assets/vectors/iconsProductTechSpec.svg';
import Badge from 'components/UI/Badge';
import { IconLink } from 'components/UI/IconLink';
import { ProductDocumentData } from 'models/product';

import { Badges, Container, Text } from './styled';

const validTypes = {
  WM: 'Workshop manual',
  DOC: 'Declaration of Conformity',
  OM: 'Operators manual',
  AI: 'Assembly Instruction',
  IPL: 'Illustrated Parts List',
  QG: 'Quick Guide',
  RD: 'Regulatory Documents',
  TH: 'Technical manual',
  SB: 'Service bulletin',
  SMB: 'Service manager bulletin',
  SP: 'Service Plan',
  TD: 'Transport Documents',
  FAQ: 'Frequently asked questions',
  SDS: 'Security Data Sheet',
  CTC: 'Commercial Terms & Conditions',
  CMP: 'Commercial Maintenance Plan',
  OMS: 'Operators Manual Slim',
  PEC: 'Product Error Codes',
  RN: 'Release Note'
};

type ValidDocumentKeys = keyof typeof validTypes;

type Props = {
  document: ProductDocumentData;
  languageCode?: string;
};

export const ProductDocument: FC<Props> = ({ document, languageCode }) => {
  // Document type
  const documentType = useMemo(
    () => validTypes[document?.publicationType as ValidDocumentKeys],
    [document]
  );

  // Render icon depending on type
  const iconSrc = useMemo(() => {
    switch (documentType) {
      case validTypes.QG:
        return QuickGuideIcon;
      case validTypes.FAQ:
        return FAQIcon;
      default:
        return ManualsIcon;
    }
  }, [documentType]);

  const language = useMemo(() => {
    const found = document.languages.find(
      (lang) => lang === languageCode?.toUpperCase()
    );

    return found ?? document.languages?.[0];
  }, [document.languages, languageCode]);

  return (
    <IconLink link={document.link} iconSrc={iconSrc}>
      <Container>
        <Text>
          {document.publicationTitle.en ?? documentType ?? 'Document'}
        </Text>
        <Badges>
          <Badge>{language}</Badge>
          {document?.format && <Badge>{document.format.toUpperCase()}</Badge>}
        </Badges>
      </Container>
    </IconLink>
  );
};
