import styled, { css, DefaultTheme } from 'styled-components';

type Props = {
  theme: DefaultTheme;
  $isVertical: boolean;
};

const verticalProps = ({ theme, $isVertical }: Props) =>
  $isVertical &&
  css`
    margin: 0 0.6rem;
    border-left: 1px solid ${theme.colors.grey3};
    height: 100%;
    width: 0;
  `;

export const SDiv = styled.div<Props>`
  width: 100%;
  height: 0;
  border: none;
  border-top: 1px solid ${({ theme }) => theme.colors.grey3};
  border-left: none;
  margin: 16px 0;
  ${verticalProps}
`;
