import styled, { DefaultTheme } from 'styled-components';

type InputProps = {
  disabled?: boolean;
  $error?: boolean;
  $focus?: boolean;
  $outlined?: boolean;
};

function handleBackgroundColor(
  theme: DefaultTheme,
  disabled?: boolean,
  error?: boolean
) {
  if (disabled) {
    return theme.colors.background;
  }

  if (error) {
    return theme.colors.subtleError;
  }

  return theme.colors.surface;
}

function handleBorderColor(
  theme: DefaultTheme,
  focus?: boolean,
  error?: boolean,
  outlined?: boolean
) {
  if (error) {
    return theme.colors.error;
  }

  if (focus) {
    return theme.colors.grey7;
  }

  if (outlined) {
    return theme.colors.grey3;
  }

  return theme.colors.grey3;
}

export const LabelFloat = styled.div`
  position: relative;
`;

export const LabelFloatGroup = styled.div<InputProps>`
  display: flex;
  background-color: ${({ theme, disabled, $error }) =>
    handleBackgroundColor(theme, disabled, $error)};
  border-radius: 8px;
  opacity: ${({ disabled }) => (disabled ? '0.7' : '1')};
  background-clip: padding-box;
  border: 1px solid;
  border-color: ${({ theme, $focus, $error, $outlined }) =>
    handleBorderColor(theme, $focus, $error, $outlined)};
`;

const labelSmall = `
  font-size: 12px;
  margin-top: 8px;
`;

export const LabelFloatInput = styled.input<InputProps>`
  position: relative;
  width: 100%;
  height: 54px;
  outline: none;
  border: none;
  padding: 24px 14px 6px;
  min-width: 180px;
  font-size: 18px;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -webkit-appearance: none;
  background-color: ${({ theme, disabled, $error }) =>
    handleBackgroundColor(theme, disabled, $error)};
  border-radius: 8px;
  caret-color: ${({ theme }) => theme.colors.primary};

  &::placeholder {
    color: transparent;
  }

  &:focus + label,
  &:not(:placeholder-shown) + label {
    ${labelSmall}
  }

  &:-webkit-autofill + label {
    ${labelSmall}
  }

  &::-webkit-calendar-picker-indicator {
    display: none;
  }
`;

export const LabelFloatLabel = styled.label<InputProps>`
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 16px;
  margin-top: 16px;
  font-size: 18px;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  color: ${({ theme, $error, $focus }) => {
    if ($error) {
      return theme.colors.error;
    }

    if ($focus) {
      return theme.colors.primary;
    }

    return theme.colors.onSurface;
  }};
`;

export const LabelFloatButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  padding: 0 16px;
  margin-left: 1px;
  background: transparent;

  img {
    width: 24px;
    height: 24px;
  }
`;

export const LabelFloatMessage = styled.div<InputProps>`
  min-height: 24px;
  padding: 4px 16px;
  margin-bottom: 8px;
  display: flex;

  p {
    margin: 0;
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme, $error }) => {
      if ($error) {
        return theme.colors.error;
      }

      return theme.colors.onSurface;
    }};
  }
`;
