import * as config from 'config';
import { ContractDocumentUrlResponse } from 'models/contractDocument';
import { contractTypes } from 'utils/constants';
import { apiFetch, defaultHeaders } from 'utils/fetchUtils';

/**
 * Get MyPages contracts' (like the Terms of Use) URLs in a given language
 */
export async function getContractDocumentUrl(
  contractType: contractTypes,
  languageCode: string,
  countryCode?: string
): Promise<string> {
  let url = `${config.backendApi}/customers/contracts/${contractType}?langCode=${languageCode}`;

  if (countryCode) {
    url += `&countryCode=${countryCode}`;
  }

  const json = await apiFetch<ContractDocumentUrlResponse>(url, {
    headers: {
      ...defaultHeaders
    }
  });

  return json.data.attributes.url;
}
