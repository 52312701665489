import { FC } from 'react';
import { ImageContainer } from './styled';

interface ProductImageProps {
  src?: string | null;
}

const ProductImage: FC<ProductImageProps> = ({ src }) => {
  if (!src) {
    return null;
  }
  return (
    <ImageContainer>
      <img src={src} alt="product" />
    </ImageContainer>
  );
};

export default ProductImage;
