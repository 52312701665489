import { FC, useCallback, useEffect, useMemo } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';

import { useTranslations } from 'hooks/useTranslations';
import { mailRegex } from 'utils/constants';

import InputField from 'components/UI/InputField';
import Button from 'components/UI/Buttons/Button';
import Loader from 'components/UI/Loader';
import Modal from 'components/UI/Modal';

import { ModalDescription, ModalForm, ModalTitle } from 'styles';
import { ButtonGroup, LoaderWrapper } from './styled';

export type ChangeEmailValues = {
  email: string;
  password: string;
};

type Props = {
  currentEmail: string;
  isLoading: boolean;
  isCompleted: boolean;
  isOpen: boolean;
  close: () => void;
  submit: (values: ChangeEmailValues) => void;
};

const ChangeEmailModal: FC<Props> = ({
  currentEmail,
  isLoading,
  isCompleted,
  isOpen,
  close,
  submit
}) => {
  const t = useTranslations();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors
  } = useForm();

  // Close if completed
  useEffect(() => {
    if (isCompleted) {
      close();
    }
  }, [close, isCompleted]);

  // Submit
  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    (values) =>
      submit({
        email: values.email,
        password: values.password
      }),
    [submit]
  );

  // Reset inputfield
  const reset = useCallback(
    (name: string) => {
      setValue(name, '');
      clearErrors(name);
    },
    [setValue, clearErrors]
  );

  // Form
  const form = useMemo(() => {
    if (isLoading) {
      return (
        <LoaderWrapper>
          <Loader center />
        </LoaderWrapper>
      );
    }
    return (
      <>
        <InputField
          name="email"
          description="input.email_label"
          error={errors.email}
          register={register('email', {
            required: {
              value: true,
              message: 'input.email_required'
            },
            pattern: {
              value: mailRegex,
              message: 'input.email_invalid'
            },
            validate: (value) =>
              value !== currentEmail ||
              t('change_email.request.email_same', {
                email: value
              })
          })}
          reset={reset}
          outlined
        />
        <InputField
          name="password"
          description="input.password_label"
          type="password"
          error={errors.password}
          register={register('password', {
            required: {
              value: true,
              message: 'input.password_required'
            }
          })}
          reset={reset}
          outlined
        />
      </>
    );
  }, [register, reset, t, errors, currentEmail, isLoading]);

  return (
    <Modal isOpen={isOpen}>
      <ModalTitle>{t('change_email.request.title')}</ModalTitle>
      <ModalDescription>
        {t('change_email.request.description')}
      </ModalDescription>
      <ModalForm onSubmit={handleSubmit(onSubmit)}>
        {form}
        <ButtonGroup>
          <Button color="transparent" textColor="primary" onClick={close}>
            {t('change_email.request.cancel')}
          </Button>
          <Button submit disabled={isLoading}>
            {t('change_email.request.confirm')}
          </Button>
        </ButtonGroup>
      </ModalForm>
    </Modal>
  );
};

export default ChangeEmailModal;
