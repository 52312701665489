import { FC } from 'react';

import { Product } from 'models/product';
import ProductCard from 'components/UI/Product/ProductCard';

type Props = {
  product: Product;
};

const ProductInformationCard: FC<Props> = ({ product }) => {
  return <ProductCard product={product} husqvarnaId={product.husqvarnaId} />;
};

export default ProductInformationCard;
