import { isObject } from 'utils/objectUtils';
import { JsonApiData } from './jsonApi';
import { DealerInfo, Product } from './product';

export type InviteRegistrationQuery = {
  customerId: string;
  invite: Invite;
  soldDate?: number | null;
};

export type Invite = {
  id?: string;
  products: Product[];
  dealerInfo: DealerInfo | null;
  soldDate: number | null;
  customerEmail: string | null;
};

export class InviteDto implements Invite {
  readonly id?: string;
  readonly products: Product[];
  readonly dealerInfo: DealerInfo | null;
  readonly soldDate: number | null;
  readonly customerEmail: string | null;

  constructor(obj: Partial<InviteDto>) {
    const invite = isObject(obj) ? obj : {};
    this.id = invite.id;
    this.products = invite.products ?? [];
    this.dealerInfo = invite.dealerInfo ?? null;
    this.soldDate = invite.soldDate ?? null;
    this.customerEmail = invite.customerEmail ?? null;
  }

  public toJson(): Invite {
    return {
      id: this.id,
      products: this.products,
      dealerInfo: this.dealerInfo,
      soldDate: this.soldDate,
      customerEmail: this.customerEmail
    };
  }

  public static fromJson(obj: Partial<Invite>): Invite {
    return new InviteDto(obj).toJson();
  }

  public static fromJsonApiData({ id, attributes }: JsonApiData): Invite {
    return InviteDto.fromJson({ id, ...attributes });
  }
}
