import { FC, ReactNode, useMemo } from 'react';

import Heading, { Type } from 'components/UI/Heading';
import Icon, { IconType } from 'components/UI/Icon';

import { Container, IconContainer, Text } from './styled';

type Props = {
  children?: ReactNode;
  title?: string;
  description?: string;
  iconType?: IconType;
  small?: boolean;
};

const PageState: FC<Props> = ({
  iconType,
  title,
  description,
  small = false,
  children
}) => {
  // Render icon
  const icon = useMemo(() => {
    if (iconType) {
      return (
        <IconContainer>
          <Icon type={iconType} size={small ? 48 : 64} />
        </IconContainer>
      );
    }
    return null;
  }, [iconType, small]);

  return (
    <Container>
      {icon}
      {title && <Heading type={small ? Type.h4 : Type.h2}>{title}</Heading>}
      {description && <Text>{description}</Text>}
      {children}
    </Container>
  );
};

export default PageState;
