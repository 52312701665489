import styled from 'styled-components';

export const StyledBackButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border: none;
  color: ${({ theme }) => theme.colors.grey6};
  background-color: transparent;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 12px;
  cursor: pointer;

  transform: translateX(-6px);

  > img {
    width: 24px;
    height: 24px;
    margin-right: 4px;
  }
`;
