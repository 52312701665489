import styled, { DefaultTheme } from 'styled-components';
import { breakpoints } from 'styles';

export const Table = styled.table`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.surface};
  border-radius: 16px;
  border-spacing: 0;

  @media (max-width: ${breakpoints.s}px) {
    display: none;
  }
`;

type TableCellProps = {
  last: boolean;
  theme: DefaultTheme;
};

function handleBorder({ theme, last }: TableCellProps) {
  return last ? 'none' : `1px solid ${theme.colors.background}`;
}

export const TableHeadCell = styled.td`
  border-bottom: 1px solid ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.grey6};
  word-break: break-word;
  padding: 12px 16px;
  font-size: 14px;
`;

export const TableBodyCell = styled.td<TableCellProps>`
  border-bottom: ${handleBorder};
  word-break: break-word;
  padding: 16px;
  font-size: 16px;
`;

export const SmallTable = styled.div`
  background-color: ${({ theme }) => theme.colors.surface};
  border-radius: 16px;

  @media (min-width: ${breakpoints.s + 1}px) {
    display: none;
  }
`;

export const SmallTableRow = styled.div<TableCellProps>`
  border-bottom: ${handleBorder};
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  padding: 16px;
`;
