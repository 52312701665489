import { FC } from 'react';

import { DealerData } from 'models/product';

import { Container, AddressLine } from './styled';

type Props = {
  dealer: DealerData;
};

const ProductDealer: FC<Props> = ({ dealer }) => {
  const { name, address } = dealer;

  return (
    <Container>
      {name && <p>{name}</p>}
      {address.name && <AddressLine>{address.name}</AddressLine>}
      {address.street && (
        <AddressLine>{address.street.toLocaleLowerCase()}</AddressLine>
      )}
      {address.zipCity && (
        <AddressLine>{address.zipCity.toLocaleLowerCase()}</AddressLine>
      )}
    </Container>
  );
};

export default ProductDealer;
