import { FC, ReactNode, useMemo } from 'react';
import { Container, DataCell, DataRow } from './styled';

export type DataListItem = {
  name: string | null;
  value?: ReactNode | null;
};

type Props = {
  data: DataListItem[];
};

const DataList: FC<Props> = ({ data }) => {
  const list = useMemo(() => {
    if (!data.length) {
      return '';
    }

    return data.map((listItem) => (
      <DataRow key={listItem.name}>
        <DataCell>{listItem.name}</DataCell>
        <DataCell>{listItem.value || '–'}</DataCell>
      </DataRow>
    ));
  }, [data]);

  return <Container>{list}</Container>;
};

export default DataList;
