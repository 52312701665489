import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FieldValues, SubmitHandler } from 'react-hook-form';

import * as routes from 'router/routes';
import { useTranslations } from 'hooks/useTranslations';
import { showSuccessToast } from 'utils/toastUtils';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useQuery } from 'hooks/useQuery';

import {
  deleteAccountSelector,
  deleteAccountValidation,
  deleteAccountConfirm
} from 'store/deleteAccountState';
import { logout, authSelector } from 'store/authState';

import Loader from 'components/UI/Loader';
import PageImageWrapper from 'components/UI/Page/PageImageWrapper';
import ConfirmDeleteAccountModal from 'components/modals/ConfirmDeleteAccountModal';

const ConfirmDeleteAccountPage: FC = () => {
  const t = useTranslations();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Redux
  const { session } = useAppSelector(authSelector);
  const { validation, confirm } = useAppSelector(deleteAccountSelector);

  // State
  const [modalOpen, setModalOpen] = useState<boolean>(true);

  // Hooks
  const [customerId, code] = useQuery('customerId', 'code');

  // Submit
  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    (values) => {
      if (customerId) {
        dispatch(
          deleteAccountConfirm(customerId, values.code, values.password)
        );
      }
    },
    [customerId, dispatch]
  );

  // Cancel
  const cancelModal = useCallback(() => {
    setModalOpen(false);
    navigate(routes.LOGIN);
  }, [navigate]);

  // Store query in state
  useEffect(() => {
    if (customerId && code) {
      dispatch(deleteAccountValidation(customerId, code));
    }
  }, [dispatch, customerId, code]);

  // Show success toast
  useEffect(() => {
    if (confirm.isCompleted) {
      showSuccessToast(t('toast.account_delete_success'));

      if (session.authenticated) {
        dispatch(logout());
      }

      navigate(routes.LOGIN);
    }
  }, [dispatch, t, navigate, confirm, session]);

  // Cancel modal on validation error
  useEffect(() => {
    if (validation.error) {
      cancelModal();
    }
  }, [validation, cancelModal]);

  return (
    <PageImageWrapper>
      <Loader center size="large" />
      <ConfirmDeleteAccountModal
        isOpen={modalOpen}
        isLoading={confirm.isLoading || validation.isLoading}
        defaultCode={code}
        email={validation.email}
        groups={validation.groups}
        cancel={cancelModal}
        submit={onSubmit}
      />
    </PageImageWrapper>
  );
};

export default ConfirmDeleteAccountPage;
