import { CountryOption, LanguageOption } from 'models/basicData';
import {
  Address,
  Customer,
  CustomerConsentTranslationText,
  CustomerType,
  CustomerUpdateForm,
  EContact,
  ICustomerUpdate
} from 'models/customer';
import { Invite } from 'models/invite';
import { PhoneNumber } from 'models/phoneNumber';
import { allKeysAreEmptyStrings } from 'utils/objectUtils';

export function buildAddress(address?: Address): string {
  let addressLine = '—';
  if (!address?.street) {
    return addressLine;
  }

  addressLine = address.street;

  if (address.zipCode) {
    addressLine += `, ${address.zipCode}`;
  }
  if (address.city) {
    addressLine += `, ${address.city}`;
  }
  return addressLine;
}

export function getCustomerEmail(eContact?: EContact): string {
  if (eContact?.emailContacts.length) {
    return eContact.emailContacts[0].emailAddress ?? '';
  }
  return '';
}

export function getCustomerPhone(eContact?: EContact): string {
  if (eContact?.cellPhoneContacts.length) {
    return eContact.cellPhoneContacts[0].cellPhoneNumber ?? '';
  }
  return '';
}

export function getCustomerPhoneNumber(cellPhoneNumber?: PhoneNumber): string {
  if (cellPhoneNumber) {
    const { number = '', dialCode = '' } = cellPhoneNumber;

    if (number || dialCode) {
      return `${dialCode}${number}`;
    }
  }
  return '—';
}

export function findCustomerOption(
  options?: CountryOption[] | LanguageOption[],
  property?: string
): string {
  if (options) {
    const found = options.find(({ value }) => value === property);
    if (found) {
      return found.label;
    }
  }
  return '—';
}

export function createCustomerUpdateRequest(
  values: CustomerUpdateForm
): ICustomerUpdate {
  const { country } = values;

  // Location address
  const locationAddress = {
    ...values.locationAddress,
    country: ''
  };

  // Billing address
  let billingAddress = {
    ...values.billingAddress,
    country: ''
  };

  // Billing same as location
  if (values.sameAsLocationAddress) {
    billingAddress = locationAddress;
  }

  // Check if all empty address fields
  const emptyLocation = allKeysAreEmptyStrings(locationAddress);
  const emptyBilling = allKeysAreEmptyStrings(billingAddress);

  if (!emptyLocation && country) {
    locationAddress.country = country;
  }

  if (!emptyBilling && country) {
    billingAddress.country = country;
  }

  const dialCode = (values.phoneNumber && values.dialCode) ?? '';
  const phoneNumber = values.phoneNumber ?? '';

  return {
    firstName: values.firstName,
    lastName: values.lastName,
    ...(!emptyLocation && { locationAddress }),
    ...(!emptyBilling && { billingAddress }),
    country: values.country,
    language: values.language,
    eContact: {
      cellPhoneContacts: [
        {
          cellPhoneNumber: `${dialCode}${phoneNumber}`,
          isVerified: false
        }
      ]
    }
  };
}

export function isBusinessCustomer(customer: Customer | null) {
  if (customer) {
    return customer?.type === CustomerType.OrganizationCustomer;
  }
}

export function getConsentTranslation(
  texts?: CustomerConsentTranslationText,
  languageCode?: string
) {
  if (languageCode && texts?.[languageCode]) {
    return {
      title: texts[languageCode].title,
      description: texts[languageCode].text
    };
  }
  if (texts?.en) {
    return {
      title: texts.en.title,
      description: texts.en.text
    };
  }
  return {
    title: '',
    description: ''
  };
}

export function getCustomerSignupProductMeta(invite: Invite | null) {
  if (invite?.id) {
    return {
      inviteId: invite.id,
      iprId: null
    };
  }
  return {
    iprId: invite?.products?.[0]?.iprId ?? null,
    inviteId: null
  };
}
