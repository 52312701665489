import { FC, useCallback, useRef, useState } from 'react';
import { Button, Text } from './styled';

type Props = {
  text: string;
  color?: string;
  onClick?: () => void;
};

const FloatingActionButton: FC<Props> = ({
  onClick,
  text,
  color = 'white'
}) => {
  // Refs
  const textRef = useRef<HTMLParagraphElement>(null);

  // State
  const [isActive, setIsActive] = useState<boolean>(false);

  // Actions
  const onEnter = useCallback(() => setIsActive(true), []);
  const onClose = useCallback(() => setIsActive(false), []);

  return (
    <Button
      onClick={onClick}
      onMouseEnter={onEnter}
      onMouseLeave={onClose}
      initial={false}
      animate={isActive ? 'active' : 'inactive'}
      transition={{ ease: 'easeOut' }}
      variants={{
        active: {
          width: textRef.current ? textRef.current.clientWidth + 88 : 56,
          transition: {
            duration: 0.2
          }
        },
        inactive: { width: 56 }
      }}
    >
      <svg viewBox="0 0 24 24" fill={color} width="24px" height="24px">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
      </svg>
      <Text
        ref={textRef}
        animate={isActive ? 'active' : 'inactive'}
        transition={{ ease: 'easeOut' }}
        initial={false}
        variants={{
          active: {
            opacity: 1,
            transition: {
              opacity: {
                delay: 0.2
              }
            }
          },
          inactive: {
            opacity: 0,
            transition: {
              duration: 0.1
            }
          }
        }}
      >
        {text}
      </Text>
    </Button>
  );
};

export default FloatingActionButton;
