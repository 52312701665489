import styled from 'styled-components';

export const Form = styled.form`
  width: 100%;
  margin-bottom: 1em;
  text-align: center;
`;

export const LoaderGrid = styled.div`
  display: flex;
  justify-content: center;
  margin: 16px;
  height: 35px;
`;
