import { FC, ReactNode } from 'react';
import { Container } from './styled';

type Props = {
  name: string;
  children: ReactNode;
};

const DataCell: FC<Props> = ({ name, children }) => {
  return (
    <Container>
      <p>{name}</p>
      <h3>{children ?? '—'}</h3>
    </Container>
  );
};

export default DataCell;
