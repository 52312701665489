import styled, { DefaultTheme, createGlobalStyle } from 'styled-components';

import husqvarnaBoldWoff from 'assets/fonts/Husqvarna-Gothic-Bold.woff';
import husqvarnaBoldWoff2 from 'assets/fonts/Husqvarna-Gothic-Bold.woff2';
import husqvarnaBoldItalicWoff from 'assets/fonts/Husqvarna-Gothic-Bold-Italic.woff';
import husqvarnaBoldItalicWoff2 from 'assets/fonts/Husqvarna-Gothic-Bold-Italic.woff2';
import husqvarnaRegularWoff from 'assets/fonts/Husqvarna-Gothic-Regular.woff';
import husqvarnaRegularWoff2 from 'assets/fonts/Husqvarna-Gothic-Regular.woff2';
import husqvarnaRegularItalicWoff from 'assets/fonts/Husqvarna-Gothic-Regular-Italic.woff';
import husqvarnaRegularItalicWoff2 from 'assets/fonts/Husqvarna-Gothic-Regular-Italic.woff2';
import husqvarnaLightWoff from 'assets/fonts/Husqvarna-Gothic-Light.woff';
import husqvarnaLightWoff2 from 'assets/fonts/Husqvarna-Gothic-Light.woff2';
import husqvarnaLightItalicWoff from 'assets/fonts/Husqvarna-Gothic-Light-Italic.woff';
import husqvarnaLightItalicWoff2 from 'assets/fonts/Husqvarna-Gothic-Light-Italic.woff2';

import husqvarnaLogoPath from 'assets/vectors/logo.svg';

export type ThemeColor = keyof DefaultTheme['colors'];

// Logos
export const logoPaths = {
  husqvarna: husqvarnaLogoPath
};

// Box Shadows
export const boxShadows = {
  xs: '0 0 0 1px rgba(0, 0, 0, 0.05)',
  sm: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
  normal:
    '0 1px 3px 0 rgba(0, 0, 0, 0.18), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14)',
  md: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
  lg: '0 10px 15px -3px rgba(0, 0, 0, 0.1)',
  xl: '0 20px 25px -5px rgba(0, 0, 0, 0.1)',
  xxl: '0 25px 50px -12px rgba(0, 0, 0, 0.25)'
};

// Breakpoints
export const breakpoints = {
  s: 576,
  m: 768,
  l: 992,
  xl: 1200,
  xxl: 1920
};

export const ModalTitle = styled.h2`
  padding: 40px 40px 20px 40px;
  margin: 0;
`;

export const ModalDescription = styled.p`
  padding: 0 40px;
  margin-bottom: 20px;
`;

export const ModalForm = styled.form`
  padding: 0 40px 40px;
`;

export const Link = styled.a`
  color: ${({ theme }) => theme.colors.grey6};
  font-family: Roboto, Arial, sans-serif;
  text-decoration: underline;
  font-style: normal;
  font-weight: 350;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.4px;
  -webkit-font-smoothing: antialiased;
`;

export const GlobalStyles = createGlobalStyle`
  @keyframes backgroundColorAnim {
    0% { background-color: ${({ theme }) => theme.colors.primary}}
    45% { background-color: ${({ theme }) => theme.colors.primary}}
    50% { background-color: ${({ theme }) => theme.colors.grey4}}
    95% { background-color: ${({ theme }) => theme.colors.grey4}}
    100% { background-color: ${({ theme }) => theme.colors.primary}}
  }

  @font-face {
    font-family: 'Husqvarna Gothic';
    font-weight: 700;
    src:
      url(${husqvarnaBoldWoff2}) format('woff2'),
      url(${husqvarnaBoldWoff}) format('woff');
  }

  @font-face {
    font-family: 'Husqvarna Gothic';
    font-weight: 700;
    font-style: italic;
    src:
      url(${husqvarnaBoldItalicWoff2}) format('woff2'),
      url(${husqvarnaBoldItalicWoff}) format('woff');
  }

  @font-face {
    font-family: 'Husqvarna Gothic';
    font-weight: 400;
    src:
      url(${husqvarnaRegularWoff2}) format('woff2'),
      url(${husqvarnaRegularWoff}) format('woff');
  }

  @font-face {
    font-family: 'Husqvarna Gothic';
    font-weight: 400;
    font-style: italic;
    src:
      url(${husqvarnaRegularItalicWoff2}) format('woff2'),
      url(${husqvarnaRegularItalicWoff}) format('woff');
  }


  @font-face {
    font-family: 'Husqvarna Gothic';
    font-weight: 300;
    src:
      url(${husqvarnaLightWoff2}) format('woff2'),
      url(${husqvarnaLightWoff}) format('woff');
  }

  @font-face {
    font-family: 'Husqvarna Gothic';
    font-weight: 300;
    font-style: italic;
    src:
      url(${husqvarnaLightItalicWoff2}) format('woff2'),
      url(${husqvarnaLightItalicWoff}) format('woff');
  }

  * {
    box-sizing: border-box;
  }

  html,
  body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.background};
  }

  #root {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
  }

  p,
  div,
  input,
  label,
  button {
    font-family: ${({ theme }) => theme.fontFamily};
  }

  p {
    color: ${({ theme }) => theme.colors.grey6};
    margin: 0 0 16px;
    font-style: normal;
    font-size: 18px;
    font-weight: 350;
    line-height: 30px;
    font-family: Roboto, Arial, sans-serif;
    letter-spacing: 0.4px;
    -webkit-font-smoothing: antialiased;

    @media (max-width: ${breakpoints.s}px) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  label {
    margin: 0 0 8px;
  }

  button {
    cursor: pointer;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${({ theme }) => theme.colors.grey6};
    font-family: ${({ theme }) => theme.fontFamily};
    font-weight: bold;
    margin: 0 0 8px;
  }

  h1 {
    font-size: 36px;
    line-height: 42px;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 20px;
  }

  h4 {
    font-size: 18px;
  }

  h5 {
    font-size: 16px;
  }

  a {
    text-decoration: none;
  }
`;
