import { FC, useEffect, useCallback, useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { Buffer } from 'buffer';

import { loginRedirect } from 'services/authService';
import { authSelector } from 'store/authState';
import {
  signUp,
  fetchCaptcha,
  submitFinalization,
  signUpSelector,
  cancelFinalization
} from 'store/signupState';
import { localizationSelector } from 'store/localizationState';
import { inviteSelector } from 'store/inviteState';
import * as routes from 'router/routes';
import { useTranslations } from 'hooks/useTranslations';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { showSuccessToast } from 'utils/toastUtils';
import { allKeysAreEmptyStrings } from 'utils/objectUtils';
import {
  getConsentTranslation,
  getCustomerSignupProductMeta
} from 'utils/customerUtils';
import { CreateCustomerAttributes, DiamGroups } from 'models/customer';
import { JsonData } from 'models/jsonApi';
import userIcon from 'assets/vectors/user.svg';

import Checkbox from 'components/UI/Checkbox';
import Button from 'components/UI/Buttons/Button';
import InputField from 'components/UI/InputField';
import SelectField from 'components/UI/SelectField';
import Heading, { Type as HeadingType } from 'components/UI/Heading';
import AnimatedFormGroup from 'components/UI/AnimatedFormGroup';
import PhoneNumberField from 'components/UI/PhoneNumberField';
import BackButton from 'components/UI/Buttons/BackButton';
import PageHeader from 'components/UI/Page/PageHeader';
import Loader from 'components/UI/Loader';

import {
  Form,
  CaptchaContainer,
  ButtonContainer,
  CheckBoxContainer,
  CaptchaCode
} from './styled';

const FinalizeSignup: FC = () => {
  const t = useTranslations();
  const dispatch = useAppDispatch();
  const signUpState = useAppSelector(signUpSelector);
  const authState = useAppSelector(authSelector);
  const { invite } = useAppSelector(inviteSelector);
  const { languageCode = 'en', countryOptions } =
    useAppSelector(localizationSelector);
  const {
    handleSubmit,
    register,
    formState: { errors },
    clearErrors,
    setValue,
    watch
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      firstName: '',
      lastName: '',
      street: '',
      zipCode: '',
      city: '',
      country: '',
      dialCode: '',
      phoneNumber: '',
      captchaCode: '',
      consent: false
    }
  });

  // State
  const {
    email,
    password,
    captchaElement,
    isFinalized,
    isLoading,
    completeStatus,
    consentType,
    country,
    error
  } = signUpState;

  // Watch
  const watchConsent = watch('consent', false);
  const watchCountry = watch('country', '');
  const watchStreet = watch('street', '');
  const isAddressOpen = watchStreet !== '';

  // Reset inputfield
  const reset = useCallback(
    (name: any) => {
      setValue(name, '');
      clearErrors(name);
    },
    [setValue, clearErrors]
  );

  useEffect(() => {
    if (watchStreet === '') {
      setValue('zipCode', '');
      setValue('city', '');
      clearErrors(['zipCode', 'city']);
    }
  }, [setValue, clearErrors, watchStreet]);

  // Reset captcha code
  useEffect(() => {
    if (error) {
      dispatch(fetchCaptcha(email));
    }
  }, [dispatch, email, error]);

  // Error
  useEffect(() => {
    if (error?.code === 'captcha.not.found') {
      reset('captchaCode');
    }
  }, [error, reset]);

  // Signup
  useEffect(() => {
    if (isFinalized) {
      const { dialCode, number } = signUpState.phoneNumber;

      const customer: CreateCustomerAttributes = {
        email: signUpState.email,
        password: signUpState.password,
        firstName: signUpState.firstName,
        lastName: signUpState.lastName,
        country: signUpState.country,
        language: signUpState.language,
        phoneNumber: dialCode && number ? `${dialCode}${number}` : '',
        locationAddress: signUpState.address,
        billingAddress: signUpState.address,
        shippingAddress: {},
        groups: [DiamGroups.MyPages]
      };

      const meta: JsonData = {
        product: getCustomerSignupProductMeta(invite),
        consents: signUpState.consentStatus
          ? [
              {
                consentTypeId: signUpState.consentType?.id ?? '',
                status: 'ACCEPTED'
              }
            ]
          : undefined
      };

      dispatch(signUp(customer, signUpState.captchaCode, meta));
    }
  }, [dispatch, isFinalized, invite, signUpState]);

  // Complete status
  useEffect(() => {
    if (!completeStatus.created) {
      return;
    }
    if (completeStatus.emailVerified) {
      loginRedirect(languageCode, email, true);
      return;
    }
    showSuccessToast(t('signup.finalizing_form.email_needs_verification'));
  }, [dispatch, t, completeStatus, email, password, languageCode]);

  // Submit
  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    (values) => {
      const newAddress = {
        street: values.street,
        zipCode: values.zipCode,
        city: values.city,
        country: ''
      };

      if (!allKeysAreEmptyStrings(newAddress)) {
        newAddress.country = values.country;
      }

      dispatch(
        submitFinalization({
          firstName: values.firstName,
          lastName: values.lastName,
          captchaCode: values.captchaCode,
          address: newAddress,
          country: values.country,
          language: languageCode ?? '',
          phoneNumber: {
            dialCode: values.dialCode,
            number: values.phoneNumber
          },
          consent: values.consent
        })
      );
    },
    [dispatch, languageCode]
  );

  // Cancel finalization
  const onCancelFinalize = useCallback(
    () => dispatch(cancelFinalization()),
    [dispatch]
  );

  // On dial code change
  const onDialCodeChange = useCallback(
    (value: string) => setValue('dialCode', value),
    [setValue]
  );

  // Consent text
  const consent = useMemo(
    () => getConsentTranslation(consentType?.texts, languageCode),
    [languageCode, consentType]
  );

  // Redirect to invite registration
  if (invite && authState.session?.authenticated) {
    return <Navigate to={routes.INVITE_PRODUCTS_REG} />;
  }

  if (completeStatus.created && !completeStatus.emailVerified) {
    return (
      <Navigate
        to={`${routes.VERIFY_EMAIL}?email=${encodeURIComponent(email)}`}
      />
    );
  }

  // Loading
  if (isLoading || authState.isLoading || !captchaElement) {
    return <Loader center size="large" />;
  }

  return (
    <>
      <BackButton onClick={onCancelFinalize}>
        {t('productinfo.btn_back')}
      </BackButton>
      <PageHeader
        title={t('signup.finalizing_form.title')}
        subtitle={t('signup.finalizing_form.subtitle')}
        image={userIcon}
        imageAlt="user"
      />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <InputField
          name="firstName"
          description="signup.finalizing_form.label_firstname"
          error={errors.firstName}
          register={register('firstName', {
            required: {
              value: true,
              message: 'signup.finalizing_form.firstname_required'
            }
          })}
          reset={reset}
        />
        <InputField
          name="lastName"
          description="signup.finalizing_form.label_lastname"
          error={errors.lastName}
          register={register('lastName', {
            required: {
              value: true,
              message: 'signup.finalizing_form.lastname_required'
            }
          })}
          reset={reset}
        />
        <SelectField
          name="country"
          register={register('country', {
            required: {
              value: true,
              message: 'signup.finalizing_form.country_required'
            }
          })}
          setValue={setValue}
          clearErrors={clearErrors}
          placeholder={t('signup.finalizing_form.label_country_prompt')}
          description="signup.finalizing_form.label_country"
          error={errors.country}
          defaultValue={country}
          options={countryOptions}
        />
        <PhoneNumberField
          description="signup.finalizing_form.label_phone_number"
          numberInputRef={register('phoneNumber')}
          dialCodeInputRef={register('dialCode')}
          onDialCodeChange={onDialCodeChange}
          defaultCountry={watchCountry}
          error={errors.phoneNumber}
        />
        <InputField
          name="street"
          register={register('street')}
          description="signup.finalizing_form.label_address"
          error={errors.street}
          reset={reset}
        />
        <AnimatedFormGroup isOpen={isAddressOpen}>
          <InputField
            name="zipCode"
            register={register('zipCode', {
              required: {
                value: isAddressOpen,
                message: 'input.required'
              }
            })}
            description="signup.finalizing_form.label_zipCode"
            error={errors.zipCode}
            reset={reset}
          />
          <InputField
            name="city"
            register={register('city', {
              required: {
                value: isAddressOpen,
                message: 'input.required'
              }
            })}
            description="signup.finalizing_form.label_city"
            error={errors.city}
            reset={reset}
          />
        </AnimatedFormGroup>
        <CheckBoxContainer>
          <Checkbox
            id="consent"
            register={register('consent')}
            isChecked={watchConsent}
          />
          <div>
            <Heading type={HeadingType.h3}>{consent.title}</Heading>
            <p>{consent.description}</p>
          </div>
        </CheckBoxContainer>
        <CaptchaContainer>
          <CaptchaCode
            src={`data:image/svg+xml;base64,${Buffer.from(
              captchaElement
            ).toString('base64')}`}
            alt="captcha"
          />
        </CaptchaContainer>
        <InputField
          name="captchaCode"
          description="signup.finalizing_form.label_captcha_code"
          register={register('captchaCode', {
            required: {
              value: true,
              message: t('signup.finalizing_form.enter_captcha_prompt')
            }
          })}
          error={errors.captchaCode}
          uppercase
        />
        <ButtonContainer>
          <Button submit fullWidth>
            {t('signup.finalizing_form.btn_done')}
          </Button>
        </ButtonContainer>
      </Form>
    </>
  );
};

export default FinalizeSignup;
