import { sortMetaByDialCode } from 'utils/phoneNumberUtils';
import countryDialCodes from 'assets/data/country-code-meta.json';

const sortedCountryPhoneMeta = sortMetaByDialCode(countryDialCodes, true);

export class PhoneNumber {
  dialCode: string;
  number: string;

  constructor(dialCode?: string, number?: string) {
    this.dialCode = dialCode ?? '';
    this.number = number ?? '';
  }

  static parse(fullNumber: string) {
    if (!fullNumber) {
      return new PhoneNumber();
    }

    const countryPhoneMetaMatch = sortedCountryPhoneMeta.find((cm) =>
      new RegExp(`\\${cm.dialCode}`, 'g').exec(fullNumber)
    );

    if (!countryPhoneMetaMatch?.dialCode) {
      return new PhoneNumber(undefined, fullNumber);
    }

    return new PhoneNumber(
      countryPhoneMetaMatch.dialCode,
      fullNumber.substring(countryPhoneMetaMatch.dialCode.length)
    );
  }
}
