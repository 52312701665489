import { FC, useCallback } from 'react';
import { useForm } from 'react-hook-form';

import { mailRegex } from 'utils/constants';

import PageImageWrapper from 'components/UI/Page/PageImageWrapper';
import Heading, { Type as HeadingType } from 'components/UI/Heading';
import { useTranslations } from 'hooks/useTranslations';
import InputField from 'components/UI/InputField';
import Button from 'components/UI/Buttons/Button';

import emailIconPath from 'assets/images/husqvarna-email-icon@2x.png';

import { ImageContainer, HeadingContainer, Form } from './styled';

type Props = {
  title: string;
  description: string[];
  buttonText: string;
  email: string | null;
  submitForm: () => void;
};

const VerifyAccount: FC<Props> = ({
  title,
  description,
  buttonText,
  email,
  submitForm
}) => {
  const t = useTranslations();

  // Form
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue
  } = useForm({
    defaultValues: { email }
  });

  // Reset email
  const reset = useCallback(
    (name: any) => {
      setValue(name, '');
      clearErrors(name);
    },
    [setValue, clearErrors]
  );

  return (
    <PageImageWrapper>
      <ImageContainer>
        <img src={emailIconPath} alt="Verify email" />
      </ImageContainer>
      <HeadingContainer>
        <Heading type={HeadingType.h1}>{t(title)}</Heading>
        {description.map((item) => (
          <p key={item}>{t(item)}</p>
        ))}
      </HeadingContainer>
      <Form onSubmit={handleSubmit(submitForm)}>
        <InputField
          name="email"
          description="input.email_label"
          error={errors.email}
          register={register('email', {
            required: {
              value: true,
              message: 'input.email_required'
            },
            pattern: {
              value: mailRegex,
              message: 'input.email_invalid'
            }
          })}
          reset={reset}
          disabled
        />
        <Button submit color="transparent" textColor="primary">
          {t(buttonText)}
        </Button>
      </Form>
    </PageImageWrapper>
  );
};

export default VerifyAccount;
