import styled from 'styled-components';
import { breakpoints } from 'styles';

type Props = {
  onClick?: () => void;
};

export const Card = styled.div<Props>`
  border-radius: 32px;
  background-color: ${({ theme }) => theme.colors.surface};
  margin-bottom: 16px;
  transition: box-shadow 0.1s;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  color: ${({ theme }) => theme.colors.onSurface};
  padding: 20px;
  box-shadow: 3px 3px 2px rgba(0, 0, 0, 0.04);
  transition: box-shadow 120ms;

  &:hover {
    box-shadow: ${({ onClick }) =>
      onClick
        ? '3px 3px 2px 1px rgba(0, 0, 0, 0.16)'
        : '3px 3px 2px rgba(0, 0, 0, 0.04)'};
  }

  @media (min-width: ${breakpoints.m}px) {
    margin-bottom: 0;
  }
`;

export const CardImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
  }
`;

export const CardHeader = styled.div`
  display: flex;
  position: relative;
  margin: 16px 0;
`;

export const CardTitleCategory = styled.div`
  font-size: 14px;
  line-height: 16px;
  text-transform: capitalize;
  margin-bottom: 4px;
`;

export const CardTitleModelName = styled.h3`
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 6px;
`;

export const CardTitleDescription = styled.p`
  font-size: 14px;
  line-height: 20px;
  margin: 0;
`;

export const CardData = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 32px;
  margin-bottom: 32px;

  > div {
    > h6 {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 4px;
    }
    > p {
      font-size: 14px;
      line-height: 20px;
      margin: 0;
    }
  }
`;

export const CardActions = styled.div`
  width: 100%;
  height: 46px;
  display: flex;
  justify-content: flex-end;
`;
