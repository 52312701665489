import { Invite } from 'models/invite';
import { ProductRegistrationInfo } from 'models/productRegistration';

export function createProductRegistrationBody(
  info: ProductRegistrationInfo,
  soldDate?: number | null,
  invite?: Invite
) {
  return {
    attributes: {
      iprId: info.iprId,
      invite: invite,
      ...(soldDate && { soldDate })
    }
  };
}
