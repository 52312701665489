import styled from 'styled-components';

export const DatePickerRoot = styled.div`
  position: absolute;
  top: 70%;
  left: 0;
  width: 100%;

  .react-calendar {
    border: 1px solid ${({ theme }) => theme.colors.grey4};
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
  }

  .react-calendar__tile--now {
    background: ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.onSecondary};
  }

  .react-calendar__tile--active {
    background: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.onPrimary};

    &:focus {
      background: ${({ theme }) => theme.colors.primary};
      color: ${({ theme }) => theme.colors.onPrimary};
    }
  }

  .react-calendar__tile {
    &:hover {
      background: ${({ theme }) => theme.colors.primary}99;
      color: ${({ theme }) => theme.colors.onPrimary};
    }

    &:disabled {
      background: ${({ theme }) => theme.colors.background};
      color: ${({ theme }) => theme.colors.onBackground}99;
    }
  }

  .react-calendar__month-view__weekdays__weekday {
    > abbr {
      text-decoration: none;
    }
  }

  .react-calendar__navigation__arrow {
    font-size: 24px;
  }

  .react-calendar__navigation__label__labelText {
    font-size: 18px;
  }
`;
