import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { DIAMGroup, ICustomerChangeEmailAttributes } from 'models/customer';
import { ApiError } from 'models/errors/ApiError';
import type { RootState } from 'store';
import * as customerService from 'services/customerService';
import { displayApiError } from '../globalErrorState';

type RequestAttributes = {
  newEmail: string | null;
  isCompleted: boolean;
  isLoading: boolean;
  isError: boolean;
  error: ApiError | null;
};

type ValidationAttributes = {
  newEmail: string | null;
  groups: DIAMGroup[];
  isLoading: boolean;
  isError: boolean;
  error: ApiError | null;
};

type ConfirmationAttributes = {
  isCompleted: boolean;
  isLoading: boolean;
  isError: boolean;
  error: ApiError | null;
};

export type ChangeEmailState = {
  request: RequestAttributes;
  validation: ValidationAttributes;
  confirmation: ConfirmationAttributes;
};

export const defaultChangeEmailState: ChangeEmailState = {
  request: {
    newEmail: null,
    isCompleted: false,
    isLoading: false,
    isError: false,
    error: null
  },
  validation: {
    newEmail: null,
    groups: [],
    isLoading: false,
    isError: false,
    error: null
  },
  confirmation: {
    isCompleted: false,
    isLoading: false,
    isError: false,
    error: null
  }
};

const changeEmailSlice = createSlice({
  name: 'change-email',
  initialState: defaultChangeEmailState,
  reducers: {
    changeEmailRequestStart: (state) => {
      state.request.isLoading = true;
      state.request.isCompleted = false;
      state.request.isError = false;
      state.request.error = null;
    },
    changeEmailRequestSuccess: (state, action: PayloadAction<string>) => {
      state.request.isLoading = false;
      state.request.isCompleted = true;
      state.request.newEmail = action.payload;
    },
    changeEmailRequestFailure: (state, action: PayloadAction<ApiError>) => {
      state.request.isLoading = false;
      state.request.isError = true;
      state.request.error = action.payload;
    },
    changeEmailValidationStart: (state) => {
      state.validation.groups = [];
      state.validation.isLoading = true;
      state.validation.isError = false;
      state.validation.error = null;
    },
    changeEmailValidationSuccess: (
      state,
      action: PayloadAction<ICustomerChangeEmailAttributes>
    ) => {
      state.validation.groups = action.payload.groups;
      state.validation.newEmail = action.payload.newEmail;
      state.validation.isLoading = false;
      state.validation.error = null;
    },
    changeEmailValidationFailure: (state, action: PayloadAction<ApiError>) => {
      state.validation.isLoading = false;
      state.validation.isError = true;
      state.validation.error = action.payload;
    },
    changeEmailConfirmationStart: (state) => {
      state.confirmation.isLoading = true;
      state.confirmation.isCompleted = false;
      state.confirmation.isError = false;
      state.confirmation.error = null;
    },
    changeEmailConfirmationSuccess: (state) => {
      state.confirmation.isCompleted = true;
      state.confirmation.isLoading = false;
      state.confirmation.isError = false;
      state.confirmation.error = null;
    },
    changeEmailConfirmationFailure: (
      state,
      action: PayloadAction<ApiError>
    ) => {
      state.confirmation.isLoading = false;
      state.confirmation.isError = true;
      state.confirmation.error = action.payload;
    },
    resetChangeEmailState: () => {
      return defaultChangeEmailState;
    }
  }
});

// Actions
export const {
  changeEmailRequestStart,
  changeEmailRequestSuccess,
  changeEmailRequestFailure,
  changeEmailValidationStart,
  changeEmailValidationSuccess,
  changeEmailValidationFailure,
  changeEmailConfirmationStart,
  changeEmailConfirmationSuccess,
  changeEmailConfirmationFailure,
  resetChangeEmailState
} = changeEmailSlice.actions;

// Reducer
export const changeEmailReducer = changeEmailSlice.reducer;

// Selector
export const changeEmailSelector = (state: RootState) => state.changeEmail;

// Thunks
export function changeEmailRequest(
  customerId: string,
  newEmail: string,
  password: string,
  token: string
) {
  return async (dispatch: Dispatch) => {
    dispatch(changeEmailRequestStart());
    try {
      await customerService.requestChangeEmail(
        customerId,
        newEmail,
        password,
        token
      );
      dispatch(changeEmailRequestSuccess(newEmail));
    } catch (e: any) {
      dispatch(displayApiError(e));
      dispatch(changeEmailRequestFailure(e));
    }
  };
}

export function changeEmailValidation(customerId: string, code: string) {
  return async (dispatch: Dispatch) => {
    dispatch(changeEmailValidationStart());
    try {
      const response = await customerService.validateChangeEmail(
        customerId,
        code
      );
      dispatch(changeEmailValidationSuccess(response.data.attributes));
    } catch (e: any) {
      dispatch(displayApiError(e));
      dispatch(changeEmailValidationFailure(e));
    }
  };
}

export function changeEmailConfirmation(
  customerId: string,
  newEmail: string,
  code: string,
  password: string
) {
  return async (dispatch: Dispatch) => {
    dispatch(changeEmailConfirmationStart());
    try {
      await customerService.confirmChangeEmail(
        customerId,
        newEmail,
        code,
        password
      );
      dispatch(changeEmailConfirmationSuccess());
    } catch (e: any) {
      dispatch(displayApiError(e));
      dispatch(changeEmailConfirmationFailure(e));
    }
  };
}
