import { FC } from 'react';
import { Wrapper } from './styled';

type Props = {
  children: any;
  empty?: boolean;
  responsive?: boolean;
};

const PageWrapper: FC<Props> = ({
  children,
  empty = false,
  responsive = true
}) => {
  return (
    <Wrapper $responsive={responsive} $empty={empty}>
      {children}
    </Wrapper>
  );
};

export default PageWrapper;
