import { JsonData } from 'models/jsonApi';

export function sortObjArrayByProperty<T extends JsonData>(
  array: T[],
  property: string,
  asc = false
): T[] {
  return [...array].sort((a, b) => {
    const aValue = a[property];
    const bValue = b[property];
    if (aValue > bValue) {
      return asc ? 1 : -1;
    }
    if (aValue < bValue) {
      return asc ? -1 : 1;
    }
    return 0;
  });
}
