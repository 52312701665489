import styled from 'styled-components';
import { ThemeColor, breakpoints } from 'styles';

export type ButtonSize = 'large' | 'medium' | 'small';

export type ButtonColor = ThemeColor | 'transparent';

type ButtonProps = {
  $textColor: ThemeColor;
  $color: ButtonColor;
  $size: ButtonSize;
  $border: boolean;
  $fullWidth: boolean;
};

// Render font size
function renderFontSize(size: ButtonSize) {
  if (size === 'large') {
    return '16px';
  }
  if (size === 'small') {
    return '12px';
  }
  return '14px';
}

// Render line height
function renderLineHeight(size: ButtonSize) {
  if (size === 'large') {
    return '20px';
  }
  if (size === 'small') {
    return '14px';
  }
  return '16px';
}

// Render padding
function renderPadding(size: ButtonSize) {
  if (size === 'large') {
    return '16px 32px';
  }
  if (size === 'small') {
    return '9px 16px';
  }
  return '13px 16px';
}

export const StyledButton = styled.button<ButtonProps>`
  color: ${({ theme, $textColor }) => theme.colors[$textColor]};
  background-color: ${({ theme, $color }) => {
    if ($color === 'transparent') {
      return 'transparent';
    }
    return theme.colors[$color];
  }};
  border: ${({ theme, $border }) =>
    $border ? `1px solid ${theme.colors.grey6}` : 'none'};
  border-radius: 16px;
  line-height: ${({ $size }) => renderLineHeight($size)};
  font-size: ${({ $size }) => renderFontSize($size)};
  padding: ${({ $size }) => renderPadding($size)};
  text-transform: ${({ color }) =>
    color === 'transparent' ? 'initial' : 'uppercase'};
  font-weight: bold;
  margin: 0;
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'initial')};

  @media (max-width: ${breakpoints.s}px) {
    width: 100%;
    overflow-wrap: anywhere;
  }

  &:disabled {
    opacity: 60%;
  }
`;
