import { FC, useMemo } from 'react';
import moment from 'moment';

import { useTranslations } from 'hooks/useTranslations';
import { ServiceRecord } from 'models/product';
import { sortObjArrayByProperty } from 'utils/arrayUtils';

import { IconType } from 'components/UI/Icon';
import PageState from 'components/UI/Page/PageState';
import CardTable, {
  TableBodyItem,
  TableColItem
} from 'components/UI/CardTable';

import { Container } from './styled';

type Props = {
  data: ServiceRecord[];
};

const ProductServiceBook: FC<Props> = ({ data }) => {
  const t = useTranslations();

  // Head
  const head: TableColItem[] = useMemo(() => {
    return [
      {
        key: 1,
        item: t('productdetails.dsb_title')
      },
      {
        key: 2,
        item: t('productdetails.dsb_date')
      }
    ];
  }, [t]);

  // Body
  const body: TableBodyItem[] = useMemo(() => {
    return sortObjArrayByProperty(data, 'recordDate').map(
      ({ id, title, recordDate }) => {
        return {
          key: id,
          cols: [
            {
              key: `${id}-dsb_title`,
              item: title
            },
            {
              key: `${id}-date`,
              item: moment(recordDate).format('YYYY-MM-DD')
            }
          ]
        };
      }
    );
  }, [data]);

  // Empty state
  if (!data.length) {
    return (
      <PageState
        iconType={IconType.Maintenance}
        title={t('productdetails.dsb_empty')}
        small
      />
    );
  }

  return (
    <Container>
      <CardTable head={head} body={body} />
    </Container>
  );
};

export default ProductServiceBook;
