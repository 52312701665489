import errorCodes from './errorCodes.json';

export type ErrorCode = {
  messageKey: string;
  type: string;
};

export type ErrorCodeKey = keyof typeof errorCodes;

export type ApiErrorCode = {
  status: number;
  code: ErrorCodeKey;
};

export const BAD_ARGUMENTS: ApiErrorCode = {
  status: 400,
  code: 'invalid.arguments' as ErrorCodeKey
};

export function toErrorCode(code: string): ErrorCode {
  const errorCode = errorCodes[code as ErrorCodeKey];
  if (errorCode) {
    return errorCode;
  }
  console.warn('Unknown error code: %s! Default error will be used.', code);
  return errorCodes.unknown;
}

export function getErrorCode(code: string) {
  const found = errorCodes[code as ErrorCodeKey];

  if (!found) {
    throw new Error(`Error code "${code}" doesn't exist!`);
  }

  return found;
}
