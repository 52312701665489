import { FC, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  resetInviteState,
  inviteSelector,
  inviteRegistrationError,
  completeInviteFailure,
  completeInviteCancel,
  completeInviteStart
} from 'store/inviteState';
import { useRegisterProductsMutation } from 'store/productService';
import { displayGlobalError } from 'store/globalErrorState';
import { customerSelector } from 'store/customerState';
import * as routes from 'router/routes';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useTranslations } from 'hooks/useTranslations';
import { parseJsonError } from 'utils/fetchUtils';

import CalendarInputModal from 'components/modals/CalendarInputModal';
import PageWrapper from 'components/UI/Page/PageWrapper';
import PageState from 'components/UI/Page/PageState';
import Loader from 'components/UI/Loader';
import Button from 'components/UI/Buttons/Button';
import { IconType } from 'components/UI/Icon';

import { ButtonGrid } from './styled';

const RegisterInviteProductsPage: FC = () => {
  const t = useTranslations();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Redux
  const { invite, isCompleted, soldDateRequired, hasErrors } =
    useAppSelector(inviteSelector);
  const { customer } = useAppSelector(customerSelector);

  // Hooks
  const [registerProducts, { isSuccess, error, isLoading }] =
    useRegisterProductsMutation();

  // Register products
  const onRegisterProducts = useCallback(
    (soldDate?: number) => {
      if (customer && invite) {
        registerProducts({
          customerId: customer.id,
          soldDate,
          invite
        });
      }
    },
    [registerProducts, customer, invite]
  );

  // Register products
  useEffect(() => {
    onRegisterProducts();
  }, [onRegisterProducts]);

  // Handle success
  useEffect(() => {
    if (isSuccess) {
      dispatch(displayGlobalError('product.registered.success'));
    }
  }, [dispatch, isSuccess]);

  // Handle error
  useEffect(() => {
    if (error) {
      const { data } = error as any;
      const apiError = parseJsonError(data);
      dispatch(inviteRegistrationError(apiError));
    }
  }, [dispatch, error]);

  // Redirect on success
  useEffect(() => {
    if (isSuccess || !invite || isCompleted) {
      dispatch(resetInviteState());
      navigate(routes.PRODUCTS);
    }
  }, [dispatch, navigate, isSuccess, invite, isCompleted]);

  // Navigate to products
  const onGoToHome = useCallback(() => {
    dispatch(completeInviteFailure());
    navigate(routes.PRODUCTS);
  }, [dispatch, navigate]);

  // Cancel registration
  const onCancel = useCallback(() => {
    dispatch(completeInviteCancel());
    navigate(routes.PRODUCT_INFO);
  }, [navigate, dispatch]);

  // Retry registration
  const onRetryRegistration = useCallback(() => {
    dispatch(completeInviteStart());
    onRegisterProducts();
  }, [dispatch, onRegisterProducts]);

  // Retry if registration fails
  if (hasErrors && !isLoading) {
    return (
      <PageWrapper>
        <PageState
          iconType={IconType.Error}
          title={t('finalizeproductregistration.error.title')}
          description={t('finalizeproductregistration.error.description')}
        >
          <ButtonGrid>
            <Button onClick={onRetryRegistration}>
              {t('finalizeproductregistration.error.btn')}
            </Button>
            <Button
              color="transparent"
              textColor="primary"
              onClick={onGoToHome}
            >
              {t('not_found.btn_to_home')}
            </Button>
          </ButtonGrid>
        </PageState>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      <CalendarInputModal
        isOpen={soldDateRequired}
        submit={onRegisterProducts}
        close={onCancel}
      />
      <Loader center size="large" />
    </PageWrapper>
  );
};

export default RegisterInviteProductsPage;
