import { FC, useCallback, useEffect, useMemo } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { Navigate, useNavigate } from 'react-router-dom';

import { loginRedirect } from 'services/authService';
import { localizationSelector } from 'store/localizationState';
import {
  inviteSelector,
  updateInviteEmail,
  resetInviteState,
  completeInviteStart
} from 'store/inviteState';
import { authSelector } from 'store/authState';
import { useGetAccountStatusByEmailMutation } from 'store/customerService';
import * as routes from 'router/routes';

import { useQuery } from 'hooks/useQuery';
import { useFetchInvite } from 'hooks/useFetchInvite';
import { useTranslations } from 'hooks/useTranslations';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { mailRegex } from 'utils/constants';
import { Product } from 'models/product';

import Loader from 'components/UI/Loader';
import SeoHelmet from 'components/fragments/SeoHelmet';
import Heading from 'components/UI/Heading';
import Button from 'components/UI/Buttons/Button';
import InputField from 'components/UI/InputField';
import PageWrapper from 'components/UI/Page/PageWrapper';
import BackButton from 'components/UI/Buttons/BackButton';
import ProductCardList from 'components/UI/Product/ProductCardList';
import ProductInfoCard from 'components/fragments/ProductInfoCard';
import ProductImage from 'components/UI/Product/ProductImage';
import DataCell from 'components/UI/DataCell';
import Copyright from 'components/UI/Copyright';

import {
  CardContainer,
  Form,
  DataRow,
  ResponsiveContainer,
  PageContainer,
  Description,
  Type
} from './styled';

const ProductInformationPage: FC = () => {
  const t = useTranslations();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Redux
  const {
    session: { authenticated }
  } = useAppSelector(authSelector);
  const { invite, isCompleted } = useAppSelector(inviteSelector);
  const { languageCode = 'en' } = useAppSelector(localizationSelector);

  // Form
  const {
    handleSubmit,
    register,
    formState: { errors },
    resetField,
    setValue
  } = useForm();

  // Query
  const [pnc, serialNumber, husqvarnaId, code] = useQuery(
    'productno',
    'serialNumber',
    'husqvarnaId',
    'code'
  );

  // Hooks
  const { isLoading, isError } = useFetchInvite({
    pnc,
    serialNumber,
    husqvarnaId,
    code
  });
  const [checkAccountStatus, accountStatus] =
    useGetAccountStatusByEmailMutation();

  // Complete
  useEffect(() => {
    if (isCompleted && !invite) {
      navigate(routes.PRODUCTS);
    }
  }, [navigate, isCompleted, invite]);

  // Errors
  useEffect(() => {
    if (isError) {
      navigate(routes.PRODUCT_REG);
      dispatch(resetInviteState());
    }
  }, [dispatch, navigate, isError]);

  // Redirect to login or signup
  useEffect(() => {
    const { isSuccess, data } = accountStatus;

    // Redirect when account status has been checked
    if (!isSuccess || !invite?.customerEmail) {
      return;
    }

    if (data.accountExists) {
      loginRedirect(languageCode, invite.customerEmail, true);
    } else {
      navigate({
        pathname: routes.SIGNUP,
        search: `?email=${encodeURIComponent(invite.customerEmail)}`
      });
    }
  }, [accountStatus, languageCode, invite, dispatch, navigate]);

  // Update email field from invite
  useEffect(() => {
    if (invite) {
      setValue('email', invite?.customerEmail);
    }
  }, [invite, setValue]);

  // On register submit
  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    (values) => {
      dispatch(completeInviteStart());

      if (authenticated) {
        navigate(routes.INVITE_PRODUCTS_REG);
      } else {
        dispatch(updateInviteEmail(values.email));
        checkAccountStatus(values.email);
      }
    },
    [dispatch, navigate, checkAccountStatus, authenticated]
  );

  // Render email input
  const emailInputField = useMemo(() => {
    if (authenticated) {
      return null;
    }
    return (
      <InputField
        name="email"
        description="input.email_label"
        error={errors.email}
        register={register('email', {
          required: {
            value: true,
            message: 'input.email_required'
          },
          pattern: {
            value: mailRegex,
            message: 'input.email_invalid'
          }
        })}
        reset={resetField}
      />
    );
  }, [register, resetField, authenticated, errors]);

  // Form
  const renderForm = useCallback(
    (buttonText: string) => (
      <Form onSubmit={handleSubmit(onSubmit)}>
        {emailInputField}
        <Button submit>
          {t(authenticated ? buttonText : 'productinfo.btn_register_continue')}
        </Button>
      </Form>
    ),
    [handleSubmit, onSubmit, t, emailInputField, authenticated]
  );

  // Render PNC & Serial or Husqvarna
  const renderProductData = useCallback(
    (product: Product) => {
      if (product.husqvarnaId) {
        return (
          <DataRow>
            <DataCell name={t('productinfo.hid')}>
              {product.husqvarnaId}
            </DataCell>
          </DataRow>
        );
      }
      return (
        <DataRow>
          <DataCell name={t('productinfo.product_number')}>
            {product.pnc}
          </DataCell>
          <DataCell name={t('productinfo.serial_number')}>
            {product.serialNumber}
          </DataCell>
        </DataRow>
      );
    },
    [t]
  );

  // Empty query params
  if (!pnc && !serialNumber && !husqvarnaId && !code) {
    return <Navigate to={routes.PRODUCT_REG} />;
  }

  // Loading
  if (isLoading || !invite?.products) {
    return (
      <PageWrapper>
        <SeoHelmet title="productinfo.meta_title" />
        <Loader center size="large" />
      </PageWrapper>
    );
  }

  // Multiple products
  if (invite.products.length > 1) {
    return (
      <PageWrapper>
        <SeoHelmet title="productinfo.meta_title" />
        <CardContainer>
          <BackButton>{t('productinfo.btn_back')}</BackButton>
          <ProductCardList columns={2}>
            {invite.products.map((product) => (
              <ProductInfoCard key={product.iprId} product={product} />
            ))}
          </ProductCardList>
          {renderForm('productinfo.btn_register_products')}
        </CardContainer>
      </PageWrapper>
    );
  }

  // One product
  const [product] = invite.products;
  return (
    <ResponsiveContainer>
      <SeoHelmet title="productinfo.meta_title" />
      <ProductImage src={product.imageUrl} />
      <PageContainer>
        <Copyright>
          <BackButton backUrl={routes.PRODUCT_REG}>
            {t('productinfo.btn_back')}
          </BackButton>
          <Type>{product.productCategory?.toLowerCase() ?? '—'}</Type>
          <Heading>{product.modelName ?? '—'}</Heading>
          {product.description && (
            <Description>{product.description}</Description>
          )}
          {renderProductData(product)}
          {renderForm('productinfo.btn_register_product')}
        </Copyright>
      </PageContainer>
    </ResponsiveContainer>
  );
};

export default ProductInformationPage;
