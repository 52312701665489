import { FC, useMemo } from 'react';
import Modal from 'components/UI/Modal';
import RenderMarkdown from 'components/fragments/RenderMarkdown';
import closeIcon from 'assets/vectors/close-icon.svg';
import { ModalBody, ModalClose, ModalHeader } from './styled';

type Props = {
  isOpen: boolean;
  close: () => void;
  documentUrl: string;
};

const TermsUseModal: FC<Props> = ({ isOpen, close, documentUrl }) => {
  // Render body
  const modalBody = useMemo(() => {
    if (!documentUrl) {
      return null;
    }
    return <RenderMarkdown documentUrl={documentUrl} />;
  }, [documentUrl]);

  return (
    <Modal isOpen={isOpen} close={close} large>
      <ModalHeader>
        <ModalClose onClick={close}>
          <img src={closeIcon} alt="close" />
        </ModalClose>
      </ModalHeader>
      <ModalBody>{modalBody}</ModalBody>
    </Modal>
  );
};

export default TermsUseModal;
