import { FC, useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  buildAddress,
  getCustomerEmail,
  getCustomerPhone
} from 'utils/customerUtils';
import { useTranslations } from 'hooks/useTranslations';
import { useQuery } from 'hooks/useQuery';
import { useRegisterProfessionalProductsMutation } from 'store/productService';
import { ApiError } from 'models/errors/ApiError';

import * as routes from 'router/routes';
import Loader from 'components/UI/Loader';
import DataCell from 'components/UI/DataCell';
import PageWrapper from 'components/UI/Page/PageWrapper';
import ProductInfoCard from 'components/fragments/ProductInfoCard';
import ProductCardList from 'components/UI/Product/ProductCardList';
import Heading, { Type as HeadingType } from 'components/UI/Heading';
import { IconType } from 'components/UI/Icon';
import PageState from 'components/UI/Page/PageState';
import Button from 'components/UI/Buttons/Button';
import Copyright from 'components/UI/Copyright';

import { CardContainer, Card, LoaderText, Text, Content } from './styled';

type PageError = {
  title: string;
  description: string;
  canRetry: boolean;
};

const RegisterProfessionalProductsPage: FC = () => {
  const t = useTranslations();
  const navigate = useNavigate();

  // Hooks
  const [code] = useQuery('code');
  const [registerProduct, { data, isLoading, error }] =
    useRegisterProfessionalProductsMutation();

  // Finalize registration
  useEffect(() => {
    if (code) {
      registerProduct(code);
    }
  }, [registerProduct, code]);

  // Registration error
  const regError = useMemo<PageError | null>(() => {
    const errorCode = (error as ApiError)?.code;
    if (!code || errorCode === 'temporary.registration.not.found') {
      return {
        title: 'finalizeproductregistration.not_found_code.title',
        description: 'finalizeproductregistration.not_found_code.description',
        canRetry: false
      };
    }
    if (errorCode === 'invalid.verification.code') {
      return {
        title: 'finalizeproductregistration.invalid_code.title',
        description: 'finalizeproductregistration.invalid_code.description',
        canRetry: false
      };
    }
    if (error) {
      return {
        title: 'finalizeproductregistration.error.title',
        description: 'finalizeproductregistration.error.description',
        canRetry: true
      };
    }
    return null;
  }, [error, code]);

  // Retry
  const onRetry = useCallback(() => {
    if (code) {
      registerProduct(code);
    }
  }, [registerProduct, code]);

  // Navigate
  const onNavigate = useCallback(
    (email: string) => () => {
      navigate({
        pathname: routes.RESET_PASSWORD,
        search: `?email=${encodeURIComponent(email)}`
      });
    },
    [navigate]
  );

  // Render content
  const content = useMemo(() => {
    // Loading
    if (isLoading) {
      return (
        <PageWrapper>
          <Loader center size="large" />
          <LoaderText>
            {t('finalizeproductregistration.loading_text')}
            ...
          </LoaderText>
        </PageWrapper>
      );
    }

    // Error
    if (regError) {
      return (
        <PageState
          iconType={IconType.Error}
          title={t(regError.title)}
          description={t(regError.description)}
        >
          <Content>
            {regError.canRetry && (
              <Button onClick={onRetry}>
                {t('finalizeproductregistration.error.btn')}
              </Button>
            )}
          </Content>
        </PageState>
      );
    }

    // No data
    if (!data) {
      return null;
    }

    // Success
    const { customer, products } = data.attributes;
    const email = getCustomerEmail(customer?.eContact);

    return (
      <>
        <PageState
          iconType={IconType.Success}
          title={t('finalizeproductregistration.success.title')}
          description={t('finalizeproductregistration.success.description')}
        >
          <Button onClick={onNavigate(email)}>
            {t('finalizeproductregistration.success.btn')}
          </Button>
        </PageState>
        <CardContainer>
          {customer && (
            <Card>
              <div>
                <Text>{t('finalizeproductregistration.info.company')}</Text>
                <Heading type={HeadingType.h1}>{customer.name}</Heading>
                <Text>{buildAddress(customer.locationAddress)}</Text>
                <Text>
                  {`${customer.firstName} ${customer.lastName} (${email})`}
                </Text>
                <Text>{getCustomerPhone(customer.eContact)}</Text>
              </div>
              <DataCell
                name={t('finalizeproductregistration.info.registration_no')}
              >
                {customer.dunsNumber ?? customer.vatNumber}
              </DataCell>
            </Card>
          )}
          <Heading type={HeadingType.h3}>
            {`${t('finalizeproductregistration.info.list_title')} (${
              products.length
            })`}
          </Heading>
          <ProductCardList columns={2}>
            {products.map((product) => (
              <ProductInfoCard key={product.iprId} product={product} />
            ))}
          </ProductCardList>
        </CardContainer>
      </>
    );
  }, [onNavigate, onRetry, t, data, isLoading, regError]);

  return <Copyright>{content}</Copyright>;
};

export default RegisterProfessionalProductsPage;
