import styled from 'styled-components';

export const ImageContainer = styled.div`
  width: 13rem;
  margin: 1rem auto 1rem auto;

  > img {
    max-width: 100%;
  }
`;

export const HeadingContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  > p {
    text-align: center;
    margin-bottom: 2.5rem;
  }
`;

export const Form = styled.form`
  text-align: center;
`;
