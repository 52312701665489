import { ApiErrorCode, BAD_ARGUMENTS, ErrorCodeKey } from './errorCodes';

export type ApiError = {
  status: number;
  code: string;
  message: string;
};

export class ApiErrorDto extends Error {
  readonly status: number;
  readonly code: ErrorCodeKey;

  constructor(message: string, apiError: ApiErrorCode = BAD_ARGUMENTS) {
    super(message);
    this.status = apiError.status || BAD_ARGUMENTS.status;
    this.code = apiError.code || BAD_ARGUMENTS.code;

    Object.setPrototypeOf(this, ApiErrorDto.prototype);
  }

  public toJson(): ApiError {
    return {
      message: this.message,
      code: this.code,
      status: this.status
    };
  }

  public static fromError(e: any): ApiError {
    if (e instanceof ApiErrorDto) {
      return e.toJson();
    }

    return new ApiErrorDto(e.message, {
      code: e.code,
      status: e.status
    }).toJson();
  }
}
