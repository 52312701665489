import { FC, useCallback } from 'react';

import CheckboxVector from './CheckboxVector';
import { CheckBoxContainer, CheckBoxInput } from './styled';

type Props = {
  id?: string;
  register?: any;
  isChecked?: boolean;
  setIsChecked?: (checked: boolean) => void;
};

const Checkbox: FC<Props> = ({
  id,
  register,
  setIsChecked,
  isChecked = false
}) => {
  // Handle click
  const onCheckboxClick = useCallback(
    (e: any) => setIsChecked?.(e.target.checked),
    [setIsChecked]
  );

  return (
    <CheckBoxContainer>
      <CheckboxVector isChecked={isChecked} />
      <CheckBoxInput
        id={id}
        {...register}
        type="checkbox"
        onClick={onCheckboxClick}
        defaultChecked={isChecked}
      />
    </CheckBoxContainer>
  );
};
export default Checkbox;
