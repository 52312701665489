import { noSpecialCharsRegex } from 'utils/constants';

export function isString(input: any): input is string {
  return typeof input === 'string';
}

export function trim(input: any) {
  if (!isString(input)) {
    return '';
  }

  return input.trim();
}

export function truncate(input?: string, inputLength = 100) {
  if (!input || !isString(input)) {
    return null;
  }

  return `${input.substring(0, inputLength).trim()}\u2026`;
}

export function firstChar(input: any, defaultValue = ''): string {
  if (!isString(input)) {
    return defaultValue;
  }

  const [first = defaultValue] = input as any;
  return first;
}

export function capitalize(input: any, defaultValue = ''): string {
  if (!isString(input)) {
    return defaultValue;
  }

  return input.charAt(0).toUpperCase() + input.slice(1);
}

export function titleCase(input: string): string {
  return input.charAt(0).toUpperCase().concat(input.slice(1).toLowerCase());
}

export function lowerCaseStartsWith(subject: string, match: string) {
  return subject.toLocaleLowerCase().startsWith(match.toLocaleLowerCase());
}

export function removeTrailingSlash(url: string) {
  if (!url.endsWith('/')) {
    return url;
  }
  return url.substring(0, url.length - 1);
}

export function removeSpecialChars(input?: string | null): string {
  if (!input) {
    return '';
  }
  return input.replace(noSpecialCharsRegex, '');
}
