import { FC, ReactNode, MouseEvent } from 'react';
import { ThemeColor } from 'styles';
import { StyledButton, ButtonSize, ButtonColor } from './styled';

type Props = {
  children: ReactNode;
  submit?: boolean;
  onClick?: (event: MouseEvent) => void;
  textColor?: ThemeColor;
  color?: ButtonColor;
  size?: ButtonSize;
  disabled?: boolean;
  border?: boolean;
  fullWidth?: boolean;
};

const Button: FC<Props> = ({
  children,
  submit,
  onClick,
  textColor = 'onPrimary',
  color = 'primary',
  size = 'large',
  fullWidth = false,
  border = false,
  disabled = false
}) => {
  return (
    <StyledButton
      $size={size}
      onClick={onClick}
      $textColor={textColor}
      $color={color}
      type={submit ? 'submit' : 'button'}
      $fullWidth={fullWidth}
      $border={border}
      disabled={disabled}
    >
      {children}
    </StyledButton>
  );
};

export default Button;
