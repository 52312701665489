import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'store';

export type ScanState = {
  scanActive: boolean;
  scanResult: string;
};

export const defaultScanState: ScanState = {
  scanActive: false,
  scanResult: ''
};

// Export slice
export const scanSlice = createSlice({
  name: 'scan',
  initialState: defaultScanState,
  reducers: {
    scanResult: (state, action) => {
      state.scanResult = action.payload;
    },
    openScanner: (state) => {
      state.scanActive = true;
    },
    closeScanner: (state) => {
      state.scanActive = false;
    },
    resetScanState: () => {
      return defaultScanState;
    }
  }
});

// Export actions
export const { openScanner, closeScanner, scanResult, resetScanState } =
  scanSlice.actions;

// Export reducers
export const scanReducer = scanSlice.reducer;

// Export selector
export const scanSelector = (state: RootState) => state.scan;
