import { useEffect } from 'react';

type Props = {
  onKeyDown: () => void;
  addEventListener: boolean;
  // The keyboard (see https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key) event key that should trigger onKeyDown
  eventKey: KeyboardEvent['key'];
};

export const useKeyEffect = ({
  onKeyDown,
  addEventListener,
  eventKey
}: Props) => {
  useEffect(() => {
    const clickEvent = (event: KeyboardEvent) => {
      if (event.key === eventKey) {
        onKeyDown();
      }
    };

    if (addEventListener) {
      window.addEventListener('keydown', clickEvent);
    }

    return () => {
      window.removeEventListener('keydown', clickEvent);
    };
  }, [onKeyDown, addEventListener, eventKey]);
};
