import { useCallback } from 'react';
import { localizationSelector } from 'store/localizationState';
import { translate } from 'services/localizationService';
import { useAppSelector } from './redux';

export function useTranslations(): (
  key: string,
  parameters?: Record<string, any>
) => string {
  const { translation } = useAppSelector(localizationSelector);

  // Translate
  return useCallback(
    (key: string, parameters?: Record<string, any>) => {
      return translate(key, translation, parameters);
    },
    [translation]
  );
}
