import {
  FC,
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
  ChangeEvent
} from 'react';

import { lowerCaseStartsWith } from 'utils/stringUtils';
import { useTranslations } from 'hooks/useTranslations';
import { useDetectOutsideClick } from 'hooks/useDetectOutsideClick';
import { useKeyEffect } from 'hooks/useKeyEffect';
import ArrowIcon from 'assets/vectors/arrow-down.svg';
import CloseIcon from 'assets/vectors/close.svg';
import CheckIcon from 'assets/vectors/check.svg';

import {
  LabelFloatInput,
  LabelFloatLabel,
  LabelFloatButton,
  SelectWrapper,
  Select,
  SelectOptions,
  Option,
  Mark,
  LabelFloatMessage
} from './styled';

export type OptionType = {
  label: string;
  value: string;
};

type Props = {
  name: string;
  description: string;
  placeholder?: string;
  defaultValue?: string;
  options: OptionType[];
  register?: any;
  setValue: (name: any, value: any) => void;
  clearErrors: (name: any) => void;
  error?: any;
};

const SelectField: FC<Props> = ({
  name,
  description,
  placeholder = '',
  defaultValue,
  options,
  register,
  setValue,
  clearErrors,
  error
}) => {
  const t = useTranslations();

  // Refs
  const selectRef = useRef(null);
  const arrowRef = useRef<HTMLImageElement>(null);
  const optionsRef = useRef<HTMLDivElement>(null);
  const floatInputRef = useRef<HTMLInputElement>(null);
  const renderRef = useRef(false);

  // Creates a list of default value and options matching search
  const initList = useMemo(
    () => [
      {
        value: '',
        label: placeholder
      },
      ...options
    ],
    [placeholder, options]
  );

  // State
  const [focus, setFocus] = useState<boolean>(false);
  const [selected, setSelected] = useState<OptionType>(initList[0]);
  const [searchState, setSearchState] = useState<string>('');

  // Hooks
  const [isActive, setIsActive] = useDetectOutsideClick(
    [optionsRef, arrowRef],
    false
  );

  // Open close select
  const onOpen = useCallback(() => setIsActive(true), [setIsActive]);
  const onClose = useCallback(() => setIsActive(false), [setIsActive]);

  // Effect for closing menu on Escape press
  useKeyEffect({
    onKeyDown: onClose,
    addEventListener: isActive,
    eventKey: 'Escape'
  });

  // Search filter list
  const list = useMemo(() => {
    return initList.filter((item) => {
      return lowerCaseStartsWith(item.label, searchState);
    });
  }, [initList, searchState]);

  // Button Icon
  const buttonIcon = useMemo(() => {
    if (searchState) {
      return <img src={CloseIcon} alt="clear" />;
    }
    return <img ref={arrowRef} src={ArrowIcon} alt="toggle" />;
  }, [searchState]);

  // Set default value
  useEffect(() => {
    if (defaultValue && initList?.length) {
      const defaultOption = initList.find(({ value }) => {
        return value === defaultValue || value === defaultValue.toLowerCase();
      });
      if (defaultOption) {
        setSelected(defaultOption);
        setValue(name, defaultOption.value);
      }
    }
  }, [setValue, defaultValue, name, initList]);

  // Reset search state and scroll to top when deactivated
  useEffect(() => {
    if (!isActive && renderRef.current && floatInputRef.current) {
      setSearchState('');

      floatInputRef.current.blur();

      if (optionsRef.current) {
        optionsRef.current.scrollTop = 0;
      }
    }

    if (isActive && floatInputRef.current) {
      floatInputRef.current.focus();
    }

    renderRef.current = true;
  }, [isActive]);

  // Handle select click
  const onSelectClick = useCallback(() => {
    if (!searchState && !isActive) {
      onOpen();
    }
  }, [onOpen, searchState, isActive]);

  // Handle icon click
  const onIconClick = useCallback(() => {
    if (searchState) {
      return setSearchState('');
    }
    onClose();
  }, [onClose, searchState]);

  // Handle option click
  const onOptionClick = useCallback(
    (item: OptionType) => () => {
      setSelected(item);
      setValue(name, item.value);
      clearErrors(name);
      onClose();
    },
    [clearErrors, setValue, onClose, name]
  );

  // Handle focus
  const onFocus = useCallback(() => setFocus(true), []);
  const onBlur = useCallback(() => setFocus(false), []);

  // Handle change
  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) =>
      setSearchState(event.target.value),
    []
  );

  // Render select options
  const selectOptions = useMemo(() => {
    // Show no search match if search is active
    if (searchState && !list.length) {
      return <Option disabled>{t('input.search_no_match')}</Option>;
    }

    // Render options
    return list.map((item) => (
      <Option
        key={item.value}
        data-value={item.value}
        onClick={onOptionClick(item)}
      >
        <p>
          <Mark>{item.label.substring(0, searchState.length)}</Mark>
          {item.label.substring(searchState.length)}
        </p>
        {selected.value === item.value && <img src={CheckIcon} alt="arrow" />}
      </Option>
    ));
  }, [t, onOptionClick, list, selected, searchState]);

  return (
    <SelectWrapper>
      <Select
        ref={selectRef}
        $focus={focus}
        onClick={onSelectClick}
        $error={error}
      >
        <LabelFloatInput
          {...register}
          ref={floatInputRef}
          value={isActive ? searchState : selected.label}
          autoComplete="new-password"
          placeholder={placeholder}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          $error={error}
        />
        <LabelFloatLabel $error={error} $focus={focus}>
          {t(description)}
        </LabelFloatLabel>
        <LabelFloatButton
          type="button"
          $isActive={isActive}
          onClick={onIconClick}
          onFocus={onFocus}
          onBlur={onBlur}
        >
          {buttonIcon}
        </LabelFloatButton>
        <SelectOptions ref={optionsRef} $isActive={isActive}>
          {selectOptions}
        </SelectOptions>
      </Select>
      <LabelFloatMessage $error={error}>
        {error && <p>{t(error.message)}</p>}
      </LabelFloatMessage>
    </SelectWrapper>
  );
};

export default SelectField;
