import styled from 'styled-components';
import { breakpoints } from 'styles';

export const CardContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

export const Form = styled.form`
  width: 100%;
  margin-top: 24px;
  text-align: center;
`;

export const ResponsiveContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  height: 100%;

  @media (max-width: ${breakpoints.l}px) {
    flex-direction: column;
    width: 100%;
    height: auto;
  }
`;

export const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  div {
    margin: 0;
  }
  h4 {
    margin: 0;
  }
  > button {
    margin-right: 8px;
  }
`;

export const DataRow = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;

  > div {
    width: 100%;
  }
`;

export const Type = styled.p`
  font-size: 18px;
  line-height: 20px;
  text-transform: capitalize;
  margin: 24px 0 4px;
`;

export const PageContainer = styled.div`
  padding: 32px 64px;
  width: 80%;
  min-height: 100%;
  margin: 0 auto;
  overflow-y: scroll;
  position: relative;

  h1 {
    font-size: 44px;
    line-height: 48px;
    margin-bottom: 24px;
  }

  @media (max-width: ${breakpoints.l}px) {
    max-width: 800px;
    width: 100%;
    height: 100%;
    padding: 16px;
    overflow-y: initial;
  }
`;

export const Description = styled.p`
  width: 100%;
`;
