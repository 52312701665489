import { useState, useEffect, RefObject } from 'react';

export const useDetectOutsideClick = (
  elements: RefObject<any>[],
  initialState: boolean
): [boolean, (active: boolean) => void] => {
  const [isActive, setIsActive] = useState<boolean>(initialState);

  useEffect(() => {
    // Handle outside click
    const clickEvent = (event: Event) => {
      const nullElements = elements.filter((e) => e.current === null);

      // Check if element exist and contains target
      if (!nullElements.length) {
        const clickedElements = elements.filter((e) =>
          e.current.contains(event.target)
        );

        if (!clickedElements.length) {
          setIsActive(false);
        }
      }
    };

    // Event listener
    if (isActive) {
      window.addEventListener('click', clickEvent, true);
    }

    return () => {
      window.removeEventListener('click', clickEvent, true);
    };
  }, [isActive, elements]);

  return [isActive, setIsActive];
};
