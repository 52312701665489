import styled from 'styled-components';

export const HeadingContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  h1 {
    margin: 0;
  }

  > h6 {
    margin: 0 8px 0;
    padding: 0;
    text-align: center;
    font-size: 18px;
    font-weight: normal;
  }
`;

export const Description = styled.p`
  width: 100%;
  margin-bottom: 24px;
`;

export const LoadedText = styled.p`
  text-align: center;
  margin: 32px auto 16px;
`;

export const LoadMoreContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const ActionButtonContainer = styled.div`
  position: fixed;
  z-index: 1;
  right: 1.4rem;
  bottom: 1.4rem;
`;
