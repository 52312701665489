import styled from 'styled-components';
import { breakpoints } from 'styles';
import loginImage from 'assets/images/login.jpg';

export const PageContainer = styled.div`
  display: flex;
  height: 100%;
`;

export const ImageSection = styled.div`
  display: flex;
  align-items: end;
  width: 50%;
  background: linear-gradient(
      270deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.5) 75%,
      rgba(0, 0, 0, 0.7) 100%
    ),
    url(${loginImage});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media (max-width: ${breakpoints.l}px) {
    display: none;
  }
`;

export const ImageContent = styled.div`
  margin: 48px;
`;

export const ImageTitle = styled.h2`
  color: white;
  font-size: 40px;
  font-weight: 700;
  line-height: 42px;
  margin-bottom: 12px;
`;

export const ImageDescription = styled.p`
  max-width: 550px;
  color: white;
  margin: 0;
`;

export const ContentSection = styled.div`
  width: 50%;
  padding: 64px 8%;
  overflow-y: scroll;
  position: relative;

  @media (max-width: ${breakpoints.l}px) {
    width: 100%;
    margin: 0 auto;
  }

  @media (max-width: ${breakpoints.s}px) {
    display: block;
    padding: 16px;
  }
`;

type ContainerProps = {
  $alignTop: boolean;
};

export const FlexContainer = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${({ $alignTop }) => ($alignTop ? 'flex-start' : 'center')};
  min-height: 100%;
  width: 100%;
`;

export const ContentContainer = styled.div`
  width: 100%;
  max-width: 550px;
  margin: 0 auto;
`;
