import { FC, useCallback } from 'react';

import { activateAccount, signUpSelector } from 'store/signupState';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useTranslations } from 'hooks/useTranslations';
import { useQuery } from 'hooks/useQuery';

import VerifyAccount from 'components/fragments/VerifyAccount';
import Loader from 'components/UI/Loader';

const ActivateAccountPage: FC = () => {
  const t = useTranslations();
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector(signUpSelector);

  const [email] = useQuery('email');

  // Send activation email
  const handleSubmit = useCallback(() => {
    if (email) {
      dispatch(
        activateAccount(
          email,
          window.location.origin,
          t('activate_account_page.redirect_text')
        )
      );
    }
  }, [dispatch, t, email]);

  if (isLoading) {
    return <Loader center size="large" />;
  }

  return (
    <VerifyAccount
      title="activate_account_page.title"
      description={['activate_account_page.paragraph']}
      buttonText="activate_account_page.btn_submit"
      submitForm={handleSubmit}
      email={email}
    />
  );
};

export default ActivateAccountPage;
