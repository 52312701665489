import { FC, ReactNode, useCallback, useRef, useState } from 'react';

import ExpandIcon from 'components/UI/ExpandIcon';

import { Container, Content, ContentContainer, SectionButton } from './styled';

type Props = {
  children: ReactNode;
  title: string;
  openByDefault?: boolean;
};

const ExpandableSection: FC<Props> = ({
  children,
  title,
  openByDefault = false
}) => {
  // State
  const [isOpen, setIsOpen] = useState<boolean>(openByDefault);

  // Actions
  const toggleOpen = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  // Refs
  const contentRef = useRef<HTMLDivElement>(null);

  return (
    <Container>
      <SectionButton onClick={toggleOpen} $isOpen={isOpen}>
        {title}
        <ExpandIcon isOpen={isOpen} />
      </SectionButton>
      <ContentContainer
        initial={false}
        animate={isOpen ? 'open' : 'closed'}
        transition={{ ease: 'easeOut' }}
        variants={{
          open: {
            height: '100%',
            opacity: 1,
            transition: {
              duration: 0.2
            }
          },
          closed: {
            height: '0px',
            opacity: 0,
            transition: {
              duration: 0.2
            }
          }
        }}
      >
        <Content ref={contentRef}>{children}</Content>
      </ContentContainer>
    </Container>
  );
};

export default ExpandableSection;
