import { ReactNode, FC, useCallback } from 'react';
import { ModalBackground, ModalContainer, ModalInner } from './styled';

type Props = {
  children?: ReactNode;
  isOpen: boolean;
  close?: () => void;
  large?: boolean;
};

const Modal: FC<Props> = ({ children, isOpen, close, large = false }) => {
  const onClick = useCallback(() => close?.(), [close]);

  return (
    <>
      <ModalBackground
        initial={false}
        variants={{
          open: {
            opacity: 1,
            display: 'block'
          },
          closed: {
            opacity: 0,
            display: 'none',
            transition: {
              display: {
                delay: 0.5
              }
            }
          }
        }}
        transition={{ type: 'tween' }}
        animate={isOpen ? 'open' : 'closed'}
        onClick={onClick}
      />
      <ModalContainer
        initial={false}
        variants={{
          open: {
            opacity: 1,
            display: 'block'
          },
          closed: {
            opacity: 0,
            display: 'none',
            transition: {
              display: {
                delay: 0.5
              }
            }
          }
        }}
        transition={{ type: 'tween' }}
        animate={isOpen ? 'open' : 'closed'}
        $large={large}
      >
        <ModalInner>{children}</ModalInner>
      </ModalContainer>
    </>
  );
};

export default Modal;
