import * as config from 'config';

import { ICaptchaResponse } from 'models/captcha';
import {
  CreateCustomerAttributes,
  CustomerAccountStatus,
  ICustomerUpdate,
  ICustomerConsentType,
  ICustomerChangeEmailResponse,
  ICustomerDeleteAccountResponse,
  CustomerDto,
  CustomerResponse
} from 'models/customer';
import { JsonData } from 'models/jsonApi';
import {
  getCountryCodeOrDefault,
  getLanguageCodeOrDefault
} from 'utils/localizationUtils';
import { apiFetch, defaultHeaders } from 'utils/fetchUtils/fetchUtils';

// Public operations
export async function create(
  customer: CreateCustomerAttributes,
  captchaText: string,
  meta?: JsonData
): Promise<any> {
  const { email, phoneNumber } = customer;
  const url = `${config.backendApi}/customers`;

  // Country & language code
  const country = getCountryCodeOrDefault(customer.country);
  const language = getLanguageCodeOrDefault(customer.language);

  const json = await apiFetch<any>(url, {
    headers: defaultHeaders,
    method: 'POST',
    body: JSON.stringify({
      data: {
        type: 'customers',
        attributes: {
          ...customer,
          country,
          language,
          captchaText,
          type: 'IndividualCustomer',
          eContact: {
            emailContacts: [
              {
                emailAddress: email
              }
            ],
            cellPhoneContacts: phoneNumber
              ? [{ cellPhoneNumber: phoneNumber }]
              : undefined
          }
        }
      },
      meta
    })
  });

  return json.data;
}

export async function generateCaptcha(
  email: string
): Promise<ICaptchaResponse> {
  const url = `${config.backendApi}/captcha/generate/${encodeURIComponent(
    email
  )}`;

  return await apiFetch<ICaptchaResponse>(url, {
    headers: defaultHeaders,
    method: 'GET'
  });
}

export async function getAccountStatusByEmail(
  email: string
): Promise<CustomerAccountStatus> {
  const url = `${
    config.backendApi
  }/customers/account-status/${encodeURIComponent(email)}`;

  const json = await apiFetch<any>(url, {
    headers: defaultHeaders,
    method: 'GET'
  });

  return json.data.attributes;
}

export async function resetPasswordByEmail(
  email: string,
  languageCode: string
): Promise<void> {
  const url = `${config.backendApi}/password-recovery/${encodeURIComponent(email)}?language=${languageCode}`;

  await apiFetch(url, {
    headers: defaultHeaders,
    method: 'POST'
  });
}

export async function validateChangeEmail(
  customerId: string,
  code: string
): Promise<ICustomerChangeEmailResponse> {
  const url = `${config.backendApi}/customers/${customerId}/validate-change-email/${code}`;

  return await apiFetch(url, {
    headers: defaultHeaders,
    method: 'GET'
  });
}

export async function validateDeleteAccount(
  customerId: string,
  code: string
): Promise<ICustomerDeleteAccountResponse> {
  const url = `${config.backendApi}/customers/${customerId}/validate-delete-account/${code}`;

  return await apiFetch(url, {
    headers: defaultHeaders,
    method: 'GET'
  });
}

export async function verifyAccount(
  email: string,
  verificationCode: string
): Promise<void> {
  const url = `${config.backendApi}/customers/verify-account`;

  await apiFetch(url, {
    headers: defaultHeaders,
    method: 'POST',
    body: JSON.stringify({
      data: {
        type: 'customers',
        attributes: {
          email,
          verificationCode
        }
      }
    })
  });
}

export async function resendVerificationEmail(
  email: string,
  iprId?: string
): Promise<void> {
  const url = `${config.backendApi}/customers/resend-account-verification`;

  await apiFetch(url, {
    headers: defaultHeaders,
    method: 'POST',
    body: JSON.stringify({
      data: {
        type: 'customers',
        attributes: {
          email
        }
      },
      meta: iprId && {
        product: {
          iprId
        }
      }
    })
  });
}

export async function activateLoginAccount(
  email: string,
  uri: string,
  text: string
): Promise<void> {
  const url = `${config.backendApi}/customers/login-account-activation`;

  await apiFetch(url, {
    headers: defaultHeaders,
    method: 'POST',
    body: JSON.stringify({
      data: {
        type: 'customers',
        attributes: {
          email
        }
      },
      meta: {
        brand: 'husqvarna',
        forward: {
          uri,
          text
        }
      }
    })
  });
}

export async function getConsentType(): Promise<ICustomerConsentType> {
  const url = `${config.backendApi}/customers/consents/consenttypes`;

  const json = await apiFetch<any>(url, {
    headers: defaultHeaders,
    method: 'GET'
  });

  return {
    id: json.data.id,
    texts: json.data.attributes.texts || {}
  };
}

// Protected operations for authenticated users
export async function getCustomer(
  token: string,
  customerId: string
): Promise<CustomerDto> {
  const url = `${config.backendApi}/customers/${customerId}`;

  const json = await apiFetch<CustomerResponse>(url, {
    headers: {
      ...defaultHeaders,
      Authorization: `Bearer ${token}`
    }
  });

  return CustomerDto.fromJsonApiData(json.data);
}

export async function updateCustomer(
  customerId: string,
  newCustomer: ICustomerUpdate,
  accessToken: string
): Promise<CustomerDto> {
  const url = `${config.backendApi}/customers/${customerId}`;

  const json = await apiFetch<CustomerResponse>(url, {
    headers: {
      ...defaultHeaders,
      Authorization: `Bearer ${accessToken}`
    },
    method: 'PATCH',
    body: JSON.stringify({
      data: {
        type: 'customer',
        attributes: {
          type: 'IndividualCustomer',
          ...newCustomer
        }
      }
    })
  });

  return CustomerDto.fromJsonApiData(json.data);
}

export async function requestChangeEmail(
  customerId: string,
  newEmail: string,
  password: string,
  token: string
): Promise<void> {
  const url = `${config.backendApi}/customers/${customerId}/change-email`;

  return await apiFetch(url, {
    headers: {
      ...defaultHeaders,
      Authorization: `Bearer ${token}`
    },
    method: 'POST',
    body: JSON.stringify({
      data: {
        type: 'customer',
        attributes: {
          newEmail,
          password
        }
      },
      meta: {
        brand: 'husqvarna'
      }
    })
  });
}

export async function confirmChangeEmail(
  customerId: string,
  newEmail: string,
  code: string,
  password: string
): Promise<void> {
  const url = `${config.backendApi}/customers/${customerId}/confirm-change-email`;

  await apiFetch(url, {
    headers: {
      ...defaultHeaders
    },
    method: 'POST',
    body: JSON.stringify({
      data: {
        type: 'customer',
        attributes: {
          code,
          newEmail,
          password
        }
      }
    })
  });
}

export async function requestDeleteAccount(
  customerId: string,
  token: string
): Promise<void> {
  const url = `${config.backendApi}/customers/${customerId}/request-delete-account`;

  return await apiFetch(url, {
    headers: {
      ...defaultHeaders,
      Authorization: `Bearer ${token}`
    },
    method: 'POST',
    body: JSON.stringify({
      meta: {
        brand: 'husqvarna'
      }
    })
  });
}

export async function confirmDeleteAccount(
  customerId: string,
  code: string,
  password: string
): Promise<void> {
  const url = `${config.backendApi}/customers/${customerId}/confirm-delete-account`;

  await apiFetch(url, {
    headers: {
      ...defaultHeaders
    },
    method: 'POST',
    body: JSON.stringify({
      data: {
        type: 'customer',
        attributes: {
          code,
          password
        }
      }
    })
  });
}
