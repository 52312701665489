import { FC, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import * as markdownService from 'services/markdownService';

import { Container } from './styled';

type Props = {
  documentUrl: string;
};

const RenderMarkdown: FC<Props> = ({ documentUrl }) => {
  // State
  const [markdownSource, setMarkdownSource] = useState<string>('');

  // Fetch markdown
  useEffect(() => {
    const fetchMarkdown = async (uri: string) => {
      const markdown = await markdownService.fetchMarkdown(uri);
      setMarkdownSource(markdown);
    };
    if (documentUrl) {
      fetchMarkdown(documentUrl);
    }
  }, [documentUrl]);

  return (
    <Container>
      <ReactMarkdown remarkPlugins={[remarkGfm]}>
        {markdownSource}
      </ReactMarkdown>
    </Container>
  );
};

export default RenderMarkdown;
