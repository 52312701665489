import { FC, useEffect, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { fetchBasicData, localizationSelector } from 'store/localizationState';
import { resetSignupState, signUpSelector } from 'store/signupState';

import SeoHelmet from 'components/fragments/SeoHelmet';
import PageImageWrapper from 'components/UI/Page/PageImageWrapper';

import CredentialSignup from './CredentialSignup';
import FinalizeSignup from './FinalizeSignup';

const SignUpPage: FC = () => {
  const dispatch = useAppDispatch();
  const { isSubmitted } = useAppSelector(signUpSelector);
  const { countryOptions } = useAppSelector(localizationSelector);

  // Reset state
  useEffect(() => {
    return () => {
      dispatch(resetSignupState());
    };
  }, [dispatch]);

  // Fetch country and language options
  useEffect(() => {
    if (!countryOptions.length) {
      dispatch(fetchBasicData());
    }
  }, [dispatch, countryOptions]);

  // Content
  const content = useMemo(() => {
    if (isSubmitted) {
      return <FinalizeSignup />;
    }
    return <CredentialSignup />;
  }, [isSubmitted]);

  return (
    <PageImageWrapper alignTop>
      <SeoHelmet title="signup.meta_title" />
      {content}
    </PageImageWrapper>
  );
};

export default SignUpPage;
