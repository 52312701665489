import { FC, useCallback, useMemo } from 'react';
import closeIcon from 'assets/vectors/close-icon.svg';

import Modal from '../Modal';
import {
  ModalBody,
  ModalClose,
  ModalHeader,
  ModalMenuItem,
  ModalTitle
} from './styled';

export type MenuItem = {
  id: number;
  name: string;
  action: () => void;
};
type Props = {
  isOpen: boolean;
  close: () => void;
  title: string;
  menu: MenuItem[];
};

const MoreModal: FC<Props> = ({ isOpen, close, title, menu }) => {
  // Action
  const onClick = useCallback(
    (item: MenuItem) => () => {
      item.action();
      close();
    },
    [close]
  );

  // Menu items
  const menuItems = useMemo(() => {
    return menu.map((item) => (
      <ModalMenuItem key={item.id} onClick={onClick(item)}>
        {item.name}
      </ModalMenuItem>
    ));
  }, [menu, onClick]);

  return (
    <Modal isOpen={isOpen} close={close}>
      <ModalHeader>
        <ModalTitle>{title}</ModalTitle>
        <ModalClose onClick={close}>
          <img src={closeIcon} alt="Close" />
        </ModalClose>
      </ModalHeader>
      <ModalBody>{menuItems}</ModalBody>
    </Modal>
  );
};

export default MoreModal;
