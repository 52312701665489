import styled from 'styled-components';

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ModalTitle = styled.p`
  color: ${({ theme }) => theme.colors.subtle};
  margin: 0;
  padding: 8px 16px;
`;

export const ModalClose = styled.div`
  display: flex;
  padding: 24px 24px 16px;
  cursor: pointer;

  img {
    height: 32px;
  }
`;

export const ModalBody = styled.div`
  margin-bottom: 20px;
`;

export const ModalMenuItem = styled.div`
  font-size: 18px;
  padding: 12px 24px;
  cursor: pointer;
`;
