import styled from 'styled-components';
import { breakpoints } from 'styles';

export const ResponsiveContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row-reverse;
  background: linear-gradient(#f4f4f3, #ffffff 512px);
  overflow-y: hidden;

  @media (max-width: ${breakpoints.l}px) {
    width: 100%;
    flex-direction: column;
    overflow-y: scroll;
  }
`;

export const Type = styled.p`
  font-size: 18px;
  line-height: 20px;
  text-transform: capitalize;
  margin: 24px 0 4px;
`;

export const PageContainer = styled.div`
  width: 80%;
  padding: 32px 36px;
  overflow-y: scroll;
  max-width: 800px;

  h1 {
    font-size: 44px;
    line-height: 48px;
  }

  @media (max-width: ${breakpoints.l}px) {
    max-width: 800px;
    width: 100%;
    height: 100%;
    padding: 16px;
    margin: 0 auto;
    overflow-y: visible;
  }
`;

export const Container = styled.div`
  padding-bottom: 48px;
`;

export const ProductHeader = styled.div`
  margin: 24px 12px;
`;

export const ProductDescription = styled.div`
  margin: 0 12px;
`;

export const Description = styled.p`
  width: 100%;
  margin: 16px 0;
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 32px 0;
`;

export const ProductOffer = styled.div`
  background: ${({ theme }) => theme.colors.background};
  margin: 32px 0;
  padding: 24px;
  border-radius: 32px;

  p {
    margin-bottom: 16px;
  }

  button {
    width: 100%;
  }
`;
