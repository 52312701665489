import { FC, ReactNode } from 'react';
import { useTranslations } from 'hooks/useTranslations';
import husqvarnaGroupLogo from 'assets/vectors/hq-logo-group.svg';
import { Footer, Logo, Text, Content, Links, Link } from './styled';
import { externalFooterLinks } from 'utils/constants';

type Props = {
  children: ReactNode;
  fullHeight?: boolean;
};

const Copyright: FC<Props> = ({ children, fullHeight = false }) => {
  const t = useTranslations();

  return (
    <>
      <Content $fullHeight={fullHeight}>{children}</Content>
      <Footer>
        <Logo src={husqvarnaGroupLogo} alt="husqvarna group" />
        <Text>{t('login.hq_group_text')}</Text>
        <Links>
          {externalFooterLinks.map(({ link, name }) => (
            <Link
              key={name}
              href={link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t(name)}
            </Link>
          ))}
        </Links>
      </Footer>
    </>
  );
};

export default Copyright;
