import styled from 'styled-components';

type Props = {
  $border?: boolean;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 20px;
`;

export const Row = styled.div<Props>`
  display: flex;
  align-items: center;
  border-bottom: ${({ theme, $border }) =>
    $border ? `1px solid ${theme.colors.grey2}` : 'none'};
  padding: 16px 0;
  cursor: pointer;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  p {
    font-size: 16px;
    margin: 0;
  }
`;

export const ArrowIcon = styled.img`
  height: 16px;
`;
