import { FC, ReactNode } from 'react';

export enum Type {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6
}

type Props = {
  type?: Type;
  children: ReactNode;
};

const renderHeadingType = (type: Type, text: ReactNode) => {
  switch (type) {
    case Type.h6: {
      return <h6>{text}</h6>;
    }
    case Type.h5: {
      return <h5>{text}</h5>;
    }
    case Type.h4: {
      return <h4>{text}</h4>;
    }
    case Type.h3: {
      return <h3>{text}</h3>;
    }
    case Type.h2: {
      return <h2>{text}</h2>;
    }
    default: {
      return <h1>{text}</h1>;
    }
  }
};

const Heading: FC<Props> = ({ type = Type.h1, children }) => {
  return renderHeadingType(type, children);
};

export default Heading;
