import styled from 'styled-components';
import { ThemeColor } from 'styles';

type TextProps = {
  color: ThemeColor;
};

export const Text = styled.p<TextProps>`
  font-size: 16px;
  color: ${({ theme, color }) => theme.colors[color]};
`;

export const Info = styled.div`
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.error};
  color: ${({ theme }) => theme.colors.onPrimary};
  border-radius: 4px;
  margin-bottom: 16px;

  p:last-child {
    margin-bottom: 0px;
  }
`;

export const Group = styled.li`
  font-size: 16px;
  font-weight: bold;
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    min-width: auto;
  }
`;

export const LoaderWrapper = styled.div`
  margin-bottom: 16px;
`;
