import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { breakpoints } from 'styles';

export const SelectButtonGrid = styled.div`
  display: flex;
  gap: 16px;
  margin: 8px 0 32px;
`;

export const Form = styled.form`
  margin: 1rem 0 0 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1 1 auto;
`;

export const FormText = styled.p`
  font-size: 16px;
  line-height: 24px;

  @media (max-width: ${breakpoints.s}px) {
    padding: 0;
  }

  > a {
    color: inherit;
    text-decoration: underline;
  }
`;

export const TextAction = styled.span`
  color: inherit;
  text-decoration: underline;
  cursor: pointer;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 16px 0 48px;
  gap: 16px;
`;

export const MessageLink = styled(Link)`
  color: ${({ theme }) => theme.colors.primary};
  padding: 0 4px;
  text-decoration: underline;
  font-weight: bold;
`;

export const CheckBoxContainer = styled.label`
  display: flex;
  gap: 16px;
  cursor: pointer;

  p {
    font-size: 1.1rem;
    padding: 0;
  }
`;

export const CaptchaContainer = styled.div`
  margin-bottom: 24px;
`;

export const CaptchaCode = styled.img`
  width: 100%;
  max-width: 250px;
  height: auto;
`;

export const Card = styled.div`
  background-color: ${({ theme }) => theme.colors.surface};
  color: ${({ theme }) => theme.colors.onSurface};
  border-radius: 32px;
  padding: 20px;

  h2 {
    margin-bottom: 16px;
  }

  p {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const ExternalLink = styled.a`
  display: flex;
  gap: 8px;
  color: ${({ theme }) => theme.colors.grey6};
  font-family: Roboto, Arial, sans-serif;
  text-decoration: underline;
  font-style: normal;
  font-weight: 350;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.4px;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
`;
