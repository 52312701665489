import { PhoneNumber } from './phoneNumber';

export enum DiamGroups {
  MyPages = 'husqvarna-mypages'
}

export type CustomerUpdateForm = {
  firstName: string;
  lastName: string;
  email: string;
  dialCode: string;
  phoneNumber?: string;
  language?: string;
  country?: string;
  locationAddress: {
    street?: string;
    zipCode?: string;
    city?: string;
  };
  billingAddress: {
    street?: string;
    zipCode?: string;
    city?: string;
  };
  sameAsLocationAddress?: boolean;
};

export type CustomerAccountStatus = {
  accountExists: boolean;
  emailVerified: boolean;
};

export type DIAMGroup = {
  groupId: string;
  name: string;
  groupType: string;
};

export type CreateCustomerAttributes = {
  firstName: string;
  lastName: string;
  country: string;
  language: string;
  phoneNumber?: string;
  email: string;
  password: string;
  locationAddress: Address;
  billingAddress: Address;
  shippingAddress: Address;
  groups: string[];
};

export type CustomerConsentStatus = 'ACCEPTED' | 'DENIED';

export type CustomerConsentQuery = {
  customerId?: string | null;
  consentTypeId?: string;
  body?: {
    status: CustomerConsentStatus;
  };
};

export type CustomerConsentTypeText = {
  title: string;
  text: string;
};

export type CustomerConsentTranslationText = {
  [languageCode: string]: CustomerConsentTypeText;
};

export type CustomerConsentAttributes = {
  customerId: string;
  consentTypeId: string;
  status: CustomerConsentStatus | null;
  timestamp: number | null;
  texts: CustomerConsentTranslationText;
};

export type CustomerConsent = {
  id: string;
  type: string;
  attributes: CustomerConsentAttributes;
};

export type CustomerConsentResponse = {
  data: CustomerConsent[];
};

export type CustomerConsentUpdateResponse = {
  data: {
    id: string;
    type: string;
    attributes: {
      status: string;
    };
  };
};

export type ConsentType = {
  consentTypeId: string;
  status: CustomerConsentStatus;
};

export type CustomerToken = {
  data: {
    id: string;
    attributes: {
      customerId: string;
      refreshToken: string;
      expiresIn: number;
    };
  };
};

export type Address = {
  city?: string;
  street?: string;
  zipCode?: string;
  country?: string;
};

export type IAMUser = {
  groups: string[];
};

export type EContactEmail = {
  emailAddress: string;
};

export type EContactPhone = {
  cellPhoneNumber: string;
};

export type EContact = {
  emailContacts: EContactEmail[];
  cellPhoneContacts: EContactPhone[];
};

export enum CustomerType {
  IndividualCustomer = 'IndividualCustomer',
  OrganizationCustomer = 'OrganizationCustomer'
}

export type CustomerAttributes = {
  type: CustomerType;
  firstName: string;
  lastName: string;
  name?: string;
  country: string;
  language: string;
  vatNumber?: string;
  dunsNumber?: string;
  locationAddress?: Address;
  billingAddress?: Address;
  shippingAddress?: Address;
  eContact?: EContact;
  users: IAMUser[];
};

export type CustomerResponseData = {
  id: string;
  attributes: CustomerAttributes;
};

export type CustomerResponse = {
  data: CustomerResponseData;
};

export interface CustomerObj extends CustomerAttributes {
  id: string;
}

export type Customer = {
  id: string;
  type: CustomerType;
  firstName: string;
  lastName: string;
  name?: string;
  email: string;
  language?: string;
  dunsNumber?: string;
  vatNumber?: string;
  country?: string;
  locationAddress?: Address;
  billingAddress?: Address;
  shippingAddress?: Address;
  cellPhoneNumber?: PhoneNumber;
  groups: string[];
};

export class CustomerDto implements Customer {
  readonly id: string;
  readonly type: CustomerType;
  readonly firstName: string;
  readonly lastName: string;
  readonly name?: string;
  readonly email: string;
  readonly language?: string;
  readonly dunsNumber?: string;
  readonly vatNumber?: string;
  readonly country?: string;
  readonly locationAddress?: Address;
  readonly billingAddress?: Address;
  readonly shippingAddress?: Address;
  readonly cellPhoneNumber?: PhoneNumber;
  readonly groups: string[];

  constructor(obj: CustomerObj) {
    this.id = obj.id;
    this.type = obj.type;
    this.firstName = obj.firstName;
    this.lastName = obj.lastName;
    this.name = obj.name;
    this.country = obj.country;
    this.language = obj.language;
    this.dunsNumber = obj.dunsNumber;
    this.vatNumber = obj.vatNumber;
    this.locationAddress = obj.locationAddress ?? undefined;
    this.billingAddress = obj.billingAddress ?? undefined;
    this.shippingAddress = obj.shippingAddress ?? undefined;
    this.groups = obj.users?.[0].groups;
    this.email = '';

    if (obj.eContact) {
      const { emailContacts, cellPhoneContacts } = obj.eContact;

      if (emailContacts.length > 0) {
        this.email = emailContacts[0].emailAddress || '';
      }

      this.cellPhoneNumber = {
        ...PhoneNumber.parse(cellPhoneContacts[0]?.cellPhoneNumber)
      };
    }
  }

  public isOfGroup(group: string) {
    return this.groups.includes(group);
  }

  public toJson(): Customer {
    return {
      id: this.id,
      type: this.type,
      firstName: this.firstName,
      lastName: this.lastName,
      name: this.name,
      email: this.email,
      dunsNumber: this.dunsNumber,
      vatNumber: this.vatNumber,
      country: this.country,
      language: this.language,
      locationAddress: this.locationAddress,
      billingAddress: this.billingAddress,
      shippingAddress: this.shippingAddress,
      cellPhoneNumber: this.cellPhoneNumber,
      groups: this.groups
    };
  }

  public static fromJsonApiData({ id, attributes }: CustomerResponseData) {
    return new CustomerDto({ id, ...attributes });
  }
}

export interface ICustomerUpdate {
  firstName?: string;
  lastName?: string;
  billingAddress?: Address;
  locationAddress?: Address;
  shippingAddress?: Address;
  country?: string;
  language?: string;
  eContact?: {
    cellPhoneContacts?: [
      {
        cellPhoneNumber: string;
        isVerified: boolean;
      }
    ];
  };
}

export interface ICustomerConsentType {
  id: string;
  texts: any;
}

export interface ICustomerChangeEmailAttributes {
  groups: DIAMGroup[];
  iamUserId: string;
  newEmail: string;
}

export interface ICustomerChangeEmailResponse {
  data: {
    type: 'customer';
    id: string;
    attributes: ICustomerChangeEmailAttributes;
  };
}

export interface ICustomerDeleteAccountAttributes {
  groups: DIAMGroup[];
  email: string;
}

export interface ICustomerDeleteAccountResponse {
  data: {
    type: 'customer';
    id: string;
    attributes: ICustomerDeleteAccountAttributes;
  };
}
