import {
  MiddlewareAPI,
  isRejectedWithValue,
  Middleware
} from '@reduxjs/toolkit';
import {
  BaseQueryApi,
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError
} from '@reduxjs/toolkit/query/react';
import { ApiError, ApiErrorDto } from 'models/errors/ApiError';
import { displayApiError } from 'store/globalErrorState';
import { logoutSuccess } from 'store/authState';
import { RootState, store } from 'store';
import * as config from 'config';

export const defaultHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json'
};

/**
 * Parse errors
 */
export function parseJsonError(json: any, fallbackText?: string): ApiError {
  if (Array.isArray(json.errors)) {
    // Assume JSON:API standard error
    const [first] = json.errors;

    // Check if token expired
    if (first.status === 403 || first.status === 401) {
      store.dispatch(logoutSuccess());
    }

    return new ApiErrorDto(first.title, {
      code: first.code,
      status: first.status
    }).toJson();
  }
  console.warn('Unknown error response:', json);
  return new ApiErrorDto(json.message ?? fallbackText).toJson();
}

/**
 * Prepare RTK Query headers
 */
export function prepareHeaders(
  headers: Headers,
  api: Pick<BaseQueryApi, 'getState'>
) {
  const state = api.getState() as RootState;
  const token = state.auth.session.accessToken;

  // If we have a token set in state, let's assume that we should be passing it.
  if (token) {
    headers.set('Authorization', `Bearer ${token}`);
  }

  headers.set('Content-Type', 'application/json');

  return headers;
}

/**
 * Base query
 */
const baseQuery = fetchBaseQuery({
  baseUrl: config.backendApi,
  prepareHeaders
});

/**
 * Reauth base query
 */
export const baseQueryAuth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const res = await baseQuery(args, api, extraOptions);
  const errorStatus = res.error?.status;

  // Expired token error
  if (errorStatus === 401 || errorStatus === 403) {
    api.dispatch(logoutSuccess());
  }

  return res;
};

/**
 * Log errors and display toasts
 */
export const rtkQueryError: Middleware =
  (api: MiddlewareAPI) => (next) => (action: any) => {
    if (isRejectedWithValue(action)) {
      const errors = action.payload?.data?.errors;
      if (errors?.length) {
        errors.forEach((error: any) => {
          api.dispatch(
            displayApiError(
              new ApiErrorDto(error.title, {
                code: error.code,
                status: error.status
              }).toJson()
            )
          );
        });
      }
    }
    return next(action);
  };

export function createFilterUrl<T extends Record<string, string | null>>(
  url: string,
  filterObj: T
): string {
  const filters = Object.entries(filterObj).filter(
    ([_, value]) => value?.length
  );

  filters.forEach(([key, value], i) => {
    if (!value) {
      return;
    }

    if (i === 0) {
      url += '?';
    }

    url += `filter[${key}]=${encodeURIComponent(value)}`;

    if (filters.length !== i + 1) {
      url += '&';
    }
  });

  return url;
}

export async function apiFetch<T>(
  url: string,
  options: RequestInit = {}
): Promise<T> {
  // Fetch
  const res = await fetch(url, options);

  const text = await res.text();

  // No response body
  if (!text) {
    return null as unknown as T;
  }

  // JSON
  const json = await JSON.parse(text);

  if (!res.ok) {
    console.warn(
      'Failed - statusText: %s, status: %d',
      res.statusText,
      res.status
    );

    throw parseJsonError(json, res.statusText);
  }

  return json;
}
