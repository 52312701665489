export function setItem<T>(key: string, value: T): T {
  localStorage.setItem(key, JSON.stringify(value));
  return value;
}

export function getItem<T>(key: string) {
  const item = localStorage.getItem(key);
  if (!item) {
    return null;
  }
  return JSON.parse(item) as T;
}

export function removeItem(key: string) {
  localStorage.removeItem(key);
}

export function clear() {
  localStorage.clear();
}
