import { ReactNode, FC } from 'react';
import HamburgerButton from 'components/UI/Buttons/HamburgerButton';
import {
  DrawerBackground,
  DrawerContainer,
  DrawerBody,
  DrawerHeader
} from './styled';

type Props = {
  title?: string;
  children?: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  position?: 'left' | 'right';
};

const Drawer: FC<Props> = ({
  onClose,
  isOpen,
  title,
  children,
  position = 'left'
}) => {
  return (
    <>
      <DrawerBackground
        data-testid="drawer-background"
        initial={false}
        transition={{ type: 'tween' }}
        variants={{
          open: {
            opacity: 1,
            display: 'block'
          },
          closed: {
            opacity: 0,
            display: 'none'
          }
        }}
        animate={isOpen ? 'open' : 'closed'}
        onClick={onClose}
      />
      <DrawerContainer
        transition={{ type: 'tween' }}
        initial={false}
        variants={{
          open: {
            right: position === 'right' ? 0 : 'unset',
            left: position === 'left' ? 0 : 'unset'
          },
          closed: {
            right: position === 'right' ? '-100%' : 'unset',
            left: position === 'left' ? '-100%' : 'unset'
          }
        }}
        animate={isOpen ? 'open' : 'closed'}
        position={position}
      >
        {title && (
          <DrawerHeader>
            <h3>{title}</h3>
            <HamburgerButton setIsOpen={onClose} isOpen={isOpen} />
          </DrawerHeader>
        )}
        <DrawerBody>{children}</DrawerBody>
      </DrawerContainer>
    </>
  );
};

export default Drawer;
