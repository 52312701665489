import { FC } from 'react';
import { IconContainer, StyledGroup, StyledPath } from './styled';

export enum IconType {
  Home = 'HOME',
  Logout = 'LOGOUT',
  Products = 'PRODUCTS',
  Profile = 'PROFILE',
  Support = 'SUPPORT',
  Search = 'SEARCH',
  Login = 'LOGIN',
  SignUp = 'SIGN_UP',
  Success = 'SUCCESS',
  Error = 'ERROR',
  Maintenance = 'MAINTENANCE',
  Inbox = 'INBOX',
  Notification = 'NOTIFICATION',
  Register = 'REGISTER',
  ExternalLink = 'EXTERNALLINK',
  Document = 'DOCUMENT'
}

const renderIcon = (type: IconType, isActive: boolean) => {
  switch (type) {
    case IconType.Home: {
      return (
        <g data-testid="svg-home">
          <g>
            <path
              d="M0 0H23.486V24H0z"
              transform="translate(-116 -127) translate(116.657 127)"
            />
            <StyledPath
              $isActive={isActive}
              d="M2.166 8.85l9.296-6.65c.169-.12.393-.12.561 0l9.297 6.65c.13.094.209.247.209.41V21.5c0 .276-.22.5-.49.5H13.7v-7H9.786v7h-7.34c-.27 0-.489-.224-.489-.5V9.26c0-.163.078-.316.209-.41z"
              transform="translate(-116 -127) translate(116.657 127)"
            />
          </g>
        </g>
      );
    }
    case IconType.Products: {
      return (
        <StyledGroup $isActive={isActive} data-testid="svg-products">
          <g>
            <path
              d="M10.264 13c.276 0 .5.224.5.5v8c0 .276-.224.5-.5.5H2.457c-.276 0-.5-.224-.5-.5v-8c0-.276.224-.5.5-.5h7.807zm10.765 0c.276 0 .5.224.5.5v8c0 .276-.224.5-.5.5H13.22c-.276 0-.5-.224-.5-.5v-8c0-.276.224-.5.5-.5h7.808zM10.264 2c.276 0 .5.224.5.5v8c0 .276-.224.5-.5.5H2.457c-.276 0-.5-.224-.5-.5v-8c0-.276.224-.5.5-.5h7.807zM21.03 2c.276 0 .5.224.5.5v8c0 .276-.224.5-.5.5H13.22c-.276 0-.5-.224-.5-.5v-8c0-.276.224-.5.5-.5h7.808z"
              transform="translate(-116 -175) translate(116.657 175)"
            />
          </g>
        </StyledGroup>
      );
    }
    case IconType.Profile: {
      return (
        <g data-testid="svg-profile">
          <g>
            <path
              d="M0 0H23.486V24H0z"
              transform="translate(-116 -223) translate(116.657 223)"
            />
            <StyledPath
              $isActive={isActive}
              d="M11.743 14c3.914 0 6.85 1.5 8.807 3 .596 1.217.92 2.712.971 4.485.008.276-.209.507-.485.515H2.464c-.276 0-.5-.224-.5-.5v-.015c.052-1.773.376-3.268.972-4.485 1.875-1.533 4.893-3 8.807-3zm0-12c3.243 0 5.871 2.686 5.871 6s-2.628 6-5.871 6C8.5 14 5.87 11.314 5.87 8s2.63-6 5.872-6z"
              transform="translate(-116 -223) translate(116.657 223)"
            />
          </g>
        </g>
      );
    }
    case IconType.Support: {
      return (
        <g data-testid="svg-support">
          <g>
            <path
              d="M0 0H23.486V24H0z"
              transform="translate(-116 -271) translate(116.657 271)"
            />
            <StyledPath
              $isActive={isActive}
              d="M21.029 8c.276 0 .5.224.5.5v10c0 .276-.224.5-.5.5H19.57v3l-4.892-3h-6.35c-.277 0-.5-.224-.5-.5v-3.166h1.957v1.667L14.679 17l2.935 2v-2h1.957v-7h-1.957V8zM2.457 14c-.276 0-.5-.224-.5-.5v-11c0-.276.224-.5.5-.5h12.7c.276 0 .5.224.5.5v11c0 .276-.224.5-.5.5H6.85l-2.936 2v-2H2.457zm6.821-2c.447 0 .808-.37.808-.826 0-.456-.361-.826-.808-.826-.446 0-.808.37-.808.826 0 .456.362.826.808.826zm.618-2.402h-1.35C8.542 8.62 8.56 8.19 9.51 7.3c.385-.36.69-.645.648-1.206-.04-.533-.492-.812-.92-.812-.477 0-1.034.349-1.034 1.333H6.85C6.85 5.026 7.799 4 9.267 4c.697 0 1.307.224 1.718.632.383.383.582.909.575 1.522-.012.917-.579 1.518-1.035 2.001-.608.646-.637 1.003-.63 1.443z"
              transform="translate(-116 -271) translate(116.657 271)"
            />
          </g>
        </g>
      );
    }
    case IconType.Search: {
      return (
        <StyledPath
          $isActive={isActive}
          d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0016 9.5 6.5 6.5 0 109.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
          data-testid="svg-search"
        />
      );
    }
    case IconType.Login: {
      return (
        <StyledPath
          $isActive={isActive}
          d="M11 7L9.6 8.4l2.6 2.6H2v2h10.2l-2.6 2.6L11 17l5-5-5-5zm9 12h-8v2h8c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-8v2h8v14z"
          data-testid="svg-login"
        />
      );
    }
    case IconType.SignUp: {
      return (
        <StyledPath
          $isActive={isActive}
          d="M15 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm-9-2V7H4v3H1v2h3v3h2v-3h3v-2H6zm9 4c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"
          data-testid="svg-signup"
        />
      );
    }
    case IconType.Success: {
      return (
        <g fill="none" fillRule="evenodd" data-testid="svg-success">
          <path d="M0 0h24v24H0z" />
          <path
            d="M12.188.75c6.109 0 11.062 4.953 11.062 11.063 0 6.109-4.953 11.062-11.063 11.062-6.109 0-11.062-4.953-11.062-11.063C1.125 5.704 6.078.75 12.188.75Zm0 2.011a9.052 9.052 0 1 0 0 18.104 9.052 9.052 0 0 0 0-18.104Zm5.907 4.918.71.71a.501.501 0 0 1 0 .711l-7.821 7.822a.503.503 0 0 1-.642.059l-.07-.059-4.265-4.266a.501.501 0 0 1 0-.71l.71-.712a.501.501 0 0 1 .712 0l3.2 3.2 6.755-6.756a.503.503 0 0 1 .71 0v.001Z"
            fill="#449C25"
            fillRule="nonzero"
          />
        </g>
      );
    }
    case IconType.Error: {
      return (
        <path
          d="M0 12c0 6.627 5.373 12 12 12s12-5.373 12-12S18.627 0 12 0 0 5.373 0 12Zm21.818 0c0 5.422-4.396 9.818-9.818 9.818S2.182 17.422 2.182 12 6.578 2.182 12 2.182 21.818 6.578 21.818 12Zm-6.347-5.014a.545.545 0 0 1 .772 0l.771.771a.545.545 0 0 1 0 .772L13.543 12l3.471 3.471a.545.545 0 0 1 0 .772l-.771.771a.545.545 0 0 1-.772 0L12 13.543l-3.471 3.471a.545.545 0 0 1-.772 0l-.771-.771a.545.545 0 0 1 0-.772L10.457 12 6.986 8.529a.545.545 0 0 1 0-.772l.771-.771a.545.545 0 0 1 .772 0L12 10.457l3.471-3.471Z"
          fill="#DC2832"
          fillRule="evenodd"
          data-testid="svg-error"
        />
      );
    }
    case IconType.Maintenance: {
      return (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.12773 7.6836L2.31109 4.87C1.94257 5.81227 1.90125 6.85313 2.18828 7.81727L5.22719 10.8575C6.42498 11.2142 7.74094 11.0642 8.83838 10.4075L9.2212 10.8138L10.8497 9.27717L10.3974 8.85212C11.0593 7.75178 11.211 6.43032 10.8533 5.22836L7.8143 2.1882C6.85086 1.90133 5.81078 1.94242 4.86891 2.31141L7.68493 5.12508L5.12773 7.6836ZM18.8521 7.6836L21.6669 4.87C22.036 5.81227 22.0767 6.85313 21.7903 7.81727L18.7515 10.8575C17.5528 11.2144 16.2359 11.064 15.1378 10.4062L4.6892 21.5316C4.68553 21.5358 4.68139 21.5402 4.67717 21.5445L4.67475 21.5469C4.67358 21.5477 4.67233 21.5484 4.67178 21.5493C4.38959 21.8277 4.00248 22 3.57537 22C2.71248 22 2.01334 21.3005 2.01334 20.4374C2.01334 20.0166 2.18045 19.6353 2.45068 19.3545C2.45291 19.3521 2.45494 19.3497 2.457 19.3472C2.45884 19.345 2.46069 19.3428 2.46271 19.3406L2.46748 19.3366C2.47257 19.331 2.47851 19.3256 2.48457 19.3201L2.48787 19.3171L13.579 8.84908C12.9188 7.74935 12.7674 6.42921 13.1254 5.22836L16.1642 2.1882C17.1276 1.90133 18.1678 1.94242 19.1097 2.31141L16.2948 5.12508L18.8521 7.6836ZM19.2601 21.4586L13.0678 14.8922L14.8282 13.0172L21.4571 19.2481C21.7896 19.5346 22 19.959 22 20.4325C22 21.2957 21.3015 21.9952 20.4387 21.9952C19.9684 21.9952 19.5466 21.7874 19.2601 21.4586Z"
          fill="#3D3D3C"
        />
      );
    }
    case IconType.Inbox: {
      return (
        <path
          d="M2.0004 24C1.4664 24 0.9996 23.7996 0.6 23.4C0.2004 23.0004 0 22.5336 0 21.9996V2.0004C0 1.4664 0.2004 0.9996 0.6 0.6C0.9996 0.2004 1.4664 0 2.0004 0H21.9996C22.5336 0 23.0004 0.2004 23.4 0.6C23.7996 0.9996 24 1.4664 24 2.0004V21.9996C24 22.5336 23.7996 23.0004 23.4 23.4C23.0004 23.7996 22.5336 24 21.9996 24H2.0004ZM2.0004 21.9996H21.9996V17.4672H17.1336C16.5771 18.3365 15.8009 19.0436 14.8836 19.5168C13.9947 19.9858 13.005 20.2313 12 20.232C11.0004 20.232 10.0392 19.9932 9.1164 19.5156C8.19906 19.0424 7.42291 18.3353 6.8664 17.466H2.0004V21.9996ZM12.0084 18.2328C12.8904 18.2406 13.7519 17.9665 14.4672 17.4504C15.2116 16.9161 15.8453 16.2425 16.3332 15.4668H21.9996V2.0004H2.0004V15.4668H7.6668C8.15612 16.244 8.7929 16.9179 9.5412 17.4504C10.26 17.9659 11.1239 18.2399 12.0084 18.2328Z"
          fill="#3D3D3C"
          fillRule="nonzero"
        />
      );
    }
    case IconType.Notification: {
      return (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 2.5C10 2.22386 10.2239 2 10.5 2H13.5C13.7761 2 14 2.22386 14 2.5V3.34426C16.3156 4.16953 17.9788 6.38746 17.9998 9C18 12.5405 18.2639 15.2192 20.7918 17.0361C20.9225 17.1301 21 17.2812 21 17.4421V18.5C21 18.7761 20.7761 19 20.5 19H15C15 20.6569 13.6569 22 12 22C10.3431 22 9 20.6569 9 19H3.5C3.22386 19 3 18.7761 3 18.5V17.4421C3.00003 17.2811 3.07751 17.13 3.2082 17.0361C5.73607 15.2192 6 12.5405 6 9H6.0002C6.02117 6.38746 7.68437 4.16953 10 3.34426V2.5ZM12 5C14.189 5 15.9732 6.78269 15.9997 9H8.0003C8.02676 6.78269 9.81102 5 12 5ZM7.87172 11C7.80989 11.4645 7.76476 11.9159 7.72099 12.3539C7.5432 14.1325 7.38757 15.6894 6.22472 17H17.7753C16.6124 15.6894 16.4568 14.1325 16.279 12.3538L16.279 12.3538C16.2352 11.9158 16.1901 11.4645 16.1283 11H7.87172Z"
          fill="#3D3D3C"
        />
      );
    }
    case IconType.Register: {
      return (
        <g fill="none" fillRule="evenodd">
          <path
            d="M22.692 12.335 19.52 9.118a1.04 1.04 0 0 0-1.485 0l-.634.643V2.065C17.4 1.477 16.93 1 16.35 1H3.05C2.47 1 2 1.477 2 2.065v17.032c0 .588.47 1.064 1.05 1.064H9v1.774C9 22.523 9.47 23 10.05 23h3.173c.279 0 .546-.112.743-.312l8.726-8.847c.41-.416.41-1.09 0-1.506ZM4.1 3.13h11.2v8.761l-5.992 6.075a1.05 1.05 0 0 0-.06.067H4.1V3.13Zm8.688 17.742H11.1v-1.712l7.677-7.783 1.688 1.712-7.677 7.783Z"
            fill="#3D3D3C"
            fillRule="nonzero"
          />
          <path d="M0 0h24v24H0z" />
        </g>
      );
    }
    case IconType.ExternalLink: {
      return (
        <g fill="none" fillRule="evenodd">
          <path
            d="M18.176 11.784 19.758 4l-7.834 1.31 2.344 2.428-3.924 3.789 1.563 1.618 3.924-3.789 2.345 2.428ZM6 6.763h5v2H7v8h8v-4h2v5a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1v-10a1 1 0 0 1 1-1Z"
            fill="#3D3D3C"
          />
          <path d="M0 0h24v24H0z" />
        </g>
      );
    }
    case IconType.Document: {
      return (
        <path
          d="M5 6v13h13V6H5zm6.5-4c1.524 0 2.82.835 3.3 2H16v2h1V4h2.5a.5.5 0 0 1 .5.5v16a.5.5 0 0 1-.5.5h-16a.5.5 0 0 1-.5-.5v-16a.5.5 0 0 1 .5-.5H6v2h1V4h1.2c.459-1.114 1.664-1.926 3.101-1.995zm0 1a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1zM7 9h9v2H7V9zm0 4h6v2H7v-2z"
          fill="#201E1E"
          fillRule="evenodd"
        />
      );
    }
    default: {
      return (
        <g data-testid="svg-logout">
          <g>
            <path
              d="M0 0H23.486V24H0z"
              transform="translate(-116 -319) translate(116.657 319)"
            />
            <StyledPath
              $isActive={isActive}
              d="M17.114 3c.276 0 .5.224.5.5v3c0 .276-.224.5-.5.5h-.957c-.276 0-.5-.224-.5-.5V5H7.83v14h7.828v-1.5c0-.276.224-.5.5-.5h.957c.276 0 .5.224.5.5v3c0 .276-.224.5-.5.5H6.371c-.276 0-.5-.224-.5-.5v-17c0-.276.224-.5.5-.5h10.743zm.99 6.5c.105 0 .208.035.293.1l2.61 2c.216.166.26.48.098.7-.028.038-.061.072-.098.1l-2.61 2c-.216.166-.523.12-.685-.1-.063-.087-.098-.192-.098-.3v-1h-6.359c-.276 0-.49-.226-.49-.505v-.99c0-.291.219-.505.49-.505h6.36v-1c0-.276.218-.5.489-.5z"
              transform="translate(-116 -319) translate(116.657 319)"
            />
          </g>
        </g>
      );
    }
  }
};

type Props = {
  type: IconType;
  active?: boolean;
  size?: number;
};

const Icon: FC<Props> = ({ type, active = false, size = 24 }) => {
  return (
    <IconContainer>
      <svg width={size} height={size} viewBox="0 0 24 24">
        <g fill="none" fillRule="evenodd">
          {renderIcon(type, active)}
        </g>
      </svg>
    </IconContainer>
  );
};

export default Icon;
