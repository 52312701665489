import { motion } from 'framer-motion';
import styled from 'styled-components';

type RootProps = {
  $defaultIsOpen: boolean;
};
export const Root = styled(motion.div)<RootProps>`
  position: relative;
  height: ${({ $defaultIsOpen }) => ($defaultIsOpen ? 'auto' : '0')};
  opacity: ${({ $defaultIsOpen }) => ($defaultIsOpen ? '1' : '0')};
`;
