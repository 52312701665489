import data from 'assets/data/countries-languages.json' assert { type: 'json' };
import { isString } from 'utils/stringUtils';

export const OTHER_NORWEGIAN_LANGCODES = ['nn', 'nb'];

function sortAttributesByName(a: any, b: any) {
  const propA = a.attributes.name;
  const propB = b.attributes.name;

  if (propA === propB) {
    return 0;
  }
  return propA < propB ? -1 : 1;
}

/*
 * Translates all countries to a sorted array of objects with attributes
 */
function getCountries() {
  const countries = data.countries.map((country) => {
    return {
      type: 'countries',
      id: country.code || '',
      attributes: {
        name: country.name || ''
      }
    };
  });

  return countries
    .filter((country) => country.id !== '' && country.attributes.name !== '')
    .sort(sortAttributesByName);
}

/*
 * Translates all languages to a sorted array of objects with attributes
 */
function getLanguages() {
  const languages = data.languages.map((language) => {
    return {
      type: 'languages',
      id: language.code || '',
      attributes: {
        name: language.name || ''
      }
    };
  });

  return languages
    .filter((language) => language.id !== '' && language.attributes.name !== '')
    .sort(sortAttributesByName);
}

function countryCodeExists(countryCode: string) {
  if (!countryCode) {
    return false;
  }

  const code = countryCode.toUpperCase();
  const exists = data.countries.some((country) => country.code === code);

  return exists;
}

function languageCodeExists(languageCode: string) {
  if (!languageCode) {
    return false;
  }

  const code = languageCode.toLowerCase();
  const exists = data.languages.some((language) => language.code === code);

  return exists;
}

/**
 * Checks if a countryCode's format is correct. Should be two characters in uppercase.
 */
export function isCountryCodeInCorrectFormat(countryCode: string): boolean {
  if (!isString(countryCode)) {
    return false;
  }

  return countryCode.length === 2 && countryCode.toUpperCase() === countryCode;
}

/**
 * Checks if the country code is a valid ISO code.
 * Does not care about casing.
 */
export function isCountryCode(countryCode: string = '') {
  if (!isString(countryCode)) {
    return false;
  }

  return (
    isCountryCodeInCorrectFormat(countryCode.toUpperCase()) &&
    countryCodeExists(countryCode)
  );
}

/**
 * Checks if a languageCode's format is correct. Should be two characters in lowercase
 */
export function isLanguageCodeInCorrectFormat(languageCode: string): boolean {
  if (!isString(languageCode)) {
    return false;
  }

  return (
    languageCode.length === 2 && languageCode.toLowerCase() === languageCode
  );
}

/**
 * Checks if a language code is supported by the system as a translated value
 */
export function isLanguageCode(languageCode: string = '') {
  if (!isString(languageCode)) {
    return false;
  }

  return (
    isLanguageCodeInCorrectFormat(languageCode) &&
    languageCodeExists(languageCode)
  );
}

export function toLangCode(code: string): string {
  if (OTHER_NORWEGIAN_LANGCODES.some((langCode) => langCode === code)) {
    return 'no';
  }

  return code;
}

/*
 * ISO 3166-1 alpha-2 two-letter country codes
 */
export const ALL_COUNTRIES = getCountries();

/*
 * ISO 639-1 two-letter language codes
 */
export const ALL_LANGUAGES = getLanguages();
