import {
  FC,
  useState,
  useRef,
  useEffect,
  ReactNode,
  useCallback,
  ChangeEvent,
  useMemo
} from 'react';
import CountryFlag from 'react-country-flag';

import { useTranslations } from 'hooks/useTranslations';

import arrowIcon from 'assets/vectors/arrow-down.svg';
import globeIcon from 'assets/vectors/globe.svg';
import checkIcon from 'assets/vectors/check.svg';
import countryCodes from 'assets/data/country-code-meta.json';

import { useKeyEffect } from 'hooks/useKeyEffect';
import { useDetectOutsideClick } from 'hooks/useDetectOutsideClick';
import { findDefaultCountryPhoneMeta } from 'utils/phoneNumberUtils';
import { lowerCaseStartsWith } from 'utils/stringUtils';

import {
  LabelFloat,
  LabelFloatGroup,
  LabelFloatInput,
  LabelFloatLabel,
  LabelFloatMessage,
  CountryGroup,
  CountryButton,
  SelectOptions,
  Option,
  DialCodeInput,
  DialCodeLabel,
  OptionSearchInput,
  OptionGroup,
  OptionSearchGroup,
  OptionSearchLabel,
  OptionMark,
  OptionContent,
  ArrowIcon,
  DefaultIcon
} from './styled';

type PhoneNumberOption = {
  name: string;
  dialCode: string;
  countryCode: string;
};

type CustomIcon = {
  src: any;
  alt: string;
  onClick: () => void;
};

type Props = {
  description: string;
  placeholder?: string;
  numberInputRef?: any;
  dialCodeInputRef?: any;
  onDialCodeChange: (value: string) => void;
  defaultDialCode?: string;
  defaultNumber?: string;
  defaultCountry?: string;
  disabled?: boolean;
  error?: any;
  icon?: CustomIcon;
  outlined?: boolean;
};

const PhoneNumberField: FC<Props> = ({
  description,
  placeholder = ' ',
  numberInputRef,
  dialCodeInputRef,
  onDialCodeChange,
  defaultDialCode,
  defaultNumber,
  defaultCountry,
  disabled = false,
  outlined = false,
  error
}) => {
  const t = useTranslations();

  // Refs
  const countryButtonRef = useRef(null);
  const selectOptionsRef = useRef(null);
  const optionsSearchInputRef = useRef<HTMLInputElement>(null);
  const optionsGroupRef = useRef<HTMLInputElement>(null);
  const renderRef = useRef(false);

  // State
  const [focus, setFocus] = useState<boolean>(false);
  const [searchFocus, setSearchFocus] = useState<boolean>(false);
  const [searchState, setSearchState] = useState<string>('');
  const [selected, setSelected] = useState(
    findDefaultCountryPhoneMeta(countryCodes, defaultDialCode, defaultCountry)
  );

  // Hooks
  const [isActive, setIsActive] = useDetectOutsideClick(
    [countryButtonRef, selectOptionsRef],
    false
  );

  // Active toggle function
  const toggleIsActive = useCallback(
    () => setIsActive(!isActive),
    [setIsActive, isActive]
  );

  // On focus and blur
  const onFocus = useCallback(() => setFocus(true), []);
  const onBlur = useCallback(() => setFocus(false), []);

  // On search change, focus and blur
  const onSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setSearchState(e.target.value),
    []
  );
  const onSearchFocus = useCallback(() => setSearchFocus(true), []);
  const onSearchBlur = useCallback(() => setSearchFocus(false), []);

  // Options array filtered by current search state
  const options = useMemo(
    () => [
      ...countryCodes.filter((cc) => lowerCaseStartsWith(cc.name, searchState))
    ],
    [searchState]
  );

  // Effect when default dial code or country changes and no dial code has been selected yet (registration process country selection)
  useEffect(() => {
    setSelected(
      findDefaultCountryPhoneMeta(countryCodes, defaultDialCode, defaultCountry)
    );
  }, [defaultDialCode, defaultCountry]);

  // Effect for closing menu on Escape press
  useKeyEffect({
    onKeyDown: toggleIsActive,
    addEventListener: isActive,
    eventKey: 'Escape'
  });

  // Effect when isActive updates
  useEffect(() => {
    if (!isActive && renderRef.current) {
      setSearchState('');

      if (optionsGroupRef.current) {
        optionsGroupRef.current.scrollTop = 0;
      }
    }

    if (isActive && optionsSearchInputRef.current) {
      optionsSearchInputRef.current.focus();
    }

    renderRef.current = true;
  }, [isActive]);

  // Effect on new selection
  useEffect(() => {
    if (selected) {
      onDialCodeChange(selected.dialCode);
    }
  }, [onDialCodeChange, selected]);

  // Render flag vector
  const renderFlag = (countryCode?: string) => {
    if (!countryCode) {
      return <DefaultIcon src={globeIcon} alt="flag" />;
    }
    return (
      <CountryFlag
        style={{
          width: '32px',
          height: '32px'
        }}
        countryCode={countryCode}
        data-testid={countryCode}
        svg
      />
    );
  };

  const onSelect = useCallback(
    (option: PhoneNumberOption) => () => {
      setSelected(option);
      setIsActive(false);
    },
    [setIsActive]
  );

  // Render selected country option
  const selectedOption = useMemo(() => {
    if (options.length && selected) {
      return (
        <Option selected onClick={toggleIsActive}>
          <OptionContent>
            {renderFlag(selected?.countryCode)}
            <p>{`${selected?.name} (${selected?.dialCode})`}</p>
          </OptionContent>
          <img src={checkIcon} alt="Check mark" />
        </Option>
      );
    }

    return null;
  }, [options.length, selected, toggleIsActive]);

  // Render flag option list
  const optionsDropdown = useMemo(() => {
    if (!options.length) {
      return (
        <Option selected={false} disabled>
          <OptionContent>
            <p>{t('input.search_no_match')}</p>
          </OptionContent>
        </Option>
      );
    }

    const validOptions: ReactNode[] = [];
    options.forEach((option: PhoneNumberOption) => {
      if (option.countryCode !== selected?.countryCode) {
        validOptions.push(
          <Option
            selected={false}
            key={option.countryCode}
            onClick={onSelect(option)}
          >
            <OptionContent>
              {renderFlag(option.countryCode)}
              <p>
                <OptionMark>
                  {option.name.substring(0, searchState.length)}
                </OptionMark>
                {option.name.substring(searchState.length)}
                {` (${option.dialCode})`}
              </p>
            </OptionContent>
          </Option>
        );
      }
    });
    return validOptions;
  }, [t, onSelect, options, searchState.length, selected?.countryCode]);

  return (
    <LabelFloat>
      <LabelFloatGroup
        $focus={focus}
        $error={error}
        disabled={disabled}
        $outlined={outlined}
      >
        <CountryGroup>
          <CountryButton
            ref={countryButtonRef}
            type="button"
            onClick={toggleIsActive}
            $isActive={isActive}
          >
            {renderFlag(selected?.countryCode)}
            <ArrowIcon src={arrowIcon} alt="Arrow" $isActive={isActive} />
          </CountryButton>
        </CountryGroup>
        <DialCodeLabel>{selected?.dialCode}</DialCodeLabel>
        <DialCodeInput {...dialCodeInputRef} defaultValue={defaultDialCode} />
        <LabelFloatInput
          {...numberInputRef}
          type="text"
          defaultValue={defaultNumber}
          placeholder={placeholder}
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={disabled}
          error={error}
        />
        <LabelFloatLabel $error={error} $focus={focus}>
          {t(description)}
        </LabelFloatLabel>
        <SelectOptions ref={selectOptionsRef} $isActive={isActive}>
          <OptionSearchGroup>
            <OptionSearchInput
              ref={optionsSearchInputRef}
              value={searchState}
              type="text"
              placeholder={placeholder}
              onChange={onSearchChange}
              onFocus={onSearchFocus}
              onBlur={onSearchBlur}
              data-testid="option-search-input"
            />
            <OptionSearchLabel $focus={searchFocus}>Search</OptionSearchLabel>
          </OptionSearchGroup>
          <OptionGroup ref={optionsGroupRef}>
            <>
              {selectedOption}
              {optionsDropdown}
            </>
          </OptionGroup>
        </SelectOptions>
      </LabelFloatGroup>
      <LabelFloatMessage $error={error}>
        {error && <p>{t(error.message)}</p>}
      </LabelFloatMessage>
    </LabelFloat>
  );
};

export default PhoneNumberField;
