import { FC, ReactNode } from 'react';

import { IconLinkContainer } from './styled';

type Props = {
  children: ReactNode;
  link: string;
  iconSrc: string;
};

export const IconLink: FC<Props> = ({ children, link, iconSrc }) => {
  return (
    <IconLinkContainer href={link} target="_blank">
      <img src={iconSrc} alt="" />
      {children}
    </IconLinkContainer>
  );
};
