import { Variants } from 'framer-motion';
import { FC, ReactNode } from 'react';
import { Root } from './styled';

// Variants
const variants: Variants = {
  open: {
    height: 'auto',
    visibility: 'visible',
    opacity: 1,
    transition: {
      visibility: {
        delay: 0.5
      },
      height: {
        duration: 0.5
      },
      opacity: {
        delay: 0.5,
        duration: 0.1
      }
    }
  },
  closed: {
    height: 0,
    visibility: 'hidden',
    opacity: 0,
    transition: {
      visibility: {
        delay: 0.5
      },
      height: {
        duration: 0.5
      },
      opacity: {
        duration: 0.5
      }
    }
  }
};

type Props = {
  defaultIsOpen?: boolean;
  isOpen: boolean;
  children: ReactNode;
};

const AnimatedFormGroup: FC<Props> = ({
  defaultIsOpen = false,
  isOpen,
  children
}) => {
  return (
    <Root
      $defaultIsOpen={defaultIsOpen}
      animate={isOpen ? 'open' : 'closed'}
      transition={{ duration: 0.5 }}
      variants={variants}
    >
      {children}
    </Root>
  );
};

export default AnimatedFormGroup;
