import styled from 'styled-components';
import { breakpoints } from 'styles';

export const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 48px;

  > img {
    width: 100%;
    max-width: 1000px;
  }

  @media (max-width: ${breakpoints.l}px) {
    width: auto;
    max-width: 800px;
    padding: 16px;
    margin: 0 auto;

    > img {
      height: 100%;
    }
  }
`;
