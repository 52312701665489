import { FC, useCallback, useMemo } from 'react';
import { Hamburger, Button, Path } from './styled';

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

const HamburgerButton: FC<Props> = ({ setIsOpen, isOpen }) => {
  // On click
  const onClick = useCallback(() => setIsOpen(!isOpen), [setIsOpen, isOpen]);

  // Motion paths
  const motionPaths = useMemo(() => {
    const list = [
      {
        id: 1,
        variants: {
          closed: {
            d: 'M 0 2.5 L 20 2.5',
            transition: { stroke: { delay: 0.1 } }
          },
          open: {
            d: 'M 3 16.5 L 17 2.5',
            transition: { stroke: { delay: 0.1 } }
          }
        }
      },
      {
        id: 2,
        d: 'M 0 9.423 L 20 9.423',
        variants: {
          closed: {
            opacity: 1,
            transition: { stroke: { delay: 0.1 } }
          },
          open: {
            opacity: 0,
            transition: { stroke: { delay: 0.1 } }
          }
        }
      },
      {
        id: 3,
        variants: {
          closed: {
            d: 'M 0 16.346 L 20 16.346',
            transition: { stroke: { delay: 0.1 } }
          },
          open: {
            d: 'M 3 2.5 L 17 16.346',
            transition: { stroke: { delay: 0.1 } }
          }
        }
      }
    ];

    return list.map((item) => (
      <Path key={item.id} variants={item.variants} d={item.d} />
    ));
  }, []);

  return (
    <Hamburger
      initial={false}
      animate={isOpen ? 'open' : 'closed'}
      transition={{ type: 'tween' }}
    >
      <Button onClick={onClick} data-testid="hamburger-button">
        <svg width="32" height="20" viewBox="0 0 20 18">
          {motionPaths}
        </svg>
      </Button>
    </Hamburger>
  );
};

export default HamburgerButton;
