import styled from 'styled-components';

export const CardContainer = styled.div`
  max-width: 800px;
  padding: 16px;
  margin: 0 auto;
`;

export const Card = styled.div`
  background-color: ${({ theme }) => theme.colors.surface};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 32px;
  margin-bottom: 32px;
  padding: 24px;
`;

export const LoaderText = styled.p`
  font-weight: bold;
  text-align: center;
  margin-top: 16px;
`;

export const Text = styled.p`
  margin-bottom: 0;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;
