import { FC, useCallback } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';

import { DIAMGroup } from 'models/customer';
import { useTranslations } from 'hooks/useTranslations';
import InputField from 'components/UI/InputField';
import Button from 'components/UI/Buttons/Button';
import Loader from 'components/UI/Loader';
import Modal from 'components/UI/Modal';
import { ModalForm, ModalTitle } from 'styles';
import { ButtonGroup, Group, Text, Info, LoaderWrapper } from './styled';

type ValidateChangeEmail = {
  code: string;
  password: string;
};

type Props = {
  isOpen: boolean;
  isLoading: boolean;
  defaultCode?: string | null;
  email: string | null;
  groups: DIAMGroup[];
  submit: (values: ValidateChangeEmail) => void;
  cancel: () => void;
};

const ConfirmDeleteAccountModal: FC<Props> = ({
  isOpen,
  isLoading,
  defaultCode,
  email,
  groups,
  submit,
  cancel
}) => {
  const t = useTranslations();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors
  } = useForm();

  // Submit
  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    ({ password }) => {
      if (defaultCode) {
        submit({ code: defaultCode, password });
      }
    },
    [submit, defaultCode]
  );

  // Reset inputfield
  const reset = useCallback(
    (name: string) => {
      setValue(name, '');
      clearErrors(name);
    },
    [setValue, clearErrors]
  );

  // Render services
  const renderServices = () => {
    if (!groups.length) {
      return null;
    }
    return (
      <ul>
        {groups.map((item) => (
          <Group key={item.groupId}>{item.name}</Group>
        ))}
      </ul>
    );
  };

  // Render info box
  const renderInfoBox = () => {
    if (!email) {
      return null;
    }

    return (
      <Info>
        <Text color="white">
          {t('delete_account.confirm.instructions', { email })}
        </Text>
      </Info>
    );
  };

  // Render form
  const renderForm = () => {
    if (isLoading) {
      return (
        <LoaderWrapper>
          <Loader center />
        </LoaderWrapper>
      );
    }
    return (
      <InputField
        name="password"
        description="input.password_label"
        type="password"
        error={errors.password}
        register={register('password', {
          required: {
            value: true,
            message: 'input.password_required'
          }
        })}
        reset={reset}
        outlined
      />
    );
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalTitle>{t('delete_account.confirm.title')}</ModalTitle>
      <ModalForm onSubmit={handleSubmit(onSubmit)}>
        <Text color="grey6">{t('delete_account.confirm.description')}</Text>
        {renderServices()}
        {renderInfoBox()}
        {renderForm()}
        <ButtonGroup>
          <Button color="transparent" textColor="grey6" onClick={cancel}>
            {t('delete_account.confirm.cancel')}
          </Button>
          <Button submit color="error" disabled={isLoading}>
            {t('delete_account.confirm.confirm')}
          </Button>
        </ButtonGroup>
      </ModalForm>
    </Modal>
  );
};

export default ConfirmDeleteAccountModal;
