import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import * as routes from 'router/routes';
import { useTranslations } from 'hooks/useTranslations';
import SeoHelmet from 'components/fragments/SeoHelmet';
import PageWrapper from 'components/UI/Page/PageWrapper';
import Button from 'components/UI/Buttons/Button';
import Heading, { Type as HeadingType } from 'components/UI/Heading';

import { Container, ButtonGrid } from './styled';

const NotFoundPage: FC = () => {
  const t = useTranslations();
  const navigate = useNavigate();

  // On click
  const onClick = useCallback(() => navigate(routes.LOGIN), [navigate]);

  return (
    <PageWrapper>
      <SeoHelmet title="not_found.meta_title" />
      <Container>
        <Heading type={HeadingType.h1}>{t('not_found.title')}</Heading>
        <p>{t('not_found.description')}</p>
        <ButtonGrid>
          <Button onClick={onClick}>{t('not_found.btn_to_home')}</Button>
        </ButtonGrid>
      </Container>
    </PageWrapper>
  );
};

export default NotFoundPage;
