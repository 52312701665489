import { FC } from 'react';

import { TermsAndConditions } from 'models/product';

import { IconLink } from 'components/UI/IconLink';
import Badge from 'components/UI/Badge';

import { extractPublicationTitle } from 'utils/productUtils';
import linkIcon from 'assets/vectors/external-link.svg';

import { Badges, Container, Text } from './styled';

type Props = {
  toc: TermsAndConditions;
  languageCode: string;
};

const ProductServiceTermsAndConditions: FC<Props> = ({ toc, languageCode }) => {
  const { publicationTitle, languages, link, format } = toc.attributes;

  return (
    <IconLink link={link} iconSrc={linkIcon}>
      <Container>
        <Text>
          {extractPublicationTitle(publicationTitle, languages, languageCode)}
        </Text>
        <Badges>
          {languages.map((language) => (
            <Badge key={language}>{language.toUpperCase()}</Badge>
          ))}
          {format && <Badge>{format.toUpperCase()}</Badge>}
        </Badges>
      </Container>
    </IconLink>
  );
};

export default ProductServiceTermsAndConditions;
