/**
 * Verify that all keys in the provided object have a `thruthy` value.
 */
export function hasAllKeysDefined(obj = {}): boolean {
  return Object.values(obj).every((value) => value);
}

export function allKeysAreEmptyStrings(obj = {}): boolean {
  return Object.values(obj).every(
    (value) => value === '' || value === undefined
  );
}

export function isObject(obj: any) {
  return obj !== null && typeof obj === 'object';
}
