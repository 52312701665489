import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { breakpoints } from 'styles';

export const NavbarMargin = styled.div`
  width: 100%;
  height: 96px;
  background-color: ${({ theme }) => theme.colors.surface};

  @media (max-width: ${breakpoints.s}px) {
    height: 72px;
  }
`;

export const NavbarContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  height: 96px;
  padding: 0 8px;
  background-color: ${({ theme }) => theme.colors.surface};
  z-index: 2;

  @media (max-width: ${breakpoints.s}px) {
    height: 72px;
  }
`;

export const MenuGrid = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 32px 48px;

  @media (max-width: ${breakpoints.s}px) {
    padding: 0 4px 48px;
  }
`;

const LinkBase = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  color: ${({ theme }) => theme.colors.grey6};
  font-size: 28px;
  font-weight: 700;
  border-radius: 16px;
  gap: 16px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.background};
  }

  @media (max-width: ${breakpoints.s}px) {
    font-size: 24px;
  }
`;

export const LinkRow = styled(Link)`
  ${LinkBase}

  &:hover {
    text-decoration: none;
  }
`;

export const ActionRow = styled.div`
  ${LinkBase}
`;

export const LinkExternal = styled.a`
  display: flex;
  padding: 8px 16px;
  color: ${({ theme }) => theme.colors.grey6};
  font-size: 18px;
  border-radius: 16px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.background};
  }

  @media (max-width: ${breakpoints.s}px) {
    font-size: 16px;
  }
`;

type NavbarColProps = {
  $alignRight?: boolean;
};

export const NavbarCol = styled.div<NavbarColProps>`
  display: flex;
  justify-content: ${({ $alignRight }) => ($alignRight ? 'right' : 'left')};
  align-items: center;
`;

export const LogoLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Logo = styled.img`
  height: 64px;
  margin: auto 0;

  @media (max-width: ${breakpoints.s}px) {
    height: 48px;
  }
`;

export const ArrowIcon = styled.img`
  height: 16px;
`;

export const InboxButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  cursor: pointer;
  position: relative;

  &:hover {
    background-color: ${({ theme }) => theme.colors.background};
    border-radius: 16px;
  }

  @media (max-width: ${breakpoints.s}px) {
    width: 48px;
  }
`;

export const InboxGrid = styled.div`
  display: flex;
  position: relative;
`;

export const InboxAlert = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  color: ${({ theme }) => theme.colors.onPrimary};
  background-color: ${({ theme }) => theme.colors.warning};
  top: -14px;
  right: -14px;
  width: 24px;
  height: 24px;
  font-size: 18px;
  font-weight: bold;
  border-radius: 8px 8px 8px 0;
`;

export const InviteContainer = styled.div`
  padding: 0 48px;

  @media (max-width: ${breakpoints.s}px) {
    padding: 0;
  }
`;

export const InboxInvite = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-top: 1px solid ${({ theme }) => theme.colors.grey3};
  padding: 24px 0;

  @media (max-width: ${breakpoints.s}px) {
    margin: 0 20px;
  }
`;

export const InboxProduct = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;

  @media (max-width: ${breakpoints.s}px) {
    gap: 16px;
  }
`;

export const InboxProductImage = styled.img`
  width: 120px;
  height: 96px;
  object-fit: cover;

  @media (max-width: ${breakpoints.s}px) {
    width: 72px;
    height: 72px;
  }
`;

export const InboxProductGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: ${breakpoints.s}px) {
    gap: 8px;
  }

  h3 {
    margin: 0;
  }
`;

export const InboxProductDetails = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;

  @media (max-width: ${breakpoints.s}px) {
    gap: 16px;
  }
`;

export const InboxProductButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;
