import { FC, useCallback } from 'react';
import Calendar from 'react-calendar';

import 'react-calendar/dist/Calendar.css';

import { useAppSelector } from 'hooks/redux';
import { localizationSelector } from 'store/localizationState';
import { DatePickerRoot } from './styled';

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

type Props = {
  maxDate?: Date;
  defaultDate?: Date;
  onChange: (date: Date) => void;
  close: () => void;
};

const DatePicker: FC<Props> = ({
  maxDate,
  defaultDate: defaultValue,
  onChange,
  close
}) => {
  const { languageCode } = useAppSelector(localizationSelector);

  // On change
  const onCalendarChange = useCallback(
    (date: Value) => {
      onChange(date as Date);
      close();
    },
    [onChange, close]
  );

  return (
    <DatePickerRoot data-testid="date-picker">
      <Calendar
        locale={languageCode ?? 'en'}
        maxDate={maxDate}
        defaultValue={defaultValue ?? maxDate}
        onChange={onCalendarChange}
      />
    </DatePickerRoot>
  );
};

export default DatePicker;
