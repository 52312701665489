import styled from 'styled-components';

export const Container = styled.div`
  max-width: 420px;
  text-align: center;
  padding: 48px 16px;
  margin: 0 auto;

  h4 {
    color: ${({ theme }) => theme.colors.onSurface};
    text-transform: uppercase;
  }

  p {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
`;

export const Text = styled.p`
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
`;
