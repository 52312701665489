import { FC, useEffect } from 'react';

import { useTranslations } from 'hooks/useTranslations';
import Button from 'components/UI/Buttons/Button';
import Loader from 'components/UI/Loader';
import Modal from 'components/UI/Modal';

import { ModalDescription, ModalTitle } from 'styles';
import { ButtonGroup, LoaderWrapper } from './styled';

type Props = {
  isLoading: boolean;
  isCompleted: boolean;
  isOpen: boolean;
  close: () => void;
  submit: () => void;
};

const DeleteAccountModal: FC<Props> = ({
  isLoading,
  isCompleted,
  isOpen,
  close,
  submit
}) => {
  const t = useTranslations();

  // Close if complete
  useEffect(() => {
    if (isCompleted) {
      close();
    }
  }, [close, isCompleted]);

  return (
    <Modal isOpen={isOpen}>
      <ModalTitle>{t('delete_account.request.title')}</ModalTitle>
      <ModalDescription>
        {t('delete_account.request.description')}
      </ModalDescription>
      {isLoading && (
        <LoaderWrapper>
          <Loader center />
        </LoaderWrapper>
      )}
      <ButtonGroup>
        <Button onClick={close} color="transparent" textColor="primary">
          {t('delete_account.request.cancel')}
        </Button>
        <Button onClick={submit} submit disabled={isLoading}>
          {t('delete_account.request.confirm')}
        </Button>
      </ButtonGroup>
    </Modal>
  );
};

export default DeleteAccountModal;
