import { createApi } from '@reduxjs/toolkit/query/react';
import {
  InviteQuery,
  ProductDetails,
  ProductList,
  ProductQuery
} from 'models/product';
import { Invite, InviteDto, InviteRegistrationQuery } from 'models/invite';
import type { RootState } from 'store';
import { JsonApiData } from 'models/jsonApi';
import { FinalizeProductRegistrationData } from 'models/productRegistration';
import {
  defaultHeaders,
  baseQueryAuth,
  createFilterUrl
} from 'utils/fetchUtils/fetchUtils';
import {
  transformProductDetailsResponse,
  transformProductRegistrationResponse
} from 'utils/productUtils';
import { trim } from 'utils/stringUtils';
import { createProductRegistrationBody } from 'utils/serviceUtils';
import * as config from 'config';

export const productApi = createApi({
  reducerPath: 'productApi',
  baseQuery: baseQueryAuth,
  tagTypes: ['Product'],
  endpoints: (builder) => ({
    getRegisteredProducts: builder.query<ProductList, ProductQuery>({
      query: ({ customerId, langCode = 'en' }) =>
        `/product-registrations/search/${langCode}?filter[customerId]=${customerId}`,
      transformResponse: transformProductRegistrationResponse,
      providesTags: [{ type: 'Product', id: 'ProductList' }]
    }),
    getRegisteredProductDetails: builder.query<ProductDetails, ProductQuery>({
      query: ({ customerId, iprId, langCode = 'en' }) => {
        const id = trim(iprId);
        return `/product-registrations/search/${langCode}?filter[customerId]=${customerId}&filter[iprId]=${id}`;
      },
      transformResponse: transformProductDetailsResponse,
      providesTags: (_res, _err, { iprId }) => [
        { type: 'Product', id: iprId ?? undefined }
      ]
    }),
    registerProducts: builder.mutation<any, InviteRegistrationQuery>({
      async queryFn(
        { customerId, invite, soldDate },
        { getState },
        _extraOptions,
        fetch
      ) {
        const token = (getState() as RootState).auth.session?.accessToken;
        const { products } = invite;

        let queryData: any;
        let queryError: any = null;
        // Loop products and register them in a sequence
        for (const product of products) {
          // Registration
          const { data, error } = await fetch({
            url: `${config.backendApi}/customers/${customerId}/relationships`,
            headers: {
              ...defaultHeaders,
              Authorization: `Bearer ${token}`
            },
            method: 'POST',
            body: {
              data: createProductRegistrationBody(product, soldDate, invite)
            }
          });

          if (data) {
            queryData = data;
          }

          if (error) {
            queryError = error;
          }
        }
        return !queryError ? { data: queryData } : { error: queryError };
      },
      invalidatesTags: ['Product']
    }),
    getProductInvitesByEmail: builder.query<Invite[], InviteQuery>({
      query: ({ email }) =>
        `/invites?email=${encodeURIComponent(email as string)}`,
      transformResponse: (response: any) => {
        return response.data.map((item: JsonApiData) => {
          return InviteDto.fromJsonApiData(item);
        });
      },
      providesTags: [{ type: 'Product', id: 'InviteList' }]
    }),
    getProductInviteByPri: builder.query<Invite, ProductQuery>({
      query: ({ husqvarnaId, pnc, serialNumber, iprId, langCode }) =>
        createFilterUrl(`/search-product/${langCode}`, {
          husqvarnaId: trim(husqvarnaId),
          serialNumber: trim(serialNumber),
          pnc: trim(pnc),
          iprId: trim(iprId)
        }),
      transformResponse: (response: any) => {
        return InviteDto.fromJsonApiData(response.data);
      },
      providesTags: [{ type: 'Product', id: 'Invite' }]
    }),
    getProductInviteByCode: builder.query<Invite, InviteQuery>({
      query: ({ code }) => `/invites/${code}`,
      transformResponse: (response: any) =>
        InviteDto.fromJsonApiData(response.data),
      providesTags: [{ type: 'Product', id: 'Invite' }]
    }),
    registerProfessionalProducts: builder.mutation<
      FinalizeProductRegistrationData,
      string
    >({
      query: (code: string) => ({
        url: `/product-registrations/finalize-registration/${code}`,
        method: 'POST'
      }),
      transformResponse: (response: any) => response.data
    })
  })
});

export const {
  useGetRegisteredProductsQuery,
  useGetRegisteredProductDetailsQuery,
  useGetProductInvitesByEmailQuery,
  useGetProductInviteByCodeQuery,
  useGetProductInviteByPriQuery,
  useRegisterProfessionalProductsMutation,
  useRegisterProductsMutation
} = productApi;
