import 'react-toastify/dist/ReactToastify.css';
import { FC, useEffect } from 'react';

import { globalErrorSelector } from 'store/globalErrorState';

import { useTranslations } from 'hooks/useTranslations';
import { useAppSelector } from 'hooks/redux';
import { showToast, ToastType } from 'utils/toastUtils';

import { StyledToast } from './styled';

const ErrorDisplay: FC = () => {
  const t = useTranslations();
  const globalErrorState = useAppSelector(globalErrorSelector);

  useEffect(() => {
    if (globalErrorState.counter) {
      const { messageKey, type = ToastType.INFO } = globalErrorState;
      showToast(t(messageKey), type as ToastType);
    }
  }, [globalErrorState, t]);

  return <StyledToast />;
};

export default ErrorDisplay;
