import styled from 'styled-components';

export const IconLinkContainer = styled.a`
  display: flex;
  gap: 12px;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  padding: 8px 0;
`;
